import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P111-E1",
    exerciseKey: "img/FriendsPlus/Page111/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Complete the recipe with the verbs below. Use a
        dictionary to help you.
        `,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      textAlign: "center",
      width: 96,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: ["add", "beat", "crush", "melt", "pour", "stir", "whisk"],
        styleElement: { color: "#91268e", marginRight: "40px" },
        width: "90%",
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },

    // hideBtnFooter: true,
    // character: /[/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
            <img src="img/FriendsPlus/Page111/E1/1.jpg" style='height:12cm'/>
            <div style='position:absolute;top:89px;left:36px'> #</div>
            <div style='position:absolute;top:126px;left:38px'> #</div>
            <div style='position:absolute;top:126px;left:340px'> #</div>
            <div style='position:absolute;top:209px;left:39px'> #</div>
            <div style='position:absolute;top:246px;left:179px'> #</div>
            <div style='position:absolute;top:284px;left:195px'> #</div>
            <div style='position:absolute;top:329px;left:95px'> #</div>
            
        </div>
        `,
        answer: ["Melt", "crush", "add", "Beat", "Whisk", "Stir", "pour"],
      },
    ],
  },
  2: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P111-E2",
    exerciseKey: "img/FriendsPlus/Page111/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Match the idioms below with the definitions (1–8). Use a
        dictionary to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 310, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    character: "/",
    hintBox: [
      {
        src: [
          "be fighting fit",
          "be in good / bad shape",
          "be on the mend",
          "get back into shape",
          "go down with something",
          "have a new lease of life",
          "look the picture of health",
          "look / be under the weather",
        ],
        width: "90%",
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:25cm'>
            <b>1</b>&ensp;to be in poor / excellent physical condition#<br>
            <b>2</b>&ensp;to feel not quite well or in low spirits#<br>
            <b>3</b>&ensp;to get some exercise and become fit again#<br>
            <b>4</b>&ensp;to become ill#<br>
            <b>5</b>&ensp;to appear extremely well and healthy#<br>
            <b>6</b>&ensp;to be recovering after an illness#<br>
            <b>7</b>&ensp;to have an opportunity to live with greater enjoyment and satisfaction#<br>
            <b>8</b>&ensp;to be very healthy and physically fit#<br>
        </div>
        `,
        answer: [
          "be in bad / good shape",
          "look / be under the weather",
          "get back into shape",
          "go down with something",
          "look the picture of health",
          "be on the mend",
          "have a new lease of life",
          "be fighting fit",
        ],
      },
    ],
  },
  3: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P111-E3",
    exerciseKey: "img/FriendsPlus/Page111/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with idioms from exercise 2 in
        the correct form.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "be fighting fit",
          "be in good / bad shape",
          "be on the mend",
          "get back into shape",
          "go down with something",
          "have a new lease of life",
          "look the picture of health",
          "look / be under the weather",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    stylesTextInput: { textAlign: "center", width: 310, paddingTop: 10 },
    // character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `

        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;Hello, Mrs White. I must say, you #! Have you been on holiday?<br>
            <b>2</b>&ensp;What’s wrong with Tim? He’s been looking a bit fed up and # lately.<br>
            <b>3</b>&ensp;I’ve just weighed myself and I was horrified. I’m in pretty #, it seems.<br>
            <b>4</b>&ensp;Let’s join the gym again. I need to # for the summer.<br>
            <b>5</b>&ensp;I’ve had terrible flu, but I think I # now, thank goodness.<br>
            <b>6</b>&ensp;She’s shivering and she’s got a temperature. She must be # with something.<br>
            <b>7</b>&ensp;My grandad had # after his hip operation. He was so happy he could move about again.<br>
            <b>8</b>&ensp;I can’t wait for the 10 km race. I’m # and ready to go.<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "look the picture of health",
          "under the weather",
          "bad shape",
          "get back into shape",
          "am on the mend",
          "going down",
          "a new lease of life",
          "fighting fit",
        ],
      },
    ],
  },
  4: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P111-E4",
    exerciseKey: "img/FriendsPlus/Page111/Key/E4answerKey.png",

    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Make compound nouns from the
        phrasal verbs below. Use a dictionary to help you.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "crack down",
          "cut back",
          "rip off",
          "sell out",
          "stop off",
          "write off",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "90%",
      },
    ],
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    // character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 400,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
  
          <hintbox id=0></hintbox>
          <div style='width:26cm'>
            <b>1</b>&ensp;The show was a complete # . The ticket swere gone within minutes.<br>
            <b>2</b>&ensp;The police are having a # on speeding. There are lots of police cars on the streets in the evening.<br>
            <b>3</b>&ensp;This flight has two #, one in Berlin and one in Bangkok.<br>
            <b>4</b>&ensp;You paid £300 for those boots? What a #!<br>
            <b>5</b>&ensp;I crashed my car and it turned out to be a complete#.<br>
            <b>6</b>&ensp;The health service is suffering from budget because # of the economic climate.<br>
          </div>
          `,
        // InputRong: true,
        answer: [
          "sell-out",
          "crackdown",
          "stop offs",
          "rip-off",
          "write-off",
          "cut-backs",
        ],
      },
    ],
  },
  5: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P111-E5",
    exerciseKey: "img/FriendsPlus/Page111/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with one adjective from each pair
        in exercise 1.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 170,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrogant / self-assured",
          "calculating / shrewd",
          "courageous / foolhardy",
          "pushy / enthusiastic",
          "reserved / antisocial",
          "stingy / thrifty",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "90%",
      },
    ],
    // character: /[,/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>

        <div style='width:27cm'>
            <b>1</b>&ensp;Don’t expect a birthday present from her – she’s well-off, but so # with her money.<br>
            <b>2</b>&ensp;The new sales assistant is wonderful. She’s so genuinely # about the products that everybody buys them.<br>
            <b>3</b>&ensp;I know you think you’re being brave, but actually I think climbing that wall is just #.<br>
            <b>4</b>&ensp;Leo’s a very # judge of character. He knew immediately that the journalist wasn’t being honest.<br>
            <b>5</b>&ensp;Come over and chat to the rest of the group. Don’t be so #!<br>
            
        </div>
        
        `,
        answer: ["Stingy", "Enthusiastic", "Foolhardy", "Shrewd", "Reserved"],
      },
    ],
  },
  6: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P111-E6",
    exerciseKey: "img/FriendsPlus/Page111/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 140 },
    titleQuestion: [
      {
        num: "3",
        title: `Match the American words in A with the British words in B.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>A</b>",
          "bathing suit",
          "pants",
          "sneakers",
          "sweater",
          "undershirt",
          "vest",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "jumper",
          "swimming costume",
          "trainers",
          "trousers",
          "vest",
          "waistcoat",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>A</b>",
          "eraser",
          "hot lunch",
          "math",
          "recess",
          "teachers’ lounge",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "break time",
          "maths",
          "rubber",
          "school dinner",
          "staff room",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>A</b>", "cupcake", "dessert", "eggplant", "jelly", "takeout"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>B</b>", "aubergine", "fairy cake", "jam pudding", "takeaway"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    character: /[,-]/,
    // characterCoupleWork: "",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Clothes
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <textarea id=0 rows=3></textarea>
        <b>2</b>&ensp;School
        <hintbox id=2></hintbox>
        <hintbox id=3></hintbox>
        <textarea id=1 rows=3></textarea>
        <b>3</b>&ensp;Food
        <hintbox id=4></hintbox>
        <hintbox id=5></hintbox>
        <textarea id=2 rows=3></textarea>
        
        
          `,
        answer: [
          "bathing suit - swimming costume, pants - trousers, sneakers - trainers, sweater - jumper, undershirt - vest, vest - waistcoat",
          "eraser - rubber, hot lunch - school dinner, math - maths, recess - break time, teachers' lounge - staff room",
          "cupcake - fairy cake, dessert - pudding, eggplant - aubergine, jelly - jam, takeout - takeaway",
        ],
      },
    ],
  },
  7: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P111-E7",
    exerciseKey: "img/FriendsPlus/Page111/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Add an example of your own for
        each type of adjective formation.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 330, paddingTop: 10 },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page111/E7/1.jpg" style='height:11cm'/><br>
            <div style='margin-left:10px'>
                <b>-ful:</b> #<br>
                <b>-ive:</b> #<br>
                <b>-al:</b> #<br>
                <b>-able:</b> #<br>
                <b>-ous:</b> #<br>
                <b>-y:</b> #<br>
                <b>-less:</b> #<br>
            </div>
        </div>
        `,
        answer: [
          "truthful, useful",
          "active, creative",
          "magical, fatal",
          "breakable, uncontrollable",
          "dangerous, envious",
          "salty, muddy",
          "helpless, lifeless",
        ],
      },
    ],
  },
  8: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P111-E8",
    exerciseKey: "img/FriendsPlus/Page111/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page111/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
