import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P60-E1",
    exerciseKey: "img/FriendsPlus/Page60/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Look at the photos (A–C). Can you identify the people and their
        occupations? What qualities make them suitable for their occupation and
        successful in their career? 
        
        `,
        color: "black",
        width: "28cm",
        left: 30,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page60/E1/1.jpg" style='height:12cm'/>
        <div style='display:flex'><b>A</b><textarea id=0 rows=2></textarea></div>
        <div style='display:flex'><b>B</b><textarea id=1 rows=2></textarea></div>
        <div style='display:flex'><b>C</b><textarea id=2 rows=2></textarea></div>
        `,
        answer: [
          "Henry Ford, a businessman and an inventor, He founded Ford Motor Company and invented the famous Model T car.",
          "J.K. Rowling, author, She wrote the Harry Potter books.",
          "Mother Teresa, a nun and a humanitarian, She spent her entire life serving the poor and helpless of India.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P60-E2",
    exerciseKey: "img/FriendsPlus/Page60/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Work in pairs. Check the meaning of the adjectives below. Then
        decide which qualities you would need to be successful at each occupation
        in exercise 1. 
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 800, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Personality adjectives</b>",
          "adaptable",
          "analytical",
          "argumentative",
          "assertive",
          "compassionate",
          "conscientious",
          "considerate",
          "creative",
          "detail-oriented",
          "diligent disorganised",
          "easy-going",
          "empathetic",
          "gregarious",
          "idealistic",
          "inflexible innovative",
          "intolerant",
          "loyal",
          "objective",
          "observant",
          "optimistic",
          "outspoken persuasive",
          "reserved",
          "resourceful",
          "self-confident",
          "spontaneous",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: [
          "I think you would need to be creative and industrious to be a successful writer.",
        ],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    character: ",",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=1></hintbox>
        <hintbox id=0></hintbox>
       
        `,
        // InputRong: true,
        answer: [
          `adventure holiday, backpacking, beach holiday, camping, city break, couch-surfing, cruise, cycling holiday, ecotourism, house swap, package holiday, volunteering, winter sports holiday`,
          `B&B, beach house, cabin, camper van, campsite, caravan, cottage, guest house, holiday camp, holiday home, hotel, houseboat, self-catering apartment, tent, time-share apartment, villa, youth hostel`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P60-E3",
    exerciseKey: "img/FriendsPlus/Page60/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the MBTI descriptions on the next page. Match each of the descriptions
        with at least two adjectives from exercise 2. What other adjectives can
        you add?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Personality adjectives</b>",
          "adaptable",
          "analytical",
          "argumentative",
          "assertive",
          "compassionate",
          "conscientious",
          "considerate",
          "creative",
          "detail-oriented",
          "diligent disorganised",
          "easy-going",
          "empathetic",
          "gregarious",
          "idealistic",
          "inflexible innovative",
          "intolerant",
          "loyal",
          "objective",
          "observant",
          "optimistic",
          "outspoken persuasive",
          "reserved",
          "resourceful",
          "self-confident",
          "spontaneous",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: ["The inspector: reserved, loyal"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    stylesTextInput: { width: 450, paddingTop: 10 },
    character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=1></hintbox>
        <hintbox id=0></hintbox>
        <b>The Inspector:</b>&ensp;#<br>
        <b>The Crafter:</b>&ensp;#<br>
        <b>The Protector:</b>&ensp;#<br>
        <b>The Artist:</b>&ensp;#<br>
        <b>The Advocate:</b>&ensp;#<br>
        <b>The Mediator:</b>&ensp;#<br>
        <b>The Architect:</b>&ensp;#<br>
        <b>The Thinker:</b>&ensp;#<br>
        <b>The Persuader:</b>&ensp;#<br>
        <b>The Director:</b>&ensp;#<br>
        <b>The Performer:</b>&ensp;#<br>
        <b>The Caregiver:</b>&ensp;#<br>
        <b>The Champion:</b>&ensp;#<br>
        <b>The Giver:</b>&ensp;#<br>
        <b>The Debater:</b>&ensp;#<br>
        <b>The Commander:</b>&ensp;#<br>
        <img src="img/FriendsPlus/Page61/E6/1.jpg" style='height:25cm'/>
        `,
        // InputRong: true,
        answer: [
          "reserved, loyal",
          "objective, easy-going",
          "compassionate, observant",
          "considerate, diligent",
          "idealistic, creative",
          "loyal, idealistic",
          "analytical, self-confident",
          "detail-oriented, objective",
          "adaptable, resourceful, observant",
          "inflexible, argumentative",
          "optimistic, gregarious, spontaneous",
          "loyal, conscientious, intolerant",
          "disorganised, spontaneous, creative",
          "disorganised, spontaneous, creative",
          "persuasive, empathetic",
          "outspoken, assertive, aggressive",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P60-E4",
    exerciseKey: "img/FriendsPlus/Page60/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Using the MBTI title of each person, match the people in exercise 1 with a
        description. Is it true of him / her? Is it similar to your description?`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div>
          <b>Henry Ford:</b>&ensp;#<br>
          <b>JK Rowling:</b>&ensp;#<br>
          <b>Mother Teresa:</b>&ensp;#<br>
        </div>
        <img src="img/FriendsPlus/Page61/E6/1.jpg" style='height:25cm'/>
        `,
        answer: ["The Inspector", "The Advocate", "The Protector"],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P60-E5",
    exerciseKey: "img/FriendsPlus/Page60/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Look at the MBTI descriptions of different personality types of people. What do
        you think are the jobs suitable for each type? Choose from the list below. 
        
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 800,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "accountant",
          "artist",
          "carpenter",
          "chef",
          "counsellor",
          "dentist",
          "engineer",
          "firefighter",
          "geologist",
          "judge",
          "journalist",
          "manager",
          "mechanic",
          "musician",
          "pilot",
          "politician",
          "professor",
          "psychologist",
          "scientist",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
         <div style='line-height:45px'>
          <b>The Inspector:</b>&ensp;#<br>
          <b>The Crafter:</b>&ensp;#<br>
          <b>The Protector:</b>&ensp;#<br>
          <b>The Artist:</b>&ensp;#<br>
          <b>The Advocate:</b>&ensp;#<br>
          <b>The Mediator:</b>&ensp;#<br>
          <b>The Architect:</b>&ensp;#<br>
          <b>The Thinker:</b>&ensp;#<br>
          <b>The Persuader:</b>&ensp;#<br>
          <b>The Director:</b>&ensp;#<br>
          <b>The Performer:</b>&ensp;#<br>
          <b>The Caregiver:</b>&ensp;#<br>
          <b>The Champion:</b>&ensp;#<br>
          <b>The Giver:</b>&ensp;#<br>
          <b>The Debater:</b>&ensp;#<br>
          <b>The Commander:</b>&ensp;#<br>
         </div>
         <img src="img/FriendsPlus/Page61/E6/1.jpg" style='height:25cm'/>
        
        `,
        answer: [
          "accountant, dentist, (military) leader, lawyer",
          "engineer, mechanic, carpenter",
          "accountant, professor, counsellor,",
          "artist, chef, psychologist, designer",
          "musician, artist, psychologist, (teacher, writer)",
          "artist, counsellor, designer, psychologist, social worker, writer",
          "scientist, engineer, judge, lawyer, mathematician, doctor",
          "engineer, mathematician, geologist, pharmacist, chemist",
          "sales agent, detectives, marketer, entrepreneur, firefighter",
          "accountant, business manager, politician, police officer, judge",
          "artist, actor, counsellor, musician, fashion designer, psychologist",
          "teacher, receptionist, nurse, bookkeeper, office manager, cousellor",
          "counsellor, nurse, journalist, psychologist, politician",
          "teacher, social worker, sales representative, psychologist, manager",
          "engineer, lawyer, inventor, journalist",
          "human resource manager, professor, lawyer, scientist",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P60-E6",
    exerciseKey: "img/FriendsPlus/Page60/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.23' colorimg=#9ac7d4  color='white'></headphone> Listen again. Choose the correct answers (a–c).`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-0", "1-0", "2-8", "3-4", "4-0"],
        initialValue: [],
      },
      Layout: `

      
      <b>1</b>&ensp;On the podcast, they begin by talking about what happened
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=0 type=Circle /></div>
          <div>
                    on a TV programme. <br>
              on a podcast.<br>
              in a government debate.
          </div>
       </div>
      <b>2</b>&ensp;The prize is being offered to
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=1 type=Circle /></div>
          <div>
                    any scientist. <br>
              government scientists.<br>
              famous scientists.
          </div>
       </div>
      <b>3</b>&ensp;Dr Kenny uses the example of the warplane to demonstrate that the prize money
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=2 type=Circle /></div>
          <div>
                    is a lot. <br>
              is reasonable.<br>
              isn’t so much.
          </div>
       </div>
      <b>4</b>&ensp;World health organisations are worried that antibiotics
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=3 type=Circle /></div>
          <div>
                    endanger people’s lives. <br>
              are less successful.<br>
              have become unnecessary.
          </div>
       </div>
      <b>5</b>&ensp;If nobody wins the challenge, in the future, doctors may not be able to
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=4 type=Circle /></div>
          <div>
                    carry out some types of surgery.<br>
              treat patients for certain illnesses.<br>
              work in hospitals.       
          </div>
       </div>

      `,
    },
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P60-E7",
    exerciseKey: "img/FriendsPlus/Page60/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page60/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P60-E8",
    exerciseKey: "img/FriendsPlus/Page60/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page60/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
