import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P76-E1",
    exerciseKey: "img/FriendsPlus/Page76/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Read the statements below.
        Which do you think are true?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { marginBottom: -23, width: 510, paddingTop: 10 },
    textareaStyle: {
      width: 510,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;If you wake a sleepwalker, they will have a heart attack.<br>
          <b>2</b>&ensp;You should drink at least eight glasses of water a day.<br>
          <b>3</b>&ensp;Being slightly fat is good for you.<br>
          <b>4</b>&ensp;Sleeping longer at weekends makes up for lost sleep.<br>
        </div>

        `,
        answer: [
          "He is extremely intelligent and he did unusual things at a very young age.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P76-E2",
    exerciseKey: "img/FriendsPlus/Page76/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text and check your answers to exercise 1.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 450, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page76/E2/1.jpg" style='height:19cm'/>
        
        `,
        answer: [
          "used to work",
          "used to clean / would clean",
          "used to have",
          "Did she use to wear",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P76-E3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find examples of each
        rule in the text in exercise 2.`,
        color: "black",
        left: 40,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 340, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page76/E3/1.jpg" style='height:17cm'/>
        <img src="img/FriendsPlus/Page76/E2/1.jpg" style='height:21cm'/>
          <div style='margin-left:10px'>
            <div style='display:flex'><b>1</b>&ensp;<textarea id=0 rows=3></textarea><br></div>
            <div style='display:flex'><b>2</b>&ensp;<textarea id=1 rows=3></textarea><br></div>
            <div style='display:flex'>
              <b>3</b>
              <div style='margin-left:10px'>
              <div style='display:flex'><b>a</b><textarea id=2 rows=3></textarea></div>
              <div style='display:flex'><b>b</b><textarea id=3 rows=3></textarea></div>
              </div>
            </div>
          </div>
        
        `,
        answer: [
          "which doesn't have to be consumed as water / serious health problems can be caused by obesity / your sleep might be disturbed",
          "While nobody likes being woken up",
          "it was thought something terrible would happen to them. It is often reported that serious health problems can be caused by obesity.",
          "Waking a sleepwalker was once widely believed to be dangerous / This myth is thought to have been started by the bottled water industry. / staying in bed too long is thought to make you feel more tired!",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P76-E4",
    exerciseKey: "img/FriendsPlus/Page76/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Write the sentences with the words in brackets and the
        correct passive form. Then match each sentence with a
        rule from the <span style='color:#f88b1f'>Learn this!</span> box.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 700,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page76/E3/1.jpg" style='height:16cm'/>
          <div style='margin-left:10px'>
            <div style='display:flex;'> <input text-align=center width=50px></input><div style='margin-bottom:10px'> - <b>1</b>&ensp;It (just / report) that too much exercise is bad for you.<br>#<br></div></div>
            <div style='display:flex;'> <input text-align=center width=50px></input><div style='margin-bottom:10px'> - <b>2</b>&ensp;I (not / remember / tell) about this diet before.<br>#<br></div></div>
            <div style='display:flex;'> <input text-align=center width=50px></input><div style='margin-bottom:10px'> - <b>3</b>&ensp;Mia (should / give) better advice by the doctor.<br>#<br></div></div>
            <div style='display:flex;'> <input text-align=center width=50px></input><div style='margin-bottom:10px'> - <b>4</b>&ensp;Nobody (want / criticise) for their appearance.<br>#<br></div></div>
            <div style='display:flex;'> <input text-align=center width=50px></input><div style='margin-bottom:10px'> - <b>5</b>&ensp;The actress (rumour / lose) more than five kilos last year.<br>#<br></div></div>
            <div style='display:flex;'> <input text-align=center width=50px></input><div> - <b>6</b>&ensp;Some medicines (must / only / take) with food.<br>#<br></div></div>
          </div>
        
        `,
        answer: [
          "3a",
          "has just been reported",
          "2",
          "don't remember being told",
          "1",
          "should have been given",
          "2",
          "wants to be criticised",
          "3b",
          "is / was rumoured to have lost",
          "1",
          "must only be taken",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P76-E5",
    exerciseKey: "img/FriendsPlus/Page76/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Complete the second sentence so that
        it has a similar meaning to the first.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 300,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "in three hours from now",
          "at midnight tonight",
          "in three months’ time",
          "by this time next year",
          "by your 20th birthday",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:15px'>
            People have known for years that too much salt is unhealthy.<br>
            It <input width=696px />.
          </div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:15px'>
            Someone ought to have checked the facts carefully. <br>
            The facts <input width=611px />.
          </div>
        </div>
        <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:15px'>
            I don’t like anyone telling me how much exercise to do.<br>
            I don’t like <input width=598px />.
          </div>
        </div>
        <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:15px'>
            Should we blame parents for overweight children?<br>
            Should parents <input width=542px />?
          </div>
        </div>
        <div style='display:flex'>
          <b>5</b>
          <div style='margin-left:15px'>
            It is said that Einstein slept for ten hours every night.<br>
            Einstein is said <input width=549px />.
          </div>
        </div>

        `,
        answer: [
          "has been known for years that too much salt is unhealthy",
          "ought to have been checked carefully",
          "being told how much exercise to do",
          "be blamed for overweight children",
          "to have slept for ten hours every night",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P76-E6",
    exerciseKey: "img/FriendsPlus/Page76/Key/E6answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Discuss the food myths and
        think of reasons why they are untrue. Try to use some of
        the passive structures from this lesson.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page76/E6/1.jpg" style='height:4.3cm'/>
        <ul>
          <li>Frozen vegetables are never as good for you as fresh ones.</li>
          <li>In a fast food restaurant, a burger contains more calories than a milkshake.</li>
        </ul>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P76-E7",
    exerciseKey: "img/FriendsPlus/Page76/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page76/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P76-E8",
    exerciseKey: "img/FriendsPlus/Page76/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page76/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
