import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import { CgMagnet } from "react-icons/cg";
const json = {
  1: {
    unit: "Introduction",
    id: "SB12-2024-I-P9-E1",
    exerciseKey: "img/FriendsPlus/Page9/Key/E1answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    recorder: true,
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the list of annoying
        habits. Which annoys you the most?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page9/E1/1.jpg" style='margin-left:100px;height:6cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Introduction",
    id: "SB12-2024-I-P9-E2",
    audio: "Audios/1.02.mp3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E2answerKey.png",
    component: T6,
    checkDuplicated: true,
    // hideBtnFooter:true,
    // textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 360, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["He’s always talking about his life"],
        // styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name='1.02' colorimg=#ffdab2  color='white'></headphone> Read and listen to the dialogue. Which of the
        faults in exercise 1 does Andy complain about? Do you
        agree with the advice Jessica gives Andy? Why? / Why not?`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;margin-bottom:40px'>
          <div>
            <b>Andy</b><br><br>
            <b>Jessica</b><br><br>
            <b>Andy</b><br>
            <b>Jessica</b><br><br>
            <b>Andy</b><br><br><br>
            <b>Jessica</b><br>
            <b>Andy</b><br><br>
            <b>Jessica</b>
          </div>
          <div style='margin-left:20px;width:80%'>
              Well, Tom never asks about what I’m doing. <sup>1</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>He’s always talking about his life</span> and what he’s going
              to do, but he doesn’t care about anyone else!<br>
               Yes, I’ve noticed that <sup>2</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>he never takes an interest in other people</span>. <u>Are you going to do anything about it?</u><br>
               What can I do? <sup>3</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>That’s the personality he’s got</span>.<br>
               <sup>4</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>Next time you see him</span>, tell him that it annoys
              you. <u>I’ll tell him if you want</u> because it irritates
              me too!<br>
               Perhaps I should, because <sup>5</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>his attitude is really putting me off him</span>. If he can’t change his
              behaviour, then I don’t see how we can stay
              friends. <sup>6</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>Our first lesson tomorrow is English</span>. <u>I’ll speak to him then</u>.<br>
               Good idea. If he’s really a good friend, <u>he won’t get angry</u>.<br>
               Well, thanks for your advice. <sup>7</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>I’m playing football this evening</span>, and <u>it looks like it’s going to rain</u>, so
              I’d better go home and pick up my waterproofs.
              See you.<br>
               OK. Good luck with Tom!
          </div>
        </div>
        <b>Answer:</b>#
         

        
        `,
        answer: ["He always talks about himself."],
      },
    ],
  },
  3: {
    unit: "Introduction",
    id: "SB12-2024-I-P9-E3",
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#f88b17'>Learn this!</span>  box. Match the highlighted sentences
        (1–7) in exercise 2 with the rules (a–g).`,
        color: "black",
        left: -50,
      },
    ],

    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 500, paddingTop: 10 },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
          <img src="img/FriendsPlus/Page9/E3/1.jpg" style='margin-left:-100px;height:12cm'/>
          <div>
            <b>a:</b>#<br>
            <b>b:</b>#<br>
            <b>c:</b>#<br>
            <b>d:</b>#<br>
            <b>e:</b>#<br>
            <b>f:</b>#<br>
            <b>g:</b>#
          </div>
        </div>
      <div style='display:flex;margin-bottom:40px'>
        <div>
          <b>Andy</b><br><br>
          <b>Jessica</b><br><br>
          <b>Andy</b><br>
          <b>Jessica</b><br><br>
          <b>Andy</b><br><br><br>
          <b>Jessica</b><br>
          <b>Andy</b><br><br>
          <b>Jessica</b>
        </div>
        <div style='margin-left:20px;width:80%'>
            Well, Tom never asks about what I’m doing. <sup>1</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>He’s always talking about his life</span> and what he’s going
            to do, but he doesn’t care about anyone else!<br>
             Yes, I’ve noticed that <sup>2</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>he never takes an interest in other people</span>. <u>Are you going to do anything about it?</u><br>
             What can I do? <sup>3</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>That’s the personality he’s got</span>.<br>
             <sup>4</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>Next time you see him</span>, tell him that it annoys
            you. <u>I’ll tell him if you want</u> because it irritates
            me too!<br>
             Perhaps I should, because <sup>5</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>his attitude is really putting me off him</span>. If he can’t change his
            behaviour, then I don’t see how we can stay
            friends. <sup>6</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>Our first lesson tomorrow is English</span>. <u>I’ll speak to him then</u>.<br>
             Good idea. If he’s really a good friend, <u>he won’t get angry</u>.<br>
             Well, thanks for your advice. <sup>7</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>I’m playing football this evening</span>, and <u>it looks like it’s going to rain</u>, so
            I’d better go home and pick up my waterproofs.
            See you.<br>
             OK. Good luck with Tom!
        </div>
      </div>
      
      
        
        `,
        notSymbol: true,
        answer: [
          `he never takes an interest in other people`,
          `That's the personality he's got.`,
          `Our first lesson tomorrow is English.`,
          `Next time you see him`,
          `his attitude is really putting me off him`,
          `He's always talking about his life`,
          `I'm playing football this evening`,
        ],
      },
    ],
  },

  4: {
    unit: "Introduction",
    id: "SB12-2024-I-P9-E4",
    exerciseKey: "img/FriendsPlus/Page9/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Complete the text with the correct present simple or
        continuous form of the verbs in brackets. More than one
        answer may be possible.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: {
      width: 350,
      background: "none",
      textAlign: "center",
      paddingTop: 10,
    },
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:80%;background:rgb(171,225,251);border-radius:2px;padding:5px 15px'>
          I frequently <sup>1</sup># (text) my friends on
          my mobile phone, but I’m not like some people
          who <sup>2</sup># (always use) their phones,
          even when they <sup>3</sup># (go out) with
          friends. I <sup>4</sup>#(use) it at the moment
          because I <sup>5</sup># (revise) with some
          friends tomorrow (our exams <sup>6</sup>#
          (start) on Monday) and we <sup>7</sup># (try)
          to decide where to meet. Texting <br> <sup>8</sup>#
          (help) you organise your life, but you shouldn’t
          dominate it! 
        </div>
      
      
        
        `,
        answer: [
          "text",
          "are always using / always use",
          "go out",
          "am using",
          "am revising",
          "start / are starting",
          "are trying",
          "helps",
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "SB12-2024-I-P9-E5",
    exerciseKey: "img/FriendsPlus/Page9/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Read the <span style='color:#f88b17'>Learn this!</span>  box. Complete the rules with will
        or be going to. Use the underlined examples from the
        dialogue in exercise 2 to help you.`,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      textAlign: "center",
      width: 186,
      paddingTop: 10,
    },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page9/E5/1.jpg" style='margin-left:100px;height:12cm'/>
          <div style='position:absolute;top:85px;left:177px'>#</div>
          <div style='position:absolute;top:150px;left:177px'>#</div>
          <div style='position:absolute;top:254px;left:177px'>#</div>
          <div style='position:absolute;top:319px;left:177px'>#</div>
          <div style='position:absolute;top:384px;left:262px'><input id=4 width='148px'></input></div>
        </div>
        <div style='display:flex;margin-bottom:40px'>
          <div>
            <b>Andy</b><br><br>
            <b>Jessica</b><br><br>
            <b>Andy</b><br>
            <b>Jessica</b><br><br>
            <b>Andy</b><br><br><br>
            <b>Jessica</b><br>
            <b>Andy</b><br><br>
            <b>Jessica</b>
          </div>
          <div style='margin-left:20px;width:80%'>
              Well, Tom never asks about what I’m doing. <sup>1</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>He’s always talking about his life</span> and what he’s going
              to do, but he doesn’t care about anyone else!<br>
               Yes, I’ve noticed that <sup>2</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>he never takes an interest in other people</span>. <u>Are you going to do anything about it?</u><br>
               What can I do? <sup>3</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>That’s the personality he’s got</span>.<br>
               <sup>4</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>Next time you see him</span>, tell him that it annoys
              you. <u>I’ll tell him if you want</u> because it irritates
              me too!<br>
               Perhaps I should, because <sup>5</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>his attitude is really putting me off him</span>. If he can’t change his
              behaviour, then I don’t see how we can stay
              friends. <sup>6</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>Our first lesson tomorrow is English</span>. <u>I’ll speak to him then</u>.<br>
               Good idea. If he’s really a good friend, <u>he won’t get angry</u>.<br>
               Well, thanks for your advice. <sup>7</sup><span style='background:rgb(253,194,138);padding: 0px 5px; border-radius:10px'>I’m playing football this evening</span>, and <u>it looks like it’s going to rain</u>, so
              I’d better go home and pick up my waterproofs.
              See you.<br>
               OK. Good luck with Tom!
          </div>
        </div>
      
      
        
        `,
        answer: ["be going to", "will", "be going to", "will", "will"],
      },
    ],
  },
  6: {
    unit: "Introduction",
    id: "SB12-2024-I-P9-E6",
    exerciseKey: "img/FriendsPlus/Page9/Key/E6answerKey.png",
    component: T6,
    checkDuplicated: true,
    textAlign: "center",
    stylesTextInput: { borderBottom: "none", width: 170, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "continue",
          "forget",
          "go on",
          "like",
          "prefer",
          "remember",
          "start",
          "stop",
          "try",
        ],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;justify-content:center'>
          <img src="img/FriendsPlus/Page9/E6/1.jpg" style='height:6cm'/>
        </div>
        
        
        `,
        InputRong: true,
        answer: [
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
        ],
      },
    ],
  },
  7: {
    unit: "Introduction",
    id: "SB12-2024-I-P9-E7",
    exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title:
          "<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Ask and answer the following questions, using the proper intonation patterns.",
        color: "black",
        width: "27cm",
        left: -50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:48px'>
          Do your friends have any annoying habits?<br>
          What do you do if someone has habits that are annoying?<br>
          Do you often enjoy your school holiday with your friends?<br>
          What plans do you have for the next school holiday?<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Introduction",
    id: "SB12-2024-I-P9-E8",
    exerciseKey: "img/FriendsPlus/Page9/Key/E8answerKey.png",
    component: T6,
    recorder: true,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Tell your partner about
        something you did recently. Use verbs from this lesson.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
