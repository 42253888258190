import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P99-E1",
    exerciseKey: "img/FriendsPlus/Page99/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Look at the slogans. What are
        they about?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      resize: "none",
      //   marginBottom: -23,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <div style='display:flex'><img src="img/FriendsPlus/Page99/E1/1.jpg" style='height:1.2cm'/>:<textarea id=0 rows=2></textarea></div>
        <div style='display:flex'><img src="img/FriendsPlus/Page99/E1/2.jpg" style='height:1.2cm'/>:<textarea id=1 rows=2></textarea></div>
        <div style='display:flex'><img src="img/FriendsPlus/Page99/E1/3.jpg" style='height:1.2cm'/>:<textarea id=2 rows=2></textarea></div>
        <div style='display:flex'><img src="img/FriendsPlus/Page99/E1/4.jpg" style='height:1.2cm'/>:<textarea id=3 rows=2></textarea></div>
        `,
        answer: [
          "(Occupy Wall Street) protests against social and economic inequality.",
          "(Black Lives Matter) protests against violence towards black people.",
          "(Anti-war) protests against starting or taking part in a war.",
          "(Climate summit) protests against global warming and climate change.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P99-E2",
    exerciseKey: "img/FriendsPlus/Page99/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. Is it generally in favour of or against
        online campaigns?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 600, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Travel collocations</b>"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
      {
        src: [
          "do",
          "get",
          "go",
          "have",
          "help",
          "light",
          "put up",
          "sleep",
          "taste",
          "travel",
        ],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>Answer:</b>#
        <img src="img/FriendsPlus/Page99/E2/1.jpg" style='margin-top:20px;height:31cm'/>
        
        `,
        // InputRong: true,
        answer: ["It is generally in favour of online campaigns."],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P99-E3",
    exerciseKey: "img/FriendsPlus/Page99/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#c50d47'>Listening Strategy</span>. Then, add the
        phrases below to complete the examples.`,
        color: "black",
        left: 0,
        width: "28cm",
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "as I was saying",
          "even so",
          "for instance",
          "incidentally",
          "nevertheless",
          "still",
        ],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        width: "80%",
      },
      {
        src: ["<b>Discourse markers</b>"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    stylesTextInput: { width: 200, textAlign: "center" },

    component: T6,
    // character: "|",
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page99/E3/1.jpg" style='margin-top:20px;height:7cm'/>
        <hintbox id=0></hintbox>
        <div style='width:24cm;border-left: 5px solid rgb(192,10,66); padding-left:15px'>
            <hintbox id=1></hintbox>
            <b style='color:rgb(192,10,66)'></b>
            <b style='color:rgb(192,10,66)'>An example / more detail:</b> you know … / <sup>1</sup># …<br>
            <b style='color:rgb(192,10,66)'>A contrast or contradiction:</b> however … / <sup>2</sup># … / mind you … / <sup>3</sup># … / all the same … / <sup>4</sup># …<br>
            <b style='color:rgb(192,10,66)'>An afterthought:</b> by the way … / <sup>5</sup># …<br>
            <b style='color:rgb(192,10,66)'>A continuation of the topic / going back to an earlier topic:</b> talking of which, … <sup>6</sup># …<br>
        </div>
          
        `,
        // InputRong: true,
        answer: [
          "for instance",
          "even so",
          "nevertheless",
          "still",
          "incidentally",
          "as I was saying",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P99-E4",
    exerciseKey: "img/FriendsPlus/Page99/Key/E4answerKey.png",
    audio: "Audios/3.17.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='3.18' colorimg=#eab5b2  color='white'></headphone> Listen to speakers (1–4). What do you predict
        you will hear next? Choose one of the following lines.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 0,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["save", "learn", "have", "lie", "rise", "go", "work"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 100,
      textAlign: "center",
    },
    hideBtnFooter: true,
    component: T6,
    // character: "|",
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>A</b>&ensp;… it hasn’t been very well publicised.<br>
            <b>B</b>&ensp;… we should not stop trying.<br>
            <b>C</b>&ensp;… he handled the public’s questions well.<br>
            <b>D</b>&ensp;… how much did you pay for your TV?<br>
        </div>
        
        `,
        // InputRong: true,
        answer: [
          "T|True",
          "T|True",
          "F|False",
          "T|True",
          "T|True",
          "F|False",
          "T|True",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P99-E5",
    exerciseKey: "img/FriendsPlus/Page99/Key/E5answerKey.png",
    audio: "Audios/3.18.mp3",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='3.18' colorimg=#eab5b2  color='white'></headphone> Now listen and check your answers.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 150,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Exercise verbs</b>", "ache", "burn", "convert", "pump"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        // width: "70%",
      },
      {
        src: [
          "<b>Exercise nouns </b>",
          "adrenalin",
          "burst",
          "energy",
          "intensity",
        ],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        // width: "70%",
      },
    ],
    // hideBtnFooter: true,
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    select: true,
    selectOptionRandom: true,
    selectOptionValues: ["1", "2", "3", "4"],
    component: T6,
    // character: "|",
    questions: [
      {
        title: `

        <div style='line-height:50px'>
            <div style='display:flex'><select id=0></select><b>A</b>&ensp;… it hasn’t been very well publicised.<br></div>
            <div style='display:flex'><select id=1></select><b>B</b>&ensp;… we should not stop trying.<br></div>
            <div style='display:flex'><select id=2></select><b>C</b>&ensp;… he handled the public’s questions well.<br></div>
            <div style='display:flex'><select id=3></select><b>D</b>&ensp;… how much did you pay for your TV?<br></div>
        </div>
        `,
        // InputRong: true,
        answer: ["1", "3", "4", "2"],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P99-E6",
    exerciseKey: "img/FriendsPlus/Page99/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    textareaStyle: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#c50d47'>Pronunciation</span> box and practise saying the
        sentences in exercises 4 and 5 using the rise and fall
        intonation.`,
        color: "black",
        width: "28cm",
        left: -30,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "two",
          "theirs know",
          "cues",
          "hear",
          "one",
          "sow",
          "sea",
          "hour",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page99/E6/1.jpg" style='height:10cm'/>
          <div style='line-height:50px'>
            <b>A</b>&ensp;… it hasn’t been very well publicised.<br>
            <b>B</b>&ensp;… we should not stop trying.<br>
            <b>C</b>&ensp;… he handled the public’s questions well.<br>
            <b>D</b>&ensp;… how much did you pay for your TV?<br>
          </div>
          `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P99-E7",
    exerciseKey: "img/FriendsPlus/Page99/Key/E7answerKey.png",
    audio: "Audios/3.19.mp3",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='3.19' colorimg=#eab5b2  color='white'></headphone> Listen to three people. Match speakers (1–3)
        with sentences (A–C). Write the correct number.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Exercise collocations</b>"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        // width: "70%",
      },
      {
        src: ["attend", "be", "beat", "do", "lift", "push", "pedal"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        // width: "70%",
      },
    ],
    selectStyle: {
      marginTop: 0,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["1", "2", "3", "4"],
    stylesTextInput: { width: 100, textAlign: "center" },
    questions: [
      {
        title: `
        <div style='width:26cm'>
            The speaker …<br>
            <div style='display:flex'><select id=0></select><b>A</b>&ensp;feels strongly that fighting for a cause online is largely ineffective.<br></div>
            <div style='display:flex'><select id=1></select><b>B</b>&ensp;has found that donations made to online campaigns are not as high as those achieved by other methods.<br></div>
            <div style='display:flex'><select id=2></select><b>C</b>&ensp;is enthusiastic about learning about global issues through social media.<br></div>
        </div>
        `,
        answer: ["3", "2", "1"],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P99-E8",
    exerciseKey: "img/FriendsPlus/Page99/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
            Do you love to take part in online campaigns in social
            media? Why? / Why not?
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
