import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P51-E1",
    exerciseKey: "img/FriendsPlus/Page51/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Look at the advert. Would
        you like to go there? Discuss the advantages and
        disadvantages of such a holiday`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page51/E1/1.jpg" style='height:15cm'/>
        

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P51-E2",
    exerciseKey: "img/FriendsPlus/Page51/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the travel collocations with the
        verbs below. 
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 100, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Travel collocations</b>"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
      {
        src: [
          "do",
          "get",
          "go",
          "have",
          "help",
          "light",
          "put up",
          "sleep",
          "taste",
          "travel",
        ],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <div style='display:flex;line-height:47px'>
          <div>
            <b>1</b>&ensp;#a campfire<br>
            <b>2</b>&ensp;#a local dish<br>
            <b>3</b>&ensp;#a lovely view<br>
            <b>4</b>&ensp;#rough<br>
            <b>5</b>&ensp;#the tent<br>
          </div>
          <div style='margin-left:100px'>
            <b>6</b>&ensp;#someone with their bags<br>
            <b>7</b>&ensp;#away from it all<br>
            <b>8</b>&ensp;#light<br>
            <b>9</b>&ensp;#off the beaten track<br>
            <b>10</b>&ensp;#some volunteer work <br>
          </div>
        </div>
        
        `,
        // InputRong: true,
        answer: [
          "light",
          "taste",
          "have",
          "sleep",
          "put up",
          "help",
          "get",
          "travel",
          "go",
          "do",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P51-E3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E3answerKey.png",
    audio: "Audios/2.03.mp3",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='2.03' colorimg=#eab5b2  color='white'></headphone> Read the <span style='color:#c50d47'>Listening Strategy</span> Listen to four
        dialogues and identify a) who the speakers are and
        b) where they are.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 720, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page51/E3/1.jpg" style='height:6cm'/>
        
        <div style='line-height:47px'>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:15px'><b>a</b>&ensp;#<br><b>b</b>&ensp;#</div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:15px'><b>a</b>&ensp;#<br><b>b</b>&ensp;#</div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:15px'><b>a</b>&ensp;#<br><b>b</b>&ensp;#</div>
          </div>
          <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:15px'><b>a</b>&ensp;#<br><b>b</b>&ensp;#</div>
          </div>
        </div>
        
        `,
        // InputRong: true,
        answer: [
          "The speakers are husband and wife / boyfriend and girlfriend.",
          "The man is on a train, the woman is at home.",
          "The speakers are friends or classmates.",
          "They are at school.",
          "The speakers are acquaintances.",
          "They are in a coffee shop.",
          "The speakers are a hotel guest and the hotel receptionist.",
          "They are at the reception desk of a hotel.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P51-E4",
    exerciseKey: "img/FriendsPlus/Page51/Key/E4answerKey.png",
    audio: "Audios/2.03.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='2.03' colorimg=#eab5b2  color='white'></headphone> Listen again. For each dialogue, answer the
        questions below. Say which clues helped you to answer.
        
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["save", "learn", "have", "lie", "rise", "go", "work"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 1070,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;What kind of relationship is it?<br>
            <b>2</b>&ensp;What is the approximate time or time of day of the dialogue?<br>
            <b>3</b>&ensp;What is the reason for the dialogue?<br>
        </div>
          <b>Answer</b><br>
          <b>Dialogue 1</b><br>
          <b>1</b>#<br>
          <b>2</b>#<br>
          <b>3</b>#<br>
          <b>Dialogue 2</b><br>
          <b>1</b>#<br>
          <b>2</b>#<br>
          <b>3</b>#<br>
          <b>Dialogue 3</b><br>
          <b>1</b>#<br>
          <b>2</b>#<br>
          <b>3</b>#<br>
          <b>Dialogue 4</b><br>
          <b>1</b>#<br>
          <b>2</b>#<br>
          <b>3</b>#<br>
        `,
        answer: [
          "The speakers are husband and wife or boyfriend and girlfriend.",
          "It is about five o'clock in the afternoon.",
          "The man is not going to be home late after all.",
          "The speakers are friends or classmates.",
          "It is afternoon, near the end of school.",
          "One speaker asks why the other speaker is carrying a rucksack into class and the friends chat.",
          "The speakers hardly know each other.",
          "It is probably late morning or early afternoon.",
          "The first speaker would like to find out some information from the second speaker.",
          "The speakers are a hotel guest and the hotel receptionist.",
          "It is just before six in the evening.",
          "The guest is checking in.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P51-E5",
    exerciseKey: "img/FriendsPlus/Page51/Key/E5answerKey.png",
    audio: "Audios/2.04.mp3",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='2.04' colorimg=#eab5b2  color='white'></headphone> Listen to another conversation. Decide if the
        statements are true (T), false (F), or if the information is
        not given (NG).
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 140,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "in three hours from now",
          "at midnight tonight",
          "in three months’ time",
          "by this time next year",
          "by your 20th birthday",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: "70%",
      },
    ],
    // character: ",",

    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
          <div>
            <b>1</b>&ensp;The conversation is happening outside a coffee shop.#<br>
            <b>2</b>&ensp;Annie and Simon usually go to the gym together.#<br>
            <b>3</b>&ensp;Annie’s first reaction to Simon’s suggestion is disbelief.#<br>
            <b>4</b>&ensp;Simon generally prefers to go somewhere remote on holiday.#<br>
            <b>5</b>&ensp;Simon cannot persuade Annie that a holiday in the Antarctic is harmless for the environment.#<br>
            <b>6</b>&ensp;Annie and Simon work for the same company.#<br>
            <b>7</b>&ensp;Simon explains he’s only joking about the holiday.#<br>
          </div>
        `,
        answer: [
          "F|false",
          "NG|Not given",
          "T|true",
          "F|false",
          "F|false",
          "NG|Not given",
          "T|true",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P51-E6",
    exerciseKey: "img/FriendsPlus/Page51/Key/E6answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    // textAlign: "center",

    textareaStyle: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Listen to the conversation in exercise 5 again
        and write down the homophones for the following
        words:`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "two",
          "theirs know",
          "cues",
          "hear",
          "one",
          "sow",
          "sea",
          "hour",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P51-E7",
    exerciseKey: "img/FriendsPlus/Page51/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page51/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P51-E8",
    exerciseKey: "img/FriendsPlus/Page51/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page51/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
