import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P108-E1",
    exerciseKey: "img/FriendsPlus/Page108/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Word families are groups of words formed from a base
        word. Complete the chart.
        `,
        color: "black",
        width: "28cm",
        left: 30,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 290, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },

    // hideBtnFooter: true,
    character: /[/|]/,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <div style='text-align:center'>
                <b>Noun</b><br>
                wonder<br>
                ease<br>
                hope<br>
                #<br>
                beauty<br>
                #<br>#<br>#<br>
                surprise<br>
                annoy<br>
            </div>
            <div style='margin:0px 150px;text-align:center'>
                <b>Adjective</b><br>
                wonderful<br>
                easy<br>
                #<br>
                lazy<br>
                #<br>#<br>#<br>
                sad<br>
                #<br>#<br>
            </div>
        </div>
        <div style='margin-top:50px;display:flex'>
            <div style='text-align:center'>
                <b>Verb</b><br>
                wonder<br>
                #<br>#<br>#<br>#<br>
                create<br>
                please<br>
                #<br>#<br>#<br>
            </div>
            <div style='margin:0px 150px;text-align:center'>
                <b>Adverb</b><br>
                wonderfully<br>
                #<br>#<br>#<br>#<br>#<br>#<br>#<br>#<br>#<br>
            </div>
        </div>
        
        `,
        answer: [
          "laziness|creation|pleasure|sadness",
          "laziness|creation|pleasure|sadness",
          "laziness|creation|pleasure|sadness",
          "laziness|creation|pleasure|sadness",
          "hopeful / hopeless|beautiful|creative|pleasant|surprising|annoying",
          "hopeful / hopeless|beautiful|creative|pleasant|surprising|annoying",
          "hopeful / hopeless|beautiful|creative|pleasant|surprising|annoying",
          "hopeful / hopeless|beautiful|creative|pleasant|surprising|annoying",
          "hopeful / hopeless|beautiful|creative|pleasant|surprising|annoying",
          "hopeful / hopeless|beautiful|creative|pleasant|surprising|annoying",
          "ease|hope|laze|beautify|sadden|surprise|annoy",
          "ease|hope|laze|beautify|sadden|surprise|annoy",
          "ease|hope|laze|beautify|sadden|surprise|annoy",
          "ease|hope|laze|beautify|sadden|surprise|annoy",
          "ease|hope|laze|beautify|sadden|surprise|annoy",
          "ease|hope|laze|beautify|sadden|surprise|annoy",
          "ease|hope|laze|beautify|sadden|surprise|annoy",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
          "easily|hopefully / hopelessly|lazily|beautifully|creatively|pleasantly|sadly|surprisingly|annoyingly",
        ],
      },
    ],
  },
  2: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P108-E2",
    exerciseKey: "img/FriendsPlus/Page108/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Use the chart in exercise 1 to complete the sentences with
        the correct form of the words.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 100, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Food and health</b>",
          "dairy products",
          "fizzy drinks",
          "poultry",
          "processed foods",
          "pulses",
          "saturated fat",
          "wholegrain",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div>
            <b>1</b>&ensp;Because of your laz#, we’ll never get finished in time.<br>
            <b>2</b>&ensp;I must say, I find your attitude a little sur#.<br>
            <b>3</b>&ensp;He really does play the piano beaut#.<br>
            <b>4</b>&ensp;She’s far too clever. She does everything annoy# well.<br>
            <b>5</b>&ensp;We have to solve this problem fast. We need to think creat#.<br>
            <b>6</b>&ensp;He never even gave me a birthday card and that sad# me.<br>
            <b>7</b>&ensp;Take two of these tablets. It’ll help to eas# the pain.<br>
            <b>8</b>&ensp;They spent a very pleas# hour in the park walking and talking.<br>
            <b>9</b>&ensp;I’ve eaten John’s cake, but hop# he won’t notice!<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "iness",
          "prising",
          "ifully",
          "ingly",
          "ively",
          "dened",
          "e",
          "ant",
          "efully",
        ],
      },
    ],
  },
  3: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P108-E3",
    exerciseKey: "img/FriendsPlus/Page108/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the prepositions below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about", "at", "by", "from", "of", "on", "to", "with"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: ["The inspector: reserved, loyal"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    stylesTextInput: { textAlign: "center", width: 90, paddingTop: 10 },
    // character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:27cm'>
            <b>1</b>&ensp;My little brother is obsessed # football. He talks about nothing else.<br>
            <b>2</b>&ensp;Her unruly behaviour at school is characteristic # a troubled home life.<br>
            <b>3</b>&ensp;Your teacher is very concerned # your lack of progress in maths.<br>
            <b>4</b>&ensp;I know my car is getting old now, but I’m very attached # it.<br>
            <b>5</b>&ensp;Sarah is very emotionally detached # her family.<br>
            <b>6</b>&ensp;My grandma is very dependent # my parents.<br>
            <b>7</b>&ensp;You’re very skilful # putting your bike back together again.<br>
            <b>8</b>&ensp;He was completely shocked # his parents’ divorce.<br>
        </div>
        `,
        // InputRong: true,
        answer: ["with", "of", "about", "to", "from", "on", "at", "by"],
      },
    ],
  },
  4: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P108-E4",
    exerciseKey: "img/FriendsPlus/Page108/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with adjectives formed from the
        nouns in brackets.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div>
            <b>1</b>&ensp;I didn’t mean to break it. It was entirely # . (accident)<br>
            <b>2</b>&ensp;I don’t know if I’ll pass that exam, but I’m # . (hope)<br>
            <b>3</b>&ensp;I hope the show goes well. It could be # (disaster).<br>
            <b>4</b>&ensp;I hope your meal is better than mine! Mine’s really # (taste).<br>
            <b>5</b>&ensp;What’s the weather like? Is it # (wind) or just # (breeze)?<br>
            <b>6</b>&ensp;Ask Jack. He’ll do it. He’s always # (rely) and # (depend).<br>
        </div>
        `,
        answer: [
          "accidental",
          "hopeful",
          "disastrous",
          "tasteless",
          "windy",
          "breezy",
          "reliable",
          "dependable",
        ],
      },
    ],
  },
  5: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P108-E5",
    exerciseKey: "img/FriendsPlus/Page108/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Copy the table and write the phrasal verbs in the correct
        columns.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 800,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "ask someone out",
          "break off something",
          "chat someone up",
          "fall for someone",
          "get over someone / something",
          "go off someone",
          "go out with someone",
          "pack something in",
          "run after someone",
          "split up (with someone)",
        ],
        styleElement: {
          color: "#91268e",
          marginBottom: 10,
          marginRight: "40px",
        },
        width: "90%",
      },
    ],
    character: /[,/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <table style="width: 50%; border-collapse: collapse;border: 2px solid rgb(146,39,143);">
        <tr>
          <th style="width:20%;color:white; background-color: rgb(146,39,143); border-right: 2px solid white; padding: 8px; text-align: center;">Starting a relationship</th>
          <th style="width:20%;color:white; background-color: rgb(146,39,143);  padding: 8px; text-align: center;">Finishing a relationship</th>
        </tr>
        <tr>
          <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><textarea id=0 rows=4></textarea></td>
          <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><textarea id=1 rows=4></textarea></td>
        </tr>
      </table>
        
        `,
        answer: [
          "ask someone out, chat someone up, fall for someone, go out with someone, run after someone",
          "break off something, get over someone / something, go off someone, pack something in, split up (with someone)|split up",
        ],
      },
    ],
  },
  6: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P108-E6",
    exerciseKey: "img/FriendsPlus/Page108/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 140 },
    titleQuestion: [
      {
        num: "2",
        title: `Complete the dialogue with the correct form of the
        phrasal verbs from exercise 1.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div>
                <b>A</b> <br><b>B</b> <br><b>A</b> <br><b>B</b> <br><b>A</b> <br><br><b>B</b> <br>
            </div>
            <div  style='margin-left:15px;width:25cm'>
                 Have you heard? Maddy and James have <sup>1</sup># !
                <br> I don’t believe it! But they’re engaged!
                <br> Well, they were, but she’s <sup>2</sup># the engagement.
                <br> But why?
                <br> I have no idea. She must have <sup>3</sup># him all of a
                sudden. Why else would she <sup>4</sup># it ?
                <br> But that’s terrible. She can’t do that! He’s madly in love
                with her, poor guy. Remember how quickly he <sup>5</sup>#
                her when he first met her? He’ll never <sup>6</sup># it.
            </div>
          </div>
          `,
        answer: [
          "split up",
          "broken off",
          "gone off",
          "pack (it) in",
          "fell for",
          "get over",
        ],
      },
    ],
  },
  7: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P108-E7",
    exerciseKey: "img/FriendsPlus/Page108/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Add an example of your own for
        each type of adjective formation.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 330, paddingTop: 10 },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page108/E7/1.jpg" style='height:11cm'/><br>
            <div style='margin-left:10px'>
                <b>-ful:</b> #<br>
                <b>-ive:</b> #<br>
                <b>-al:</b> #<br>
                <b>-able:</b> #<br>
                <b>-ous:</b> #<br>
                <b>-y:</b> #<br>
                <b>-less:</b> #<br>
            </div>
        </div>
        `,
        answer: [
          "truthful, useful",
          "active, creative",
          "magical, fatal",
          "breakable, uncontrollable",
          "dangerous, envious",
          "salty, muddy",
          "helpless, lifeless",
        ],
      },
    ],
  },
  8: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P108-E8",
    exerciseKey: "img/FriendsPlus/Page108/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page108/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
