import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Review Unit 4",
    id: "SB12-2024-U4-P59-E1",
    exerciseKey: "img/FriendsPlus/Page59/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Reading</span> <br> Read the text. Choose the best answers (A–D).`,

        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
        ],
        answers: ["0-12", "1-0", "2-4", "3-8"],
        initialValue: [],
      },
      Layout: `
      <b style='font-size:30px'>The rise of Airbnb</b>
      
      <div style='margin-bottom:15px; width:25cm'>
          In 2007, design graduates, Joe Gebbie and Brian Chesky,
          were struggling to pay the rent for their San Francisco
          apartment. Hearing that there was a conference coming to
          town and there were no hotel rooms available, they created
          the website airbedandbreakfast.com where they advertised
          three airbeds in their home at $80 each a night, breakfast
          included. Only six days later, they had three guests sleeping
          on their floor. They knew immediately that this was the
          start of something big.
          <div style='margin-top:15px'>Being budding entrepreneurs, the pair decided to take their
          idea further. They enlisted Gebbie’s former flatmate, Nathan
          Blecharczyk, a computer science graduate, to develop their
          website. Their idea was to target conferences and festivals
          across the USA, getting local people to list their rooms and
          travellers to book them. The new website was completed
          just in time for the 2008 Democratic National Convention in
          Denver, at which Barack Obama was due to speak in front
          of 80,000 people. Within a week, they had 800 listings, an
          achievement which dealt in part with the shortage of hotel
          rooms, but did not solve their financial problems, as the
          site was not making any money
          </div>
          <div style='margin-top:15px'>The team decided that they would have to handle
          payment for the bookings. They began to charge three
          per cent to the host and between six and twelve per cent
          to the traveller, depending on the price of the booking.
          Meanwhile, investors had started showing interest in the
          company. By April 2009, when larger investments began to
          arrive, they moved the company out of their flat into a new
          state-of-the-art office and hired more staff.</div>
          <div style='margin-top:15px'>Since then Airbnb has gone from strength to strength. The
          company now has over 1.5 million listings in 34,000 cities in
          190 countries, and is rumoured to be worth around $20 billion.</div>
        </div>
        <b>1</b>&ensp;Gebbie and Chesky decided to rent out floor space because<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=0 type='Circle' />
          </div>
          <div>
            they wanted to attract people to a conference in their area.<br>
            they needed money to redecorate the living room.<br>
            there weren’t any hotels near their local conference centre.<br>
            there was a temporary need for accommodation in the city.<br>
          </div>
         </div>
        <b>2</b>&ensp;The new website designed by Nathan Blecharczyk enabled Gebbie and Chesky to<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=1 type='Circle' />
          </div>
          <div>
            accommodate most of the guests at a Denver convention.<br>
            make a huge profit as soon as it was launched.<br>
            rent out properties nationally.<br>
            put travellers in touch with hosts abroad<br>
            
          </div>
         </div>
        <b>3</b>&ensp;When booking a room with Airbnb, <br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=2 type='Circle' />
          </div>
          <div>
            it is only the guest who pays.<br>
            both the guest and the host have to pay.<br>
            the guest pays a fixed percentage of the price.<br>
            the guest and the host share the cost equally<br>
          </div>
         </div>
        <b>4</b>&ensp;Until April 2009, Gebbie and Chesky were running the business from<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=3 type='Circle' />
          </div>
          <div>
            a brand new building.<br>
            a friend’s house.<br>
            their own home.<br>
            a flat on Wall Street.<br>
          </div>
         </div>
        
         </div>
         `,
    },
  },
  2: {
    unit: "Review Unit 4",
    id: "SB12-2024-U4-P59-E2",
    exerciseKey: "img/FriendsPlus/Page59/Key/E2answerKey.png",
    audio: "Audios/2.09.mp3",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Listening</span><br>
        <headphone name='2.09' colorimg=#9bc5d6  color='white'></headphone> You will hear four speakers talking about their
        experiences of camping. Match questions (A–E) with
        speakers (1–4). There is one extra question.
        
        
        `,
        color: "black",
        left: 0,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        Which speaker … <br>
        <b>A</b>&ensp;didn’t feel comfortable with the people they were with?<br>
        <b>B</b>&ensp;wasn’t able to use all of the facilities they were provided with?<br>
        <b>C</b>&ensp;omitted to pack something essential for the trip? <br>
        <b>D</b>&ensp;had to call for assistance in the middle of the night?<br>
        <b>E</b>&ensp;was forced to cut their holiday short?<br>
        <div style='display:flex'>
          <div>
            Speaker 1:#<br>
            Speaker 2:#<br>
          </div>
          <div style='margin-left:200px'>
            Speaker 3:#<br>
            Speaker 4:#<br>
          </div>
        </div>
 
        `,
        answer: ["B", "A", "E", "C"],
      },
    ],
  },
  3: {
    unit: "Review Unit 4",
    id: "SB12-2024-U4-P59-E3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E3answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Speaking</span><br>
        Look at the two photos below that show different places
to stay. Compare the photos using the points:`,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <ul>
            <li>Location</li>
            <li>Facilities</li>
            <li>Cost</li>
            <li>Activities</li>
          </ul>
          <img src="img/FriendsPlus/Page59/E3/1.jpg" style='margin-left:50px;height:7cm'/>
        </div>
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },

  4: {
    unit: "Review Unit 4",
    id: "SB12-2024-U4-P59-E4",
    exerciseKey: "img/FriendsPlus/Page59/Key/E4answerKey.png",
    textareaStyle: { width: 1000, paddingTop: 1 },
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Writing</span><br>
        You have just come back from a class trip which did not
meet your expectations. Write a letter (180–200 words) to
the organiser of the trip stating your dissatisfaction with
the programme and transport and offering suggestions to
avoid future problems.
       `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
          
        `,
        answer: ["Round 2", "Family life", "Flowers", "Pink", "Yes, they did."],
      },
    ],
  },
  5: {
    unit: "Review Unit 4",
    id: "SB12-2024-U4-P59-E5",
    exerciseKey: "img/FriendsPlus/Page59/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `You are having a family reunion at the end of
        the month. Plan a short trip for your family on that day,
        including:`,
        color: "black",
        left: 50,
        width: "25cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Where you and your family are going ...<br>
          <b>2</b>&ensp;What activities you are participating in ...<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Review Unit 4",
    id: "SB12-2024-U4-P59-E6",
    exerciseKey: "img/FriendsPlus/Page59/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page59/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Review Unit 4",
    id: "SB12-2024-U4-P59-E7",
    exerciseKey: "img/FriendsPlus/Page59/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Review Unit 4",
    id: "SB12-2024-U4-P59-E8",
    exerciseKey: "img/FriendsPlus/Page59/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page59/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
