import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P25-E6",
    exerciseKey: "img/FriendsPlus/Page25/Key/E6answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "6",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Read the situations from
        the <i>It drives me crazy!</i> list. Which ones irritate
        you the most? Add three things to the list.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        
          <img src="img/FriendsPlus/Page25/E6/1.jpg" style='margin-left:100px;height:26cm'/>
          
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },

  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P25-E7",
    audio: "Audios/1.15.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='1.15' colorimg=#d0b4da  color='white'></headphone> Listen to four speakers and answer the
        questions.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: {
      width: 800,
      resize: "none",
      marginBottom: -30,
      paddingTop: 3,
    },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;What gets on Speaker 1’s nerves?<br>
            <textarea id=0 rows=3></textarea>
            <b>2</b>&ensp;What makes Speaker 2 blow her top?<br>
            <textarea id=1 rows=3></textarea>
            <b>3</b>&ensp;Why was Speaker 3 over the moon?<br>
            <textarea id=2 rows=3></textarea>
            <b>4</b>&ensp;What is Speaker 4 in two minds about?<br>
            <textarea id=3 rows=3></textarea>
        </div>
        `,
        answer: [
          "People who are always updating their status on social networking sites. Emotional messages about how somebody is missing their boyfriend or girlfriend terribly and how miserable they feel.",
          "People who are constantly eating popcorn in the cinema.",
          "Because they got a new laptop for Christmas.",
          "Taking part in more online forums.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P25-E8",
    exerciseKey: "img/FriendsPlus/Page25/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 700, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Read the <span style='color:#91268e'>Recycle</span> box and the sentences below.
        Say whether the questions refer to what is
        happening now, an annoying habit, or future
        arrangements.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page25/E8/1.jpg" style='margin-left:100px;height:10cm'/>
        <div style='width:80%;line-height:50px'>
            <b>1</b>&ensp;Why does Speaker 1 think that the comments
            that he’s always reading on social networking
            sites about people’s relationships are strange?<br>#<br>
            <b>2</b>&ensp;What is the local cinema doing that will make
            a bad situation worse for Speaker 2?<br>#<br>
            <b>3</b>&ensp;What is Speaker 3 doing tomorrow to solve his
            problem with a laptop?<br>#<br>
            <b>4</b>&ensp;What is Speaker 4 reading that she finds funny
            sometimes, but very annoying at other times?<br>#<br>
        </div>
        `,
        answer: [
          "an annoying habit",
          "future arrangements",
          "future arrangements",
          "what is happening now",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P25-E9",
    audio: "Audios/1.15.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E9answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "9",
        title: `<headphone name='1.15' colorimg=#d0b4da  color='white'></headphone> Listen again. Note down the answers to
        the questions in exercise 8.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { width: 800, paddingTop: 10 },
    textareaStyle: {
      width: 800,
      resize: "none",
      marginBottom: -30,
      paddingTop: 3,
    },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;#<br>
            <b>2</b>&ensp;#<br>
            <b>3</b>&ensp;#<br>
            <b>4</b>&ensp;#<br>
        </div>
        `,
        answer: [
          "Because they get on his nerves.",
          "They are offering free snacks for a week at the end of the month.",
          "He's taking his new laptop back to the shop tomorrow.",
          "She's reading an online forum about recycling.",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P25-E10",
    exerciseKey: "img/FriendsPlus/Page25/Key/E10answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "10",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Answer the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:80%'>
            <b>1</b>&ensp;Is anything making you anxious at the moment?<br>
            <b>2</b>&ensp;Are you in two minds about doing something?
            What could help you to be less indecisive?<br>
            <b>3</b>&ensp;When was the last time you were over the
            moon about something?<br>
            <b>4</b>&ensp;Have you ever lost face because of something
            you did? How did you improve the situation?<br>
            <b>5</b>&ensp;What kind of behaviour makes you blow your
            top? What was the last thing that made you
            furious?<br>
        </div>
        
          
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
};

export default json;
