import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P29-E1",
    exerciseKey: "img/FriendsPlus/Page29/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Read the forum post. In your own words, say
        what Marco is worried about.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        
          <img src="img/FriendsPlus/Page29/E1/2.jpg" style='margin-left:100px;height:22cm'/>
          
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P29-E2",
    exerciseKey: "img/FriendsPlus/Page29/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Find the nouns below in
        exercise 1. Which prepositions go before them?
        `,
        color: "black",
        left: -20,
        width: "100%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: ["age", "common", "course", "fact", "first", "purpose", "trouble"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `My best friend’s started hanging out with people who are two or three years older than we are. I didn’t mind at_first, but now he’s going to a concert with them after he’d said he’d go with me. He can’t have forgotten – in_fact, I’m sure he’s done it on_purpose. He hasn’t got anything in_common with them and he’s recently been going to places where he’s under_age. Of_course I don’t want him to get in_trouble, but I just don’t know what to do.`,
        ],
        answers: ["0-108", "0-42", "0-132", "0-134", "0-86", "0-98", "0-148"],
        initialValue: [],
      },
      Layout: `
      <hintbox id=0></hintbox>

      <div style='position:relative'>
          <div style='display:flex'>
                <img src="img/FriendsPlus/Page29/E2/1.jpg" style='margin-left:-50px;height:11cm'/>
                <img src="img/FriendsPlus/Page29/E1/1.jpg" style='height:20cm'/>
          </div>
          <div style='line-height:32.3px;width:141mm;font-size:22px;top:400px;left:681px; position:absolute'>
           <input id=0 type=Circle />
          </div>
      </div>
     
      `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P29-E3",
    exerciseKey: "img/FriendsPlus/Page29/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the phrases with the correct prepositions.`,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
       <div style='line-height:50px'>
            <b>1</b>&ensp;Your friend’s clearly # the wrong; I’d be very angry!<br>
            <b>2</b>&ensp;It sounds like he’s # a lot of pressure and needs help.<br>
            <b>3</b>&ensp;# the one hand, he’s behaved badly, but # the other hand, he’s still your best friend.<br>
            <b>4</b>&ensp;If I were you, I wouldn’t contact him # the time being.<br>
            <b>5</b>&ensp;I’m sure he upset you # accident, so you should forgive him.<br>
       </div>
          
        `,
        // InputRong: true,
        answer: ["in", "under", "On", "on", "for", "by"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P29-E4",
    exerciseKey: "img/FriendsPlus/Page29/Key/E4answerKey.png",
    textareaStyle: { width: 800, paddingTop: 5 },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the responses to Marco’s problem with nouns
        from exercise 2 and the <span style='color:#91268e'>Learn this!</span> box.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      fontWeight: "bold",
      borderBottom: "none",
      background: "none",
      width: 91,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page29/E2/1.jpg" style='height:11cm'/>
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page29/E4/1.jpg" style='height:6.5cm'/>
          <div style='position:absolute;top:22px;left:363px'>#</div>
          <div style='position:absolute;top:56px;left:272px'>#</div>
          <div style='position:absolute;top:138px;left:592px'>#</div>
          <div style='position:absolute;top:172px;left:433px'>#</div>
        </div>

        `,
        answer: ["fault", "once", "stake", "good"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P29-E5",
    exerciseKey: "img/FriendsPlus/Page29/Key/E5answerKey.png",
    recorder: true,
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Which comments and advice
        from exercises 3 and 4 do you agree with most? Why?
        What other advice would you give Marco?`,
        color: "black",
        left: 50,
        width: "25cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 404, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Idioms to describe feelings</b>"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        `,
        // InputRong: true,
        answer: [
          "miserable",
          "furious",
          "anxious / stressed|anxious|stressed",
          "irritated",
          "envious",
          "humiliated",
          "anxious / exasperated / frustrated|anxious|exasperated|frustrated",
          "indecisive",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P29-E6",
    exerciseKey: "img/FriendsPlus/Page29/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Read the dictionary entries. Then
        choose the correct words to complete the phrases with
        end and time.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `by_the / in`,
          `At_the / In`,
          `on / by_the`,
          `in / at`,
          `In / By`,
          `on / in`,
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `

      <img src="img/FriendsPlus/Page29/E6/1.jpg" style='margin-left:100px;height:17cm'/>
      <div style='margin-left:100px;width:19cm'>
        Last night, I waited for Matt, but he was late – again! So
        <br><sup>1</sup><input id=0 type='Circle' /> time we got to the concert, it had already
        started. <sup>2</sup><input id=1 type='Circle' /> time, I was so angry – I don’t know
        why he can’t be <sup>3</sup><input id=2 type='Circle' /> time for once! I tried
        discussing it with him, but <sup>4</sup><input id=3 type='Circle' /> the end I gave up. <sup>5</sup><input id=4 type='Circle' /> the end of the evening, we weren’t speaking.
        I don’t know how things will turn out, but I hope that
        <sup>6</sup><input id=5 type='Circle' /> time we’ll be friends again.
      </div>
     
      `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P29-E7",
    exerciseKey: "img/FriendsPlus/Page29/Key/E7answerKey.png",
    recorder: true,
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss the questions. Give
        reasons for your answers.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px;width:80%'>
            <b>1</b>&ensp;Do you think magazines and websites offer good advice to people who write in with their problems?<br>
            <b>2</b>&ensp;If you had a problem, would you seek advice on a forum?<br>
           
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P29-E8",
    exerciseKey: "img/FriendsPlus/Page29/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page29/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
