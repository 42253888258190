import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P38-E1",
    exerciseKey: "img/FriendsPlus/Page38/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:#0f9b52' >SPEAKING</span> Work in pairs. What cultural problems do
        people face when they travel abroad?`,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        `,
        answer: ["confused", "happy", "anxious", "annoyed", "surprised"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P38-E2",
    exerciseKey: "img/FriendsPlus/Page38/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. Which is the best piece of advice in
        your opinion? Why?
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { width: 800, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Gestures and expressions</b>",
          "bite your fingernails",
          "bow your head",
          "fidget",
          "fold your arms",
          "frown",
          "give a thumbs up",
          "grimace",
          "grin",
          "nod your head",
          "point",
          "pout",
          "purse your lips",
          "raise your eyebrows",
          "shake your head",
          "shrug your shoulders",
          "wink",
          "yawn",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page38/E2/1.jpg" style='height:18cm'/>
        
        `,
        // InputRong: true,
        answer: [
          "The woman is shrugging her shoulders and raising her eyebrows.",
          "The man is giving a thumbs up.",
          "The woman is frowning and biting her fingernails.",
          "The man is frowning.",
          "The woman is raising her eyebrows.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P38-E3",
    exerciseKey: "img/FriendsPlus/Page38/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box with the verbs below. Then
        find examples of the rules in the text in exercise 2. `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 500, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "be supposed to",
          "don’t have to, don’t need to and needn’t must and have to",
          "mustn’t",
          "should and ought to",
        ],
        styleElement: { color: "#f88b1f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;font-size:30px'><b style='color:rgb(248,139,31)'>LEARN THIS!</b> <b>Advice, obligation and necessity</b></div>
        <b>1</b>&ensp;We use # to give advice.<br> 
        <b>2</b>&ensp;We use # for obligation, strong advice or possibility.<br> 
        <b>3</b>&ensp;We use # to say that something isn’t allowed or for strong negative advice.<br> 
        <b>4</b>&ensp;We use # to express lack of obligation or necessity.<br> 
        <b>5</b>&ensp;We use # to talk about rules and also about what people believe or expect.<br> 
        <div>
          <b>find examples of the rules in the text</b><br>
          <b>1</b>&ensp;<input id=5 width=1000px /><br>
          <b>2</b>&ensp;<input id=6 width=1000px /><br>
          <b>3</b>&ensp;<input id=7 width=1000px /><br>
          <b>4</b>&ensp;<input id=8 width=1000px /><br>
          <b>5</b>&ensp;<input id=9 width=1000px />
        </div>
        <img src="img/FriendsPlus/Page38/E2/1.jpg" style='height:18cm'/>
        `,
        // InputRong: true,
        answer: [
          "should and ought to",
          "must and have to",
          "mustn't",
          "don't have to, don't need to and needn't",
          "be supposed to",
          "You ought to plan to keep in touch with friends and family",
          "you must try to learn some key phrases",
          "You mustn't disregard local laws and regulations",
          "You don't have to be fluent",
          "so you understand what you're supposed to do (and what you're not supposed to do)",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P38-E4",
    exerciseKey: "img/FriendsPlus/Page38/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Choose the correct verbs to complete the sentences.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -20,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Gestures and expressions</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `mustn’t / needn’t`,
          `must / are_supposed_to`,
          `don’t_have_to / shouldn’t`,
          `You’re_supposed_to / You_must`,
          `ought_to / don’t_need_to`,
        ],
        answers: ["1-4", "0-0", "2-0", "3-0", "4-0"],
        initialValue: [],
      },
      Layout: `

      <div style='line-height:50px'>
          <b>1</b>&ensp;You <input id=0 type=Circle /> eat noisily; it’s rude.<br>
          <b>2</b>&ensp;We <input id=1 type=Circle /> arrive by 10 o’clock, but we can be a bit late.<br>
          <b>3</b>&ensp;Guests <input id=2 type=Circle /> check out till midday.<br>
          <b>4</b>&ensp;<input id=3 type=Circle /> take off your shoes, but it doesn’t matter if you forget.<br>
          <b>5</b>&ensp;You <input id=4 type=Circle /> try some of the local dishes.<br>
      </div>

      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P38-E5",
    exerciseKey: "img/FriendsPlus/Page38/Key/E5answerKey.png",

    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find examples of the
        rules in the text in exercise 2.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 900,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    component: T6,
    character: "/",
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page38/E5/1.jpg" style='height:10cm'/>
        <div>
          <b>1</b>#<br>
          <b>2</b>#<br>
          <b>3</b>#<br>
          <b>4</b>#<br>
          <b>5</b>#<br>
        </div>
        `,
        answer: [
          "you may find it helps to read about the history and culture, you might love it",
          "you should fit right in",
          "there must be one in the language you need",
          "you'll be able to find a wide variety of online language courses",
          "Travelling can be lonely / You can't learn everything about where you're visiting",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P38-E6",
    exerciseKey: "img/FriendsPlus/Page38/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 300, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with verbs from the <span style='color:#f88b1f'>Learn this!</span> box.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page38/E5/1.jpg" style='height:10cm'/>
        <div style='width:20cm'>
          I’m really looking forward to my trip to Da Nang in July.
          The ‘sightseeing’ section of the guidebook is really long, so there
          <br><sup>1</sup># be a lot to see! The problem is, we <br><sup>2</sup>#
          possibly visit all the sights in three days. I’m not sure yet, but we
          <sup>3</sup># have another holiday in the autumn, so we might <br><sup>4</sup># go back. Apparently, Da Nang <br><sup>5</sup># get
          very hot and crowded in the summer, but I think it <sup>6</sup>#
          be cooler and less busy later in the year – so maybe that’s a good
          time to return. 
        </div>
        `,
        answer: [
          "must",
          "can't",
          "may / might / could",
          "be able to",
          "can",
          "may / might / could",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P38-E7",
    exerciseKey: "img/FriendsPlus/Page38/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Rewrite the sentences using the words
        in brackets.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["You should buy a phrase book."],
        styleElement: { marginLeft: 20, color: "#08728a", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    stylesTextInput: { width: 760 },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <b>1</b>&ensp;I’d recommend buying a phrase book. (should) <hintbox id=0></hintbox>
        <b>2</b>&ensp;Should we leave a tip? (supposed)<br>#<br>
        <b>3</b>&ensp;They won’t let you enter the temple in shorts. (mustn’t)<br>#<br>
        <b>4</b>&ensp;It doesn’t matter if you don’t speak Japanese. (need)<br>#<br>
        <b>5</b>&ensp;He’ll have no problem making friends, in my opinion. (shouldn’t)<br>#<br>
        <b>6</b>&ensp;There’s a chance it will rain while we’re away. (could)<br>#<br>
        <b>7</b>&ensp;Look at the map; this is definitely the right road! (must) <br>#<br>
        `,
        answer: [
          "Are we supposed to leave a tip?",
          "You mustn't enter the temple in shorts.|You must not enter the temple in shorts.",
          "You don't need to speak Spanish.|You do not need to speak Spanish.",
          "He shouldn't have any problem making friends (in my opinion).|He should not have any problem making friends (in my opinion).",
          "It could rain while we're away.",
          "Look at the map; this must be the right road!",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P38-E8",
    exerciseKey: "img/FriendsPlus/Page38/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:#0f9b52' >SPEAKING</span> Work in pairs. Use modal verbs and agree
        on how a visitor to your country should behave in these
        situations.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Going for dinner in a restaurant<br>
        <b>2</b>&ensp;Being invited to a friend’s parents’ house<br>
        <b>3</b>&ensp;Visiting a religious building<br>
        <b>4</b>&ensp;Going to the beach<br>
        <img src="img/FriendsPlus/Page38/E8/1.jpg" style='height:3cm'/>


        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
