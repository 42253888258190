import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  3: {
    audio: "Audios/1.04.mp3",
    unit: "Unit 1",
    id: "SB12-2024-U1-P13-E3",
    exerciseKey: "img/FriendsPlus/Page13/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: ["about", "at", "by", "of", "to", "with"],
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='1.04' colorimg=#d6b5da  color='white'></headphone> Listen to a teenager, Katie, talking about
          her relationships. Correct the mistakes in this ‘social
          circle’ diagram.`,
        color: "black",
        left: 100,
        width: "90%",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page13/E3/1.jpg" style='height:12cm'/>
          <div>
            <b>Answer:</b><br>#<br>#
          </div>
        </div>
        
        `,
        answer: [
          "Dad should be in the same ring as Mum.|Laura and Tom should be in the same ring as Anna.",
          "Dad should be in the same ring as Mum.|Laura and Tom should be in the same ring as Anna.",
        ],
      },
    ],
  },

  4: {
    audio: "Audios/1.05.mp3",
    unit: "Unit 1",
    id: "SB12-2024-U1-P13-E4",
    exerciseKey: "img/FriendsPlus/Page13/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.05' colorimg=#d6b5da  color='white'></headphone> Now listen again to Katie and three more
        teenagers. Choose the correct answers.`,
        color: "black",
        width: "28cm",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "has_a_lot_in_common_with / doesn’t_see_eye_to_eye_with",
          "respects / has_a_lot_in_common_with",
          "admires / trusts",
          "looks_down_on / is_a_bit_wary_of",
          "feels_sorry_for / looks_up_to",
          "see_eye_to_eye_with / respect",
          "has_nothing_in_common_with / is_on_the_same_wavelength_as",
          "is_wary_of / looks_up_to",
        ],
        answers: ["0-0", "1-0", "2-4", "3-4", "4-4", "5-0", "6-4", "7-4"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px'>
        <b>1</b>&ensp;Katie <input id=0 type='Circle'/> her cousin Tom.<br>
        <b>2</b>&ensp;Katie <input id=1 type='Circle'/> Mrs Saunders.<br>
        <b>3</b>&ensp;Ben <input id=2 type='Circle'/> his sister, Emma.<br>
        <b>4</b>&ensp;Ben <input id=3 type='Circle'/> his football coach.<br>
        <b>5</b>&ensp;Mark <input id=4 type='Circle'/> his aunt Julia.<br>
        <b>6</b>&ensp;Mark doesn’t always <input id=5 type='Circle'/> his brother.<br>
        <b>7</b>&ensp;Amy <input id=6 type='Circle'/> her grandmother.<br>
        <b>8</b>&ensp;Amy <input id=7 type='Circle'/> Mrs White.<br>
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P13-E5",
    exerciseKey: "img/FriendsPlus/Page13/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `<span style='color:#91268e'>VOCABULARY</span> Work in pairs. Check the meaning of the
        verbs below.`,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "0px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Verbal interaction</b>",
          "compliment (sb on sth)",
          "flatter (sb)",
          "insult (sb)",
          "lecture  (sb about sth)",
          "nag (sb about sth)",
          "offend (sb)",
          "praise (sb for sth)",
          "tease (sb about sth)",
          "tell (sb) off (for sth)",
          "warn (sb about sth)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },

    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page13/E5/1.jpg" style='height:12cm'/>
        <div>
        
        </div>
      
        
        `,
        answer: [
          "born",
          "fall",
          "get",
          "have",
          "learn",
          "leave",
          "pass",
          "settle",
          "start",
          "become",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P13-E6",
    exerciseKey: "img/FriendsPlus/Page13/Key/E6answerKey.png",
    audio: "Audios/1.06.mp3",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      margihLeft: 20,
      width: 700,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.06' colorimg=#d6b5da  color='white'></headphone> Listen to five dialogues. Choose a verb from
        exercise 5 and write down what is happening in each
        dialogue. Use the passive.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex'>
          In dialogue 1:
          <textarea id=0 rows=2></textarea>
        </div>
        <div style='display:flex'>
          In dialogue 2:
          <textarea id=1 rows=2></textarea>
        </div>
        <div style='display:flex'>
          In dialogue 3:
          <textarea id=2 rows=2></textarea>
        </div>
        <div style='display:flex'>
          In dialogue 4:
          <textarea id=3 rows=2></textarea>
        </div>
        <div style='display:flex'>
          In dialogue 5:
          <textarea id=4 rows=2></textarea>
        </div>
        
        `,
        answer: [
          "a man is being teased (about his haircut).",
          "a student is being praised (for his good work).",
          "a girl is being flattered (about her personality).",
          "a teenager is being told off (for going out at the weekend when they should have been revising).",
          "a driver is being warned (that he will be arrested unless he shows his licence).",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P13-E7",
    exerciseKey: "img/FriendsPlus/Page13/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Use verbs from exercise 5 to ask
        and answer questions about things that have happened
        to you. `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    recorder: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page13/E7/1.jpg" style='margin-left:50px;height:7cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P13-E8",
    exerciseKey: "img/FriendsPlus/Page13/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page13/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
