import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P32-E1",
    exerciseKey: "img/FriendsPlus/Page32/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Read the task. Are you good at
        sharing a living space with other people? Why? / Why
        not? What problems can there be?
        `,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page32/E1/1.jpg" style='margin-left:-50px;height:6.5cm'/>
        
        
          
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P32-E2",
    exerciseKey: "img/FriendsPlus/Page32/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:rgb(8,144,73)' >Speaking Strategy</span> above. Add the question
        tags. Then read the <span style='color:rgb(8,144,73)' >Pronunciation</span> box and practise
        saying the tags.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: ["age", "common", "course", "fact", "first", "purpose", "trouble"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page32/E2/1.jpg" style='margin-left:-50px;height:8cm'/>
          <img src="img/FriendsPlus/Page32/E2/2.jpg" style='margin-left:10px;height:10cm'/>
        </div>
       <div style='line-height:50px'>
          <b>1</b>&ensp;The flat’s great,#?<br>
          <b>2</b>&ensp;You don’t really like cleaning,#?<br>
          <b>3</b>&ensp;You leave a lot of clothes on the floor,#?<br>
          <b>4</b>&ensp;The cooker can get quite dirty,#?<br>
          <b>5</b>&ensp;I’m probably more organised than you,#?<br>
       </div>
          
        `,
        // InputRong: true,
        answer: ["isn't it", "do you", "don't you", "can't it", "aren't I"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P32-E3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E3answerKey.png",
    audio: "Audios/1.20.mp3",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='1.20' colorimg=#b4d3b6  color='white'></headphone> Listen to a student doing the task in exercise 1.
        Answer the questions.`,
        color: "black",
        left: 0,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 850, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page32/E1/1.jpg" style='margin-left:-50px;height:6.5cm'/>
       
       <div style='line-height:50px'>
          <b>1</b>&ensp;Which specific problems are mentioned?<br>#<br>
          <b>2</b>&ensp;Do the speakers react appropriately to each other?<br>#<br>
          <b>3</b>&ensp;What is the outcome of the conversation?<br>#<br>
       </div>
          
        `,
        // InputRong: true,
        answer: [
          "The kitchen was dirty last Friday and there were dirty plates in the sink.",
          "Yes.",
          "They agree to clean the flat together every Tuesday evening.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P32-E4",
    exerciseKey: "img/FriendsPlus/Page32/Key/E4answerKey.png",
    audio: "Audios/1.20.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.20' colorimg=#b4d3b6  color='white'></headphone> <span style='color:#91268e' >KEY PHRASES</span> Work in pairs. Complete the
        phrases from the dialogue with the words below. Listen
        again and check.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 110,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='border-left:5px solid rgb(0,147,69);padding-left:15px; width:90%'>
            <hintbox id=1></hintbox>
            <b>1</b>&ensp;Can I have a # with you about … ?<br>
            <b>2</b>&ensp;Well, it’s # that …<br>
            <b>3</b>&ensp;What did you have in #  … ?<br>
            <b>4</b>&ensp;I thought # we could …<br>
            <b>5</b>&ensp;You must # that …<br>
            <b>6</b>&ensp;I didn’t want to # it up, but …<br>
            <b>7</b>&ensp;You # have said something earlier.<br>
            <b>8</b>&ensp;Could we possibly agree #  … ?<br>
        </div>

        `,
        answer: [
          "word",
          "just",
          "mind",
          "perhaps",
          "feel",
          "bring",
          "should",
          "on",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P32-E5",
    exerciseKey: "img/FriendsPlus/Page32/Key/E5answerKey.png",
    recorder: true,
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Introduce each topic using the
        phrases from exercise 4 and discuss diplomatically.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      fontSize: 20,
      width: 182,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: ["Crime collocations"],
        styleElement: { color: "#42429c", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page32/E5/1.jpg" style='margin-left:100px;height:4cm'/>
        <div style='position:relative'>
          <b>1</b>&ensp;Cleaning the bath and toilet<br>
          <b>2</b>&ensp;Taking out the rubbish<br>
          <b>3</b>&ensp;Borrowing things without asking<br>
          <b>4</b>&ensp;Playing music<br>
       </div>

        `,
        // InputRong: true,
        answer: [
          "combat crime|cut crime|commit crime|deter crime",
          "combat crime|cut crime|commit crime|deter crime",
          "combat crime|cut crime|commit crime|deter crime",
          "combat crime|cut crime|commit crime|deter crime",
          "crime prevention|crime wave",
          "crime prevention|crime wave",
          "violent crime|recorded crime|petty crime",
          "violent crime|recorded crime|petty crime",
          "violent crime|recorded crime|petty crime",
          "Adjectives and verbs come before the noun, nouns come after the noun.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P32-E6",
    exerciseKey: "img/FriendsPlus/Page32/Key/E6answerKey.png",
    audio: "Audios/1.21.mp3",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.21' colorimg=#b4d3b6  color='white'></headphone> Listen to another student doing the task in
        exercise 1 and answer the questions in exercise 3. In which
        dialogue is there less agreement, the first or the second?`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,

    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page32/E1/1.jpg" style='margin-left:-50px;height:6.5cm'/><br>
        <div style='line-height:50px'>
          <b>1</b>&ensp;Which specific problems are mentioned?<br>
          <b>2</b>&ensp;Do the speakers react appropriately to each other?<br>
          <b>3</b>&ensp;What is the outcome of the conversation?<br>
       </div>
        <b>Answer:</b>#

        `,
        answer: ["There is less agreement in the second conversation."],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P32-E7",
    exerciseKey: "img/FriendsPlus/Page32/Key/E7answerKey.png",
    recorder: true,
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Taking it in turns to be the
        difficult flatmate. Follow the advice in the <span style='color:rgb(8,144,73)' >Speaking Strategy</span>. Try to be diplomatic and resolve the issues.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page32/E2/2.jpg" style='height:11cm'/><br>
        <img src="img/FriendsPlus/Page32/E7/1.jpg" style='height:6cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P32-E8",
    exerciseKey: "img/FriendsPlus/Page32/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page32/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
