import React from "react";
import { Drawer } from "antd";

const ExerciseKey = ({ widthModalKey, exerciseKey, visible, onClose }) => {
  return (
    <div id="drawer-container">
      <Drawer
        // title={<img src="/assets/key-header.png" alt="" />}
        width={widthModalKey ?? 1100}
        placement="right"
        closable
        onClose={onClose}
        visible={visible}
        zIndex={1050}

        // getContainer={false}
      >
        {exerciseKey && (
          <img
            src={exerciseKey}
            alt="..."
            style={{ paddingTop: 25, paddingRight: 10 }}
          />
        )}
        {!exerciseKey && (
          <div className="mt-5 d-flex justify-content-center text-info display-3">
            Students’ own answers
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default ExerciseKey;
