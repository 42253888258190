import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P88-E1",
    exerciseKey: "img/FriendsPlus/Page88/Key/E1answerKey.png",
    component: T6,
    // maxLength:1,
    stylesTextInput: { width: 840, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "although",
          "became",
          "'ve become",
          "despite",
          "in spite",
          "started",
          "'ve started",
          "never",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the news report and answer the questions.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page88/E1/1.jpg" style='height:13cm;'/>
        <div></div>
        <b>1</b>&ensp; What has the man been accused of doing?<br>#<br>
        
        <b>2</b>&ensp; Can you give examples of similar telephone or internet scams?<br># 
        `,
        answer: [
          "He has been accused of using social media to steal money from people.",
          "identity theft, selling goods online that don't exist",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P88-E2",
    audio: "Audios/3.08.mp3",
    exerciseKey: "img/FriendsPlus/Page88/Key/E2answerKey.png",
    component: T6,
    checkDuplicated: true,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone colorimg='#e9b6b3' name='3.08' color='white' marginLeft='-50px'  ></headphone> Read and listen to the texts. Answer the 
          questions.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style="width:22cm">
            <b>1</b>&ensp;the part of the report where the direct speech is reported. <br>
            <b>2</b>&ensp;the reporting verb that is used (e.g. warn, admit, etc.).<br>
          </div> 
          <b style='font-style:italic'>Answer:</b><br>
           <b>1</b>&ensp;#<br>
           <b>2</b>&ensp;#<br>
           <b>3</b>&ensp;#<br>
           <b>4</b>&ensp;#<br>
           <b>5</b>&ensp;#<br>
          `,
        answer: [
          "convinced|admitted|denied|claimed|warned",
          "convinced|admitted|denied|claimed|warned",
          "convinced|admitted|denied|claimed|warned",
          "convinced|admitted|denied|claimed|warned",
          "convinced|admitted|denied|claimed|warned",
        ],
        // InputRong:true
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P88-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page88/Key/E3answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 950, paddingTop: 10 },
    hintBox: [
      {
        src: ["admit", "announce", "deny", "explain", "insist", "promise"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <prefix text='Learn this!' icon='' color='#f79126'></prefix> box. Then report the sentences with 
          the reporting verbs below and that. `,
        color: "black",
        left: 50,
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <img src="img/FriendsPlus/Page88/E3/1.jpg" style='height:10cm;padding-bottom:20px '/>
        <div>
          <b>1</b>&ensp;Luke said, ‘Actually, I did eat the last chocolate in the box.’<br>
           <i style='color: rgb(17,205,239)'>Luke admitted that he had eaten the last chocolate in the box.</i><br>
          <b>2</b>&ensp;Dan said to Mia, ‘I’m late because I missed the bus.’<br><textarea id='0' rows=2></textarea><br>
          <div style='margin-top:-55px'><b>3</b>&ensp;Pete said to me, ‘Seriously, you are definitely wrong.’<br><textarea id='1' rows=2></textarea><br></div>
          <div style='margin-top:-55px'><b>4</b>&ensp;Jen said, ‘Guess what! I’ve passed all my exams!’<br><textarea id='2' rows=2></textarea><br></div>
          <div style='margin-top:-55px'><b>5</b>&ensp;Pablo said, ‘Of course I’ll be there on time.’<br><textarea id='3' rows=2></textarea><br></div>
          <div style='margin-top:-55px'><b>6</b>&ensp;Jack said, ‘I haven’t taken your keys, honestly.’<br><textarea id='4' rows=2></textarea><br></div>
        </div>
      
        
        `,
        answer: [
          "Dan explained to Mia that he was late because he had missed the bus.",
          "Pete insisted that I was wrong.",
          "Jen announced that she had passed all her exams.",
          "Pablo promised that he would be there on time.",
          "Jack denied that he had taken my keys. / Jack denied having taken / taking my keys. / Jack denied having taken my keys. / Jack denied taking my keys.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P88-E4",
    audio: "Audios/3.09.mp3",
    exerciseKey: "img/FriendsPlus/Page88/Key/E4answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: ["admit", "announce", "deny", "explain", "insist", "promise"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone colorimg='#e9b6b3' name='3.09' color='white' marginLeft='-50px'  ></headphone> Read the <prefix text='Learn this!' icon='' color='#f79126'></prefix> box. Then listen and 
          complete the sentences, using one of the verbs from 
          rules (1–6) in the past simple.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <img src="img/FriendsPlus/Page88/E4/1.jpg" style='height:17cm;'/>
        <div style='line-height:50px'>
          <b>1</b>&ensp; She#to make a decision.<br>
          <b>2</b>&ensp; He#her not to see the film.<br>
          <b>3</b>&ensp; She#ordering some more food.<br>
          <b>4</b>&ensp; He#about having lots of friends.<br>
          <b>5</b>&ensp; She#him for missing the bus.<br>
          <b>6</b>&ensp; He#that they make less noise.
        </div>
      
        
        `,
        answer: [
          "refused",
          "advised",
          "suggested",
          "boasted",
          "blamed",
          "requested",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P88-E5",
    audio: "Audios/3.10.mp3",
    exerciseKey: "img/FriendsPlus/Page88/Key/E5answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",,
    textareaStyle: {
      resize: "none",
      width: 600,
      paddingTop: 2,
      marginTop: -20,
      marginBottom: -70,
    },
    hintBox: [
      {
        src: ["admit", "announce", "deny", "explain", "insist", "promise"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone colorimg='#e9b6b3' name='3.10' color='white' marginLeft='-50px'  ></headphone> Listen and report the direct speech. Use the 
          verbs given, followed by the appropriate structure.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='margin-left:100px'>
          <b>1</b>&ensp;invite<br>
           <i style='color: rgb(17,205,239)'><b>1</b>&ensp;She invited him to go to the park.</i><br>
          <b>2</b>&ensp;congratulate<br><textarea id='0' rows=2></textarea><br>
          <b>3</b>&ensp;remind<br><textarea id='1' rows=2></textarea><br>
          <b>4</b>&ensp;deny<br><textarea id='2' rows=2></textarea><br>
          <b>5</b>&ensp;propose<br><textarea id='3' rows=2></textarea><br>
          <b>6</b>&ensp;apologise<br><textarea id='4' rows=2></textarea><br>
          <b>7</b>&ensp;agree<br><textarea id='5' rows=2></textarea><br>
          <b>8</b>&ensp;threaten<br><textarea id='6' rows=2></textarea><br>
          <b>9</b>&ensp;encourage<br><textarea id='7' rows=2></textarea><br>
          <b>10</b>&ensp;confess<br><textarea id='8' rows=2></textarea><br>
          <b>11</b>&ensp;accuse<br><textarea id='9' rows=2></textarea><br>
          <b>12</b>&ensp;recommend<br><textarea id='10' rows=2></textarea><br>
        </div>
        `,
        answer: [
          "She congratulated him on passing his driving test.",
          "He reminded her to take the book back to the library.",
          "He denied taking his mobile phone.",
          "He proposed that they end the meeting early so that everyone could get home.|He proposed that they should end the meeting early so that everyone could get home.|He proposed that they should end the meeting early.|He proposed that they end the meeting early.",
          "She apologised for not doing the homework.",
          "He agreed to help her with her bags.",
          "She threatened to tell the teacher.",
          "He encouraged her to apply for the summer job.|He encouraged her to apply for the job.",
          "She confessed to throwing away her magazines.|She confessed to throwing away the magazines.",
          "He accused her of breaking the chair.",
          "He recommended that she should buy the new video game.|He recommended that she buy the new video game.|He recommended that she should buy the video game.|He recommended that she buy the video game.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P88-E6",
    exerciseKey: "img/FriendsPlus/Page88/Key/E6answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Using reporting verbs, tell each 
          other about:`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    recorder: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>1</b>&ensp;a useful thing that someone recommend that you do.<br>
        <b>2</b>&ensp;something your parents warned you not to do as a child.<br>
        <b>3</b>&ensp;a time when a friend refused to do something.<br>
        <b>4</b>&ensp;something that you had to apologise for doing.<br>
        <b>5</b>&ensp;a time when someone accused you of something you didn’t do.<br>
        <b>6</b>&ensp;something you did that you later denied doing.<br>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
