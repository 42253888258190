import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P34-E1",
    exerciseKey: "img/FriendsPlus/Page34/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Think of three
        important problems that the world faces today.
        How do you think we can solve them? 
        
         `,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
          
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P34-E2",
    exerciseKey: "img/FriendsPlus/Page34/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. What solution did the British government
        suggest for a problem in the 18th century? Was it successful?
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 650, paddingTop: 10 },
    hintBox: [
      {
        src: ["age", "common", "course", "fact", "first", "purpose", "trouble"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='margin-bottom:10px;background:rgb(243,240,221);padding:10px 20px; border-radius:5px; box-shadow:2px 3px 5px 5px gray; width:25cm'>
        On a stormy night in 1707, four ships struck rocks off
        the south coast of England and sank. One thousand, four
        hundred sailors were drowned. The ships had crashed because
        they had no way of knowing how far they had travelled in a
        particular direction; they could not calculate their longitude,
        which required accurate time measurement. It was the most
        serious in a series of accidents at sea, and a stunned British
        government decided to act. In such difficult circumstances, they
        believed that the best response to the disaster was a competition:
        the Longitude Prize
          <div style='margin-top:15px'>The Longitude Prize was no ordinary competition. To win it,
          someone had to find a way of calculating how far a ship had
          travelled east or west from its point of departure. Geniuses such
          as Sir Isaac Newton had failed to find a solution, so to ensure
          the interest of Britain’s greatest scientific minds, the government
          offered a prize of £20,000 – the equivalent of £2.6 million in
          today’s money. But to everyone’s surprise, it wasn’t a famous
          academic who solved the problem, but an unknown carpenter</div>
          <div style='margin-top:15px'>When John Harrison wasn’t working with wood, he was making
          clocks. An accurate clock would allow sailors to calculate their
          position, but at the time it was thought impossible to create a
          mechanical clock that could work on a ship. The movement of
          the sea and the changes in temperature destroyed the delicate
          parts. However, after three frustrated attempts, Harrison’s fourth
          sea clock, H4, finally triumphed. Its mechanics were so good that
          the H4 worked better than most clocks on land.</div>
          <div style='margin-top:15px'>The Longitude Prize and Harrison’s success generated a lot of
          interest in the 18th century, but it was soon forgotten. However,
          in 2013, the British government created a new Longitude Prize,
          offering £10 million to the person who could solve a great
          challenge to humanity. An enthralled public then took part in a
          TV programme where viewers chose one challenge from a list of
          six for scientists to focus on. The question now is, will someone
          be able to solve it just as well as Harrison solved the challenge
          presented to him?</div>
        </div> 
        <b>What solution did the British government suggest for a problem in the 18th century?</b><br>#<br>
        <b>Was it successful?</b><br>#<br>
        
          
        `,
        // InputRong: true,
        answer: [
          "It suggested a competition called The Longitude Prize.",
          "Yes, it was successful.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P34-E3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again. Answer the questions.`,
        color: "black",
        left: 0,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 850, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='background:rgb(243,240,221);padding:10px 20px; border-radius:5px; box-shadow:2px 3px 5px 5px gray; width:25cm'>
        On a stormy night in 1707, four ships struck rocks off
        the south coast of England and sank. One thousand, four
        hundred sailors were drowned. The ships had crashed because
        they had no way of knowing how far they had travelled in a
        particular direction; they could not calculate their longitude,
        which required accurate time measurement. It was the most
        serious in a series of accidents at sea, and a stunned British
        government decided to act. In such difficult circumstances, they
        believed that the best response to the disaster was a competition:
        the Longitude Prize
          <div style='margin-top:15px'>The Longitude Prize was no ordinary competition. To win it,
          someone had to find a way of calculating how far a ship had
          travelled east or west from its point of departure. Geniuses such
          as Sir Isaac Newton had failed to find a solution, so to ensure
          the interest of Britain’s greatest scientific minds, the government
          offered a prize of £20,000 – the equivalent of £2.6 million in
          today’s money. But to everyone’s surprise, it wasn’t a famous
          academic who solved the problem, but an unknown carpenter</div>
          <div style='margin-top:15px'>When John Harrison wasn’t working with wood, he was making
          clocks. An accurate clock would allow sailors to calculate their
          position, but at the time it was thought impossible to create a
          mechanical clock that could work on a ship. The movement of
          the sea and the changes in temperature destroyed the delicate
          parts. However, after three frustrated attempts, Harrison’s fourth
          sea clock, H4, finally triumphed. Its mechanics were so good that
          the H4 worked better than most clocks on land.</div>
          <div style='margin-top:15px'>The Longitude Prize and Harrison’s success generated a lot of
          interest in the 18th century, but it was soon forgotten. However,
          in 2013, the British government created a new Longitude Prize,
          offering £10 million to the person who could solve a great
          challenge to humanity. An enthralled public then took part in a
          TV programme where viewers chose one challenge from a list of
          six for scientists to focus on. The question now is, will someone
          be able to solve it just as well as Harrison solved the challenge
          presented to him?</div>
        </div>
        <div style='margin-top:20px'>
            <b>1</b>&ensp;What was the situation that the British government decided to deal with?<br><textarea id=0 rows=2></textarea>
            <b>2</b>&ensp;What surprised people about the winner of the  Longitude Prize?<br><textarea id=1 rows=2></textarea>
            <b>3</b>&ensp;Why didn’t sailors use mechanical clocks to calculate their positions?<br><textarea id=2 rows=2></textarea>
            <b>4</b>&ensp;Why do you think the successful sea clock was called H4?<textarea id=3 rows=2></textarea>
            <b>5</b>&ensp;How was the challenge for the new Longitude Prize decided on? <br><textarea id=4 rows=2></textarea>
        
        </div>
        `,
        // InputRong: true,
        answer: [
          "The British government decided to try to prevent the kinds of accidents that had happened at sea in 1707.",
          "He was a carpenter rather than a well-known scientist.",
          "Because conditions on board damaged the clocks.",
          "The H probably refers to the first letter of the inventor's surname (Harrison), and the number 4 to the fact that it was the fourth sea clock that he had made.",
          "A TV audience was asked to choose one challenge from a list of six.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P34-E4",
    exerciseKey: "img/FriendsPlus/Page34/Key/E4answerKey.png",
    recorder: true,
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Work in pairs. The Longitude Prize is a prize
        for anyone that can solve one of the problems in the list.
        Which one would you choose to be the challenge for the
        new Longitude Prize? Why?`,
        color: "black",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 110,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page34/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "word",
          "just",
          "mind",
          "perhaps",
          "feel",
          "bring",
          "should",
          "on",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P34-E5",
    exerciseKey: "img/FriendsPlus/Page34/Key/E5answerKey.png",
    audio: "Audios/1.23.mp3",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='1.23' colorimg=#9ac7d4  color='white'></headphone> Listen to a podcast. Which problem from
        exercise 4 was chosen as the challenge for the
        Longitude Prize?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 700,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: ["<b>Introducing one side of the argument</b>"],
        styleElement: { color: "#2483c5", marginRight: "30px" },
      },
      {
        src: ["<b>Introducing the other side of the argument</b>"],
        styleElement: { color: "#2483c5", marginRight: "30px" },
      },
    ],

    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page34/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        <div>
          <b>Answer:</b>#
        </div>
        `,
        answer: ["How can we prevent the rise of resistance to antibiotics?"],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P34-E6",
    exerciseKey: "img/FriendsPlus/Page34/Key/E6answerKey.png",
    // checkDuplicated: true,
    audio: "Audios/1.23.mp3",
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.23' colorimg=#9ac7d4  color='white'></headphone> Listen again. Choose the correct answers (a–c).`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-0", "1-0", "2-8", "3-4", "4-0"],
        initialValue: [],
      },
      Layout: `

      
      <b>1</b>&ensp;On the podcast, they begin by talking about what happened
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=0 type=Circle /></div>
          <div>
                    on a TV programme. <br>
              on a podcast.<br>
              in a government debate.
          </div>
       </div>
      <b>2</b>&ensp;The prize is being offered to
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=1 type=Circle /></div>
          <div>
                    any scientist. <br>
              government scientists.<br>
              famous scientists.
          </div>
       </div>
      <b>3</b>&ensp;Dr Kenny uses the example of the warplane to demonstrate that the prize money
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=2 type=Circle /></div>
          <div>
                    is a lot. <br>
              is reasonable.<br>
              isn’t so much.
          </div>
       </div>
      <b>4</b>&ensp;World health organisations are worried that antibiotics
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=3 type=Circle /></div>
          <div>
                    endanger people’s lives. <br>
              are less successful.<br>
              have become unnecessary.
          </div>
       </div>
      <b>5</b>&ensp;If nobody wins the challenge, in the future, doctors may not be able to
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=4 type=Circle /></div>
          <div>
                    carry out some types of surgery.<br>
              treat patients for certain illnesses.<br>
              work in hospitals.       
          </div>
       </div>

      `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P34-E7",
    exerciseKey: "img/FriendsPlus/Page34/Key/E7answerKey.png",
    recorder: true,
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page34/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P34-E8",
    exerciseKey: "img/FriendsPlus/Page34/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page34/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
