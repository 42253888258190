import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Starter",
    id: "SB12-2024-Starter-P12-E1",
    exerciseKey: "img/FriendsPlus/Page12/Key/E1answerKey.png",
    audio: "Audios/2.05.mp3",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: `<headphone name='2.05' colorimg=#9bc5d6  color='white'></headphone>`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>A</b><br>
          At the age of seventeen, Alex Chacón
          <span style='background: rgb(252,193,91)'>set off</span> from his home in El Paso, Texas
          and went for a motorbike ride. Alex’s
          thirty-day tour of the USA <span style='background: rgb(252,193,91)'>took in</span>
          California, Washington and Florida and
          gave Alex an aim in life: to travel the
          world on his motorbike.<br>
          Although Alex usually travels alone, he
          has shared his experiences on his blog.
          While travelling, Alex videos himself
          doing all sorts of activities against
          breathtaking backgrounds of remote
          jungles, deserts and mountains. On
          one trip, he filmed himself by moving
          in a 360<sup>o</sup> circle so that all the famous
          landmarks would be included.<br>
          During Alex’s motorcycle expeditions,
          he regularly stops to take part in
          volunteering projects at orphanages
          that he <span style='background: rgb(252,193,91)'>comes across</span> on his route.
          Furthermore, he uses his blog to raise
          donations for orphans.<br>
          Alex is now planning to cross Africa,
          Europe and Asia. It seems that by the
          time he’s thirty, Alex will have ridden his
          bike across most of the planet!
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>B</b><br>
          Dutch actress Manon Ossevoort has dreamed of travelling from Europe across
          Africa to the South Pole for years, and she chose a slow form of transport: a tractor.<br>
          Manon only averaged 5 km/h as she drove, but that allowed her to get to know
          the hospitable people in villages where she <span style='background: rgb(252,193,91)'>stopped over</span> for the night. She would
          explain why she was going to the South Pole and ask people to follow her blog and
          write down their own dreams. She promised to build a snowman when she arrived
          at her destination and leave all their dreams inside it. The idea was popular and
          Manon received thousands of dreams on pieces of paper and in emails.<br>
          Finally, on 9 December 2014, Manon’s dream came true, and she <span style='background: rgb(252,193,91)'>pulled up</span>  in a
          big red tractor at the South Pole. She then built a snowman and left a time capsule
          inside it with all the dreams she had collected. Manon explained on her blog that
          when the time capsule is opened in eighty years’ time, future generations will be
          able to read something about our lives and our hopes for the future.
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>C</b><br>
          Have you ever travelled with no luggage? That was the question Rolf Potts aimed to
          answer on the ‘no-baggage challenge’. However, the task wasn’t simply a question of
          <span style='background: rgb(252,193,91)'>going off</span> for a week to soak up the sun on a beach. Rolf had to travel 50,000 km by
          plane and <span style='background: rgb(252,193,91)'>get around</span> eleven countries in 42 days with just the things he could carry
          in his pockets. Would he be able to do it?<br>
          Rolf was certainly well qualified enough to try. He was an experienced traveller who
          wrote a blog about budget travel. Now he planned to update it with regular reports
          about travelling extremely light.<br>
          His first ‘no-baggage challenge’ entry listed the items he took with him: a toothbrush
          and toothpaste, a small bottle of soap, a mobile phone and charger, deodorant,
          sunglasses, a passport, cash and a credit card. His second entry explained that he had
          got into the habit of washing his clothes before going to bed during his trips. By the
          third entry, he was really enjoying luggage-free travel.<br>
          Potts said that he would never pack any ‘just in case’ items again, because the
          imagined situations that they were packed for would never happen. <br>
        </div>
        
      
        
        `,
        answer: [
          "locate",
          "renovate",
          "excavate",
          "build",
          "unearth",
          "remove",
          "restore",
          "display",
          "bury",
          "destroy",
          "uncover",
          "examine",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB12-2024-Starter-P12-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page12/Key/E2answerKey.png",
    component: MatchDots,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the verbs (1–6) with the noun phrases (a–f). And 
      guess which job these activities are related to.`,
        color: "black",
        left: 0,
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: ["0-3", "11-2", "4-7", "1-6", "8-9", "10-5"],
        initialValue: [],
      },
      Layout: `
    <input id='0' type='boxMatch'/>
    <input id='1' type='boxMatch'/>
    <input id='2' type='boxMatch'/>
    <input id='3' type='boxMatch'/>
    <input id='4' type='boxMatch'/>
    <input id='5' type='boxMatch'/>
    <input id='6' type='boxMatch'/>
    <input id='7' type='boxMatch'/>
    <input id='8' type='boxMatch'/>
    <input id='9' type='boxMatch'/>
    <input id='10' type='boxMatch'/>
    <input id='11' type='boxMatch'/>
    <div style='display:flex;margin-left:50px'>
      <div style='margin-right:200px'>
        <b>1</b>&ensp;plan <br>
        <b>2</b>&ensp;get<br>
        <b>3</b>&ensp;make <br>
        <b>4</b>&ensp;use <br>
        <b>5</b>&ensp;tag<br>
        <b>6</b>&ensp;reach out for<br>
      </div>
      <div>
        <b>a</b>&ensp;software to edit videos <br>
        <b>b</b>&ensp;what to film <br>
        <b>c</b>&ensp;more followers <br>
        <b>d</b>&ensp;short films with a camera or phone <br>
        <b>e</b>&ensp;videos with web links for products <br>
        <b>f</b>&ensp;permission to film
      </div>
    </div>
    `,
    },
  },
  3: {
    unit: "Starter",
    id: "SB12-2024-Starter-P12-E3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E3answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    // hideBtnFooter:true,
    stylesTextInput: { width: 50, paddingTop: 10 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the text and answer the questions.`,
        color: "black",
        left: 100,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:50px'>In which paragraph (A–D) does the writer …</div>
          <img src="img/FriendsPlus/Page12/E3/1.jpg" style='height:21cm'/>
        <div style='margin-left:50px'>
          <b>1</b> &ensp;give reasons for the popularity of vlogs?#<br>
          <b>2</b> &ensp;provide an example that demonstrates how popular vloggers have become?#<br>
          <b>3</b>&ensp;give an example of someone being dishonest?#
        </div>
      
        
        `,
        answer: ["B", "A", "D"],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB12-2024-Starter-P12-E4",
    exerciseKey: "img/FriendsPlus/Page12/Key/E4answerKey.png",
    video: "",

    component: DesignUnderLine,
    // recorder: true,
    totalInput: 1,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#5f50a0'>Reading Strategy</span>. Then read the first question 
          in exercise 5 related to paragraph A. When you have 
          chosen the correct answer (a, b, c or d), read the 
          paragraph again and decide why the other options are 
          wrong.`,
        color: "black",
        left: 0,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page12/E4/a.jpg", width: "60%" }],
      [{ url: "img/FriendsPlus/Page12/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E4/2.jpg", input: 1 },
        { url: "img/FriendsPlus/Page12/E4/3.jpg" },
        { url: "img/FriendsPlus/Page12/E4/4.jpg", input: 2 },
        { url: "img/FriendsPlus/Page12/E4/5.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E4/6.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E4/7.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E4/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E4/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E4/10.jpg" },
        { url: "img/FriendsPlus/Page12/E4/11.jpg", input: 4 },
        { url: "img/FriendsPlus/Page12/E4/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E4/13.jpg" }],
    ],
  },
  5: {
    unit: "Starter",
    id: "SB12-2024-Starter-P12-E5",
    exerciseKey: "img/FriendsPlus/Page12/Key/E5answerKey.png",
    component: Circle_Write,
    // maxLength:4,
    textAlign: "center",
    stylesTextInput: { width: 230, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Read the text. For questions (2–4), circle the correct 
          options (a–d).`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // isHello:true,
    question: {
      leftContent: "100px",

      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: ["0-3", "11-2", "4-7", "1-6", "8-9", "10-5"],
        initialValue: [],
      },
      Write: {
        inputStyle: { paddingTop: 0, width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
        ],
        answers: ["0-12", "1-18", "2-6", "3-6"],
        initialValue: [],
      },
      Layout: `
      <input id='0' type='boxMatch'/>
      <input id='1' type='boxMatch'/>
      <input id='2' type='boxMatch'/>
      <input id='3' type='boxMatch'/>
      <input id='4' type='boxMatch'/>
      <input id='5' type='boxMatch'/>
      <input id='6' type='boxMatch'/>
      <input id='7' type='boxMatch'/>
      <input id='8' type='boxMatch'/>
      <input id='9' type='boxMatch'/>
      <input id='10' type='boxMatch'/>
      <input id='11' type='boxMatch'/>
    <div style='display:flex;margin-left:50px'>
      <div style='margin-right:200px'>
        <b>1</b>&ensp;plan <br>
        <b>2</b>&ensp;get<br>
        <b>3</b>&ensp;make <br>
        <b>4</b>&ensp;use <br>
        <b>5</b>&ensp;tag<br>
        <b>6</b>&ensp;reach out for<br>
      </div>
      <div>
        <b>a</b>&ensp;software to edit videos <br>
        <b>b</b>&ensp;what to film <br>
        <b>c</b>&ensp;more followers <br>
        <b>d</b>&ensp;short films with a camera or phone <br>
        <b>e</b>&ensp;videos with web links for products <br>
        <b>f</b>&ensp;permission to film
      </div>
    </div>


      <div>
            <b>1</b>&ensp;There was a large police presence at a bookstore because
            <div style='margin-left:20px;display:flex'>
                <div>
                  <input id='0' type='Circle'/>
                </div>
                <div>
                  fans of a famous writer were waiting to see him.<br>
                  someone very famous was causing excitement.<br>
                  the person who was coming was a pop sensation.<br>
                  thousands of people wanted to buy a book.
                </div>
            </div>
      </div>
      <div>
            <b>2</b>&ensp;One of the common characteristics of vlogs is that followers can
            <div style='margin-left:20px;display:flex'>
                <div>
                  <input id='1' type='Circle'/>
                </div>
                <div>
                  create relationships with other vlog followers.<br>
                  share their interest in vlogs created by media corporations.<br>
                  change the content of the vlogs they like.<br>
                  make their opinions on vloggers’ work known.
                </div>
            </div>
      </div>
      <div>
            <b>3</b>&ensp;Vloggers earn large sums of money by 
            <div style='margin-left:20px;display:flex'>
                <div>
                  <input id='2' type='Circle'/>
                </div>
                <div>
                  improving the quality of modern technology. <br>
                  talking about a product.<br>
                  influencing YouTube authorities.<br>
                  criticising products advertised by other vloggers. 
                </div>
            </div>
      </div>
      <div>
            <b>4</b>&ensp;The incident with Zoella’s book
            <div style='margin-left:20px;display:flex'>
                <div>
                  <input id='3' type='Circle'/>
                </div>
                <div>
                  lost her a lot of fans.<br>
                  suggested that the world of vlogging is changing.<br>
                  showed that she can write as well as a novelist.<br>
                  demonstrated that dreams can come true.
                </div>
            </div>
      </div>
      
      `,
    },
  },
};

export default json;
