import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E1",
    exerciseKey: "img/FriendsPlus/Page66/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Look at the following ideas. What problems
        are mentioned? Which is of the most concern among
        young adults?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <img src="img/FriendsPlus/Page66/E1/1.jpg" style='height:8cm'/><br>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E2",
    exerciseKey: "img/FriendsPlus/Page66/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text and answer the questions.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 50, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    // hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <div style='width:27cm;margin-bottom:10px'>
          In which paragraph (A–F) does the writer …<br>
          <b>1</b>&ensp;provide an example of a source of advice for those selecting a career?#<br>
          <b>2</b>&ensp;give explanations for the need of a certain characteristic?#<br>
          <b>3</b>&ensp;give an example of the jobs that won’t be done properly by a robot? #<br>
        </div>
        
        <div style='display:flex;width:25cm'>
          <b style='color:red'>A</b>
          <div style='margin-left:15px'>
              The world of work has been undergoing dramatic
              changes. Step by step, robots are taking over <span style='background:#b7b3d8'>manual jobs</span>
              that used to be done by semi-skilled workers.
              AI assistants in the forms of holograms have been
              on trial at Changi Airport and Boston Airport. Their
              performance as perfectly efficient check-in <span style='background:#b7b3d8'>staff</span> has
              amazed users. Certain restaurants in South Korea
              have introduced tablets for self-order and have even
              made use of maid robots in place of waiters and
              waitresses. Getting a secure job or a career can’t have
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>B</b>
          <div style='margin-left:15px'>
              On leaving school, 18-year-olds are faced with one
              of the toughest decisions of their life: career choice.
              Which to pursue requires sensible decision basing
              on their in-depth self-understanding. Guidance
              from school counsellors and parents together with
              <span style='background:#b7b3d8'>insights</span> into their potentials from character tests will
              empower them to make informed decisions. It is
              also worth noticing that, in this ever-advancing world,
              school-leavers need to be well-prepared for changes
              and once-in-a-lifetime opportunity.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>C</b>
          <div style='margin-left:15px'>
              However bewildering career choice may be, it is
              no more than the beginning of a person’s career. To
              further it, they have no alternative but to become
              better day by day. In essence, career development is
              a continual and lifelong process in which they need
              to specify their <span style='background:#b7b3d8'>goals</span>, boost their own confidence,
              improve communication skills, and expand personal
              <span style='background:#b7b3d8'>networking</span>. Actually, university graduation simply
              celebrates the completion of adults’ mainstream
              education, signifying their non-stop self-improvement. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>D</b>
          <div style='margin-left:15px'>
              Of all the qualities, a person’s flexibility will work in
              this full-of-changes world. Flexibility manifests itself
              in professional <span style='background:#b7b3d8'>agility</span>, problem-solving skills, change
              <span style='background:#b7b3d8'>management</span> and innovative thinking. As long as they
              are not set in their way of thinking, they can easily get
              a task done. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>E</b>
          <div style='margin-left:15px'>
              AI has been replacing certain blue-collar workers,
              but it can never establish a role in a field that has
              something to do with emotional intelligence (EQ).
              Automated learning machines can repeatedly lecture
              a subject, but they can never become an inspiring
              story for students. Robots in hospitals can accurately
              perform operations, but at no time can they comfort
              the lonely and suffering patients like a gentle nurse.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>F</b>
          <div style='margin-left:15px'>
            Technology has taken mankind to a world of
            constant change. School-leavers are expected to
            improve themselves and own the right skills to meet
            the social demands.
          </div>
        </div>
        
        
        `,
        // InputRong: true,
        answer: ["B", "D", "E"],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color: #42429c'>Reading Strategy</span>. Then read the first question
        in exercise 4, followed by paragraph A. When you
        have chosen the correct answer (a, b, c or d), read the
        paragraph again and decide why the other options are
        wrong.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["closely", "daily", "early", "friendly", "late", "quick"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 40, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 700,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page66/E3/1.jpg" style='height:8cm'/>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:15px'>
              It is harder for manual workers to find jobs because<br>
              <b>a</b>&ensp;the modern world prefers automatic holograms.<br>
              <b>b</b>&ensp;they do not work as effectively as robots.<br>
              <b>c</b>&ensp;robots for the airports are now available.<br>
              <b>d</b>&ensp;customers to restaurants are served by robots.<br>
            </div>
          </div>
        <b>Chosen the correct answer:</b>#<br>
        <b>Decide why the other options are wrong:</b>
        <textarea id=1></textarea>
        <textarea id=2></textarea>
        <textarea id=3></textarea>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>A</b>
          <div style='margin-left:15px'>
              The world of work has been undergoing dramatic
              changes. Step by step, robots are taking over <span style='background:#b7b3d8'>manual jobs</span>
              that used to be done by semi-skilled workers.
              AI assistants in the forms of holograms have been
              on trial at Changi Airport and Boston Airport. Their
              performance as perfectly efficient check-in <span style='background:#b7b3d8'>staff</span> has
              amazed users. Certain restaurants in South Korea
              have introduced tablets for self-order and have even
              made use of maid robots in place of waiters and
              waitresses. Getting a secure job or a career can’t have
          </div>
        </div>
        `,
        answer: [
          "b",
          "a: The text gives no information about the preferences (The world prefer).",
          "c: Robots are available at some airports only, not every airport around the world.",
          "d: Only customers at certain restaurants in South Korea are served by robots.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E4",
    exerciseKey: "img/FriendsPlus/Page66/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the text. For questions (2–5), circle the correct
        options (a–d).
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -20,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c <br> d`,
          `a <br> b <br> c <br> d`,
          `a <br> b <br> c <br> d`,
          `a <br> b <br> c <br> d`,
          `a <br> b <br> c <br> d`,
        ],
        answers: ["1-8", "4-0", "2-12", "3-4", "0-4"],
        initialValue: [],
      },
      Layout: `
      <div>
          <b>1</b>&ensp;It is harder for manual workers to find jobs because<br>
          <div style='display:flex'>
           <div style='margin-left:15px;margin-right:10px'>
             <input id=0 type='Circle' />
           </div>
           <div>
             the modern world prefers automatic holograms.<br>
             they do not work as effectively as robots.<br>
             robots for the airports are now available.<br>
             customers to restaurants are served by robots.<br>
           </div>
          </div>
          <b>2</b>&ensp;The key to a good choice of career is<br>
          <div style='display:flex'>
           <div style='margin-left:15px;margin-right:10px'>
             <input id=1 type='Circle' />
           </div>
           <div>
              talking with one’s parents.<br>
              taking calculated risks.<br>
              knowing oneself thoroughly.<br>
              consulting an expert.<br>
           </div>
          </div>
          <b>3</b>&ensp;In terms of career development, the writer emphasises<br>
          <div style='display:flex'>
           <div style='margin-left:15px;margin-right:10px'>
             <input id=2 type='Circle' />
           </div>
           <div>
              register for a continual course.<br>
              achieve a goal.<br>
              graduate from university.<br>
              better one’s interpersonal skills.<br>
           </div>
          </div>
          <b>4</b>&ensp;Flexibility<br>
          <div style='display:flex'>
           <div style='margin-left:15px;margin-right:10px'>
             <input id=3 type='Circle' />
           </div>
           <div>
              gets people into a professional conflict.<br>
              helps a person grow professionally.<br>
              wonderfully changes the world.<br>
              prevents people from acquiring new techniques.<br>
           </div>
          </div>
          <b>5</b>&ensp;In the writer’s opinions, AI<br>
          <div style='display:flex'>
           <div style='margin-left:15px;margin-right:10px'>
             <input id=4 type='Circle' />
           </div>
           <div>
              is not a frightening threat to young adults’ career.<br>
              is more efficient than humans in almost any field of work.<br>
              is going to free humans from the need to go to work.<br>
              is less sophisticated than humans in hospitals and schools.<br>
           </div>
          </div>
      </div>
      
        <div style='display:flex;width:25cm'>
          <b style='color:red'>A</b>
          <div style='margin-left:15px'>
              The world of work has been undergoing dramatic
              changes. Step by step, robots are taking over <span style='background:#b7b3d8'>manual jobs</span>
              that used to be done by semi-skilled workers.
              AI assistants in the forms of holograms have been
              on trial at Changi Airport and Boston Airport. Their
              performance as perfectly efficient check-in <span style='background:#b7b3d8'>staff</span> has
              amazed users. Certain restaurants in South Korea
              have introduced tablets for self-order and have even
              made use of maid robots in place of waiters and
              waitresses. Getting a secure job or a career can’t have
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>B</b>
          <div style='margin-left:15px'>
              On leaving school, 18-year-olds are faced with one
              of the toughest decisions of their life: career choice.
              Which to pursue requires sensible decision basing
              on their in-depth self-understanding. Guidance
              from school counsellors and parents together with
              <span style='background:#b7b3d8'>insights</span> into their potentials from character tests will
              empower them to make informed decisions. It is
              also worth noticing that, in this ever-advancing world,
              school-leavers need to be well-prepared for changes
              and once-in-a-lifetime opportunity.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>C</b>
          <div style='margin-left:15px'>
              However bewildering career choice may be, it is
              no more than the beginning of a person’s career. To
              further it, they have no alternative but to become
              better day by day. In essence, career development is
              a continual and lifelong process in which they need
              to specify their <span style='background:#b7b3d8'>goals</span>, boost their own confidence,
              improve communication skills, and expand personal
              <span style='background:#b7b3d8'>networking</span>. Actually, university graduation simply
              celebrates the completion of adults’ mainstream
              education, signifying their non-stop self-improvement. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>D</b>
          <div style='margin-left:15px'>
              Of all the qualities, a person’s flexibility will work in
              this full-of-changes world. Flexibility manifests itself
              in professional <span style='background:#b7b3d8'>agility</span>, problem-solving skills, change
              <span style='background:#b7b3d8'>management</span> and innovative thinking. As long as they
              are not set in their way of thinking, they can easily get
              a task done. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>E</b>
          <div style='margin-left:15px'>
              AI has been replacing certain blue-collar workers,
              but it can never establish a role in a field that has
              something to do with emotional intelligence (EQ).
              Automated learning machines can repeatedly lecture
              a subject, but they can never become an inspiring
              story for students. Robots in hospitals can accurately
              perform operations, but at no time can they comfort
              the lonely and suffering patients like a gentle nurse.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>F</b>
          <div style='margin-left:15px'>
            Technology has taken mankind to a world of
            constant change. School-leavers are expected to
            improve themselves and own the right skills to meet
            the social demands.
          </div>
        </div>
      
      
      `,
    },
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E5",
    exerciseKey: "img/FriendsPlus/Page66/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      marginTop: -5,
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginLeft: 17,
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the adverbs and adverbial
        phrases below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 700,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["daily", "hard", "lately", "pretty", "rough"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <div style='line-height:47px'>
          <div style='display:flex'><select id=5></select><div><b>1</b>&ensp;Every restaurant in South Korea has robots to serve customers.<textarea id=0 rows=2></textarea></div></div>
          <div style='display:flex'><select id=6></select><div><b>2</b>&ensp;Character tests can predict your future success in a career.<textarea id=1 rows=2></textarea></div></div>
          <div style='display:flex'><select id=7></select><div><b>3</b>&ensp;There are now jobs that people in the past heard nothing of.<textarea id=2 rows=2></textarea></div></div>
          <div style='display:flex'><select id=8></select><div><b>4</b>&ensp;Robots can partly replace a teacher.<textarea id=3 rows=2></textarea></div></div>
          <div style='display:flex'><select id=9></select><div><b>5</b>&ensp;Robots have both strengths and weaknesses.<textarea id=4 rows=2></textarea></div></div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>A</b>
          <div style='margin-left:15px'>
              The world of work has been undergoing dramatic
              changes. Step by step, robots are taking over <span style='background:#b7b3d8'>manual jobs</span>
              that used to be done by semi-skilled workers.
              AI assistants in the forms of holograms have been
              on trial at Changi Airport and Boston Airport. Their
              performance as perfectly efficient check-in <span style='background:#b7b3d8'>staff</span> has
              amazed users. Certain restaurants in South Korea
              have introduced tablets for self-order and have even
              made use of maid robots in place of waiters and
              waitresses. Getting a secure job or a career can’t have
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>B</b>
          <div style='margin-left:15px'>
              On leaving school, 18-year-olds are faced with one
              of the toughest decisions of their life: career choice.
              Which to pursue requires sensible decision basing
              on their in-depth self-understanding. Guidance
              from school counsellors and parents together with
              <span style='background:#b7b3d8'>insights</span> into their potentials from character tests will
              empower them to make informed decisions. It is
              also worth noticing that, in this ever-advancing world,
              school-leavers need to be well-prepared for changes
              and once-in-a-lifetime opportunity.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>C</b>
          <div style='margin-left:15px'>
              However bewildering career choice may be, it is
              no more than the beginning of a person’s career. To
              further it, they have no alternative but to become
              better day by day. In essence, career development is
              a continual and lifelong process in which they need
              to specify their <span style='background:#b7b3d8'>goals</span>, boost their own confidence,
              improve communication skills, and expand personal
              <span style='background:#b7b3d8'>networking</span>. Actually, university graduation simply
              celebrates the completion of adults’ mainstream
              education, signifying their non-stop self-improvement. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>D</b>
          <div style='margin-left:15px'>
              Of all the qualities, a person’s flexibility will work in
              this full-of-changes world. Flexibility manifests itself
              in professional <span style='background:#b7b3d8'>agility</span>, problem-solving skills, change
              <span style='background:#b7b3d8'>management</span> and innovative thinking. As long as they
              are not set in their way of thinking, they can easily get
              a task done. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>E</b>
          <div style='margin-left:15px'>
              AI has been replacing certain blue-collar workers,
              but it can never establish a role in a field that has
              something to do with emotional intelligence (EQ).
              Automated learning machines can repeatedly lecture
              a subject, but they can never become an inspiring
              story for students. Robots in hospitals can accurately
              perform operations, but at no time can they comfort
              the lonely and suffering patients like a gentle nurse.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>F</b>
          <div style='margin-left:15px'>
            Technology has taken mankind to a world of
            constant change. School-leavers are expected to
            improve themselves and own the right skills to meet
            the social demands.
          </div>
        </div>
        `,
        InputRong: true,
        answer: [
          "Some restaurants in South Korea have robots servecustomers.",
          "Character tests help students understand themselvesbetter so that they can make sensible decisions",
          "",
          "",
          "",
          "F",
          "F",
          "T",
          "T",
          "T",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E6",
    exerciseKey: "img/FriendsPlus/Page66/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the texts again. Answer the questions in your
        own words if possible.`,
        color: "black",
        width: "28cm",
        left: 40,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
          <b>1</b>&ensp;In what three ways does Alex Chacón use his blog?<textarea id=0 rows=3></textarea>
          <b>2</b>&ensp;If Alex’s plans succeed, how many years will he have been travelling?<textarea id=1 rows=3></textarea>
          <b>3</b>&ensp;What were the advantages of travelling by tractor for Manon Ossevoort?<textarea id=2 rows=3></textarea>
          <b>4</b>&ensp;How will future generations learn about our hopes for the future thanks to Manon?<textarea id=3 rows=3></textarea>
          <b>5</b>&ensp;Why did Rolf Potts seem to be the right person to try the no-baggage challenge?<textarea id=4 rows=3></textarea>
          <b>6</b>&ensp;What never, ever happens according to Rolf?<textarea id=5 rows=3></textarea>
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>A</b><br>
          At the age of seventeen, Alex Chacón
          <span style='background: rgb(252,193,91'>set off</span> from his home in El Paso, Texas
          ad went for a motorbike ride. Alex’s
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          thirty-day tour of the USA <span style='background: rgb(252,193,91)'>took in</span>
          California, Washington and Florida and
          gave Alex an aim in life: to travel the
          world on his motorbike.<br>
          Although Alex usually travels alone, he
          has shared his experiences on his blog.
          While travelling, Alex videos himself
          doing all sorts of activities against
          breathtaking backgrounds of remote
          jungles, deserts and mountains. On
          one trip, he filmed himself by moving
          in a 360<sup>o</sup> circle so that all the famous
          landmarks would be included.<br>
          During Alex’s motorcycle expeditions,
          he regularly stops to take part in
          volunteering projects at orphanages
          that he <span style='background: rgb(252,193,91)'>comes across</span> on his route.
          Furthermore, he uses his blog to raise
          donations for orphans.<br>
          Alex is now planning to cross Africa,
          Europe and Asia. It seems that by the
          time he’s thirty, Alex will have ridden his
          bike across most of the planet!
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>B</b><br>
          Dutch actress Manon Ossevoort has dreamed of travelling from Europe across
          Africa to the South Pole for years, and she chose a slow form of transport: a tractor.<br>
          Manon only averaged 5 km/h as she drove, but that allowed her to get to know
          the hospitable people in villages where she <span style='background: rgb(252,193,91)'>stopped over</span> for the night. She would
          explain why she was going to the South Pole and ask people to follow her blog and
          write down their own dreams. She promised to build a snowman when she arrived
          at her destination and leave all their dreams inside it. The idea was popular and
          Manon received thousands of dreams on pieces of paper and in emails.<br>
          Finally, on 9 December 2014, Manon’s dream came true, and she <span style='background: rgb(252,193,91)'>pulled up</span>  in a
          big red tractor at the South Pole. She then built a snowman and left a time capsule
          inside it with all the dreams she had collected. Manon explained on her blog that
          when the time capsule is opened in eighty years’ time, future generations will be
          able to read something about our lives and our hopes for the future.
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>C</b><br>
          Have you ever travelled with no luggage? That was the question Rolf Potts aimed to
          answer on the ‘no-baggage challenge’. However, the task wasn’t simply a question of
          <span style='background: rgb(252,193,91)'>going off</span> for a week to soak up the sun on a beach. Rolf had to travel 50,000 km by
          plane and <span style='background: rgb(252,193,91)'>get around</span> eleven countries in 42 days with just the things he could carry
          in his pockets. Would he be able to do it?<br>
          Rolf was certainly well qualified enough to try. He was an experienced traveller who
          wrote a blog about budget travel. Now he planned to update it with regular reports
          about travelling extremely light.<br>
          His first ‘no-baggage challenge’ entry listed the items he took with him: a toothbrush
          and toothpaste, a small bottle of soap, a mobile phone and charger, deodorant,
          sunglasses, a passport, cash and a credit card. His second entry explained that he had
          got into the habit of washing his clothes before going to bed during his trips. By the
          third entry, he was really enjoying luggage-free travel.<br>
          Potts said that he would never pack any ‘just in case’ items again, because the
          imagined situations that they were packed for would never happen. <br>
        </div>
        `,
        answer: [
          "He uses his blog to tell other people about his experiences and show them the places he visits. He also asks people to donate money to a charity that works with orphans.",
          "He will have been travelling for thirteen years.",
          "She travelled slowly and had the chance to get to know people, who were attracted to the tractor.",
          "She asked people to send her messages about their dreams and put these messages into a time capsule which she left in a snowman that she made at the South Pole.",
          "Because he is an experienced traveller and he writes a blog in which he gives advice about how to travel on a limited budget.",
          "Occasions which require special items of clothing or equipment never happen.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E7",
    exerciseKey: "img/FriendsPlus/Page66/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Match the highlighted phrasal verbs in
        the texts with the definitions. Write the base forms.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Phrasal verbs</b>"],
        styleElement: { color: "#414199", marginRight: "30px" },
      },
    ],
    questions: [
      {
        title: `
        <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          <hintbox id=1></hintbox>
          <b>1</b>&ensp;to stop a vehicle #<br>
          <b>2</b>&ensp;to leave a place to do something #<br>
          <b>3</b>&ensp;to move from place to place #<br>
          <b>4</b>&ensp;to begin a journey #<br>
          <b>5</b>&ensp;to find something by chance #<br>
          <b>6</b>&ensp;to include #<br>
          <b>7</b>&ensp;to stay somewhere for a short time during a journey #<br>
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>A</b><br>
          At the age of seventeen, Alex Chacón
          <span style='background: rgb(252,193,91)'>set off</span> from his home in El Paso, Texas
          and went for a motorbike ride. Alex’s
          thirty-day tour of the USA <span style='background: rgb(252,193,91)'>took in</span>
          California, Washington and Florida and
          gave Alex an aim in life: to travel the
          world on his motorbike.<br>
          Although Alex usually travels alone, he
          has shared his experiences on his blog.
          While travelling, Alex videos himself
          doing all sorts of activities against
          breathtaking backgrounds of remote
          jungles, deserts and mountains. On
          one trip, he filmed himself by moving
          in a 360<sup>o</sup> circle so that all the famous
          landmarks would be included.<br>
          During Alex’s motorcycle expeditions,
          he regularly stops to take part in
          volunteering projects at orphanages
          that he <span style='background: rgb(252,193,91)'>comes across</span> on his route.
          Furthermore, he uses his blog to raise
          donations for orphans.<br>
          Alex is now planning to cross Africa,
          Europe and Asia. It seems that by the
          time he’s thirty, Alex will have ridden his
          bike across most of the planet!
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>B</b><br>
          Dutch actress Manon Ossevoort has dreamed of travelling from Europe across
          Africa to the South Pole for years, and she chose a slow form of transport: a tractor.<br>
          Manon only averaged 5 km/h as she drove, but that allowed her to get to know
          the hospitable people in villages where she <span style='background: rgb(252,193,91)'>stopped over</span> for the night. She would
          explain why she was going to the South Pole and ask people to follow her blog and
          write down their own dreams. She promised to build a snowman when she arrived
          at her destination and leave all their dreams inside it. The idea was popular and
          Manon received thousands of dreams on pieces of paper and in emails.<br>
          Finally, on 9 December 2014, Manon’s dream came true, and she <span style='background: rgb(252,193,91)'>pulled up</span>  in a
          big red tractor at the South Pole. She then built a snowman and left a time capsule
          inside it with all the dreams she had collected. Manon explained on her blog that
          when the time capsule is opened in eighty years’ time, future generations will be
          able to read something about our lives and our hopes for the future.
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>C</b><br>
          Have you ever travelled with no luggage? That was the question Rolf Potts aimed to
          answer on the ‘no-baggage challenge’. However, the task wasn’t simply a question of
          <span style='background: rgb(252,193,91)'>going off</span> for a week to soak up the sun on a beach. Rolf had to travel 50,000 km by
          plane and <span style='background: rgb(252,193,91)'>get around</span> eleven countries in 42 days with just the things he could carry
          in his pockets. Would he be able to do it?<br>
          Rolf was certainly well qualified enough to try. He was an experienced traveller who
          wrote a blog about budget travel. Now he planned to update it with regular reports
          about travelling extremely light.<br>
          His first ‘no-baggage challenge’ entry listed the items he took with him: a toothbrush
          and toothpaste, a small bottle of soap, a mobile phone and charger, deodorant,
          sunglasses, a passport, cash and a credit card. His second entry explained that he had
          got into the habit of washing his clothes before going to bed during his trips. By the
          third entry, he was really enjoying luggage-free travel.<br>
          Potts said that he would never pack any ‘just in case’ items again, because the
          imagined situations that they were packed for would never happen. <br>
        </div>
        `,
        answer: [
          "pull up",
          "go off",
          "get around",
          "set off",
          "come across",
          "take in",
          "stop over",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E8",
    exerciseKey: "img/FriendsPlus/Page66/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Decide who is for and who is against
        this statement: ‘More tourism would be bad for the
        planet.’ Prepare arguments in favour of and against
        the statement. Use the ideas below to help you.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P66-E9",
    exerciseKey: "img/FriendsPlus/Page66/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss the statement in
        exercise 8. Use the phrases below to show that you
        agree or disagree.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          That’s exactly how I feel.<br>
          I couldn’t agree more. In fact, …<br>
          I see your point, but …<br>
          That may be true to some extent. However, …<br>
          I don’t really agree that …<br>
          I’m afraid I disagree. I think …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
