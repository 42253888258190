import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P127-E1",
    exerciseKey: "img/FriendsPlus/Page127/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Rewrite the sentences, changing the reported speech into
direct speech.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 800, paddingTop: 10 },
    hintBox: [
      {
        src: ["be in charge of", "cut", "request", "resign"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 900,
        marginLeft: 200,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;Katy said that she’d been studying since lunchtime.<br>#<br>
            <b>2</b>&ensp;Gina told me her aunt had been feeling unwell since last week.<br>#<br>
            <b>3</b>&ensp;Carla told me that she’d like to go to Africa one day.<br>#<br>
            <b>4</b>&ensp;Leo said that he had learned to ski the previous winter.<br>#<br>
            <b>5</b>&ensp;Alfie tells me that he doesn’t want to go to the match because he’s going to visit his cousins.<br>#<br>
            <b>6</b>&ensp;Tom says he’ll bring his sister to the party.<br>#<br>
            <b>7</b>&ensp;Karl says he isn’t going swimming with us tomorrow.<br>#<br>
            
        </div>
        `,
        // InputRong: true,
        answer: [
          "I've been studying since lunchtime.",
          "My aunt's been feeling unwell since last week.",
          "I'd like to go to Africa one day.",
          "I learned to ski last winter.",
          "I don't want to go to the match because I'm going to visit my cousins.",
          "I'll bring my sister to the party.",
          "I'm not going swimming with you tomorrow.",
        ],
      },
    ],
  },
  2: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P127-E2",
    exerciseKey: "img/FriendsPlus/Page127/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the verbs below in the
correct form.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: {
      //   marginLeft: 20,
      textAlign: "center",
      width: 250,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "award",
          "burn",
          "interview",
          "keep",
          "sell",
          "teach",
          "turn off",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;Use plenty of suncream to avoid # by the sun.<br>
            <b>2</b>&ensp;Everyone expects her an Oscar for her # latest performance.<br>
            <b>3</b>&ensp;No one wants # waiting in a queue.<br>
            <b>4</b>&ensp;I can’t # the right battery – this one doesn’t fit my tablet.<br>
            <b>5</b>&ensp;All mobile phones must # during the film.<br>
            <b>6</b>&ensp;Do you remember # how to ride a bike as a child?<br>
            <b>7</b>&ensp;They don’t think the singer will agree #  .<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "being burned",
          "to be awarded",
          "to be kept",
          "have been sold",
          "be turned off",
          "being taught",
          "to be interviewed",
        ],
      },
    ],
  },
  3: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P127-E3",
    exerciseKey: "img/FriendsPlus/Page127/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the second sentence in each pair so that it
means the same as the first.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about", "at", "by", "from", "of", "on", "to", "with"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: ["The inspector: reserved, loyal"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    stylesTextInput: { width: 100, paddingTop: 10 },
    // character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `

    <div style='width:26cm'>    
        <div style='display:flex'>
              <b>1</b>
              <div style='margin-left:20px'>
                They think that the thief escaped in a stolen car.<br>
                The thief<input width=619px />.
              </div>
            </div>
            <div style='display:flex'>
              <b>2</b>
              <div style='margin-left:20px'>
                It is known that too much fried food is bad for you.<br>
                Too much fried food<input width=489px />.
              </div>
            </div>
            <div style='display:flex'>
              <b>3</b>
              <div style='margin-left:20px'>
                The painting was thought to be an original Picasso.<br>
                It<input width=703px />.
              </div>
            </div>
            <div style='display:flex'>
              <b>4</b>
              <div style='margin-left:20px'>
                It’s reported that the earthquake caused extensive damage.<br>
                The earthquake<input width=540px />.
              </div>
            </div>
            <div style='display:flex'>
              <b>5</b>
              <div style='margin-left:20px'>
                They say that heavy rain has caused the floods.<br>
                Heavy rain<input width=598px />.
              </div>
            </div>
            <div style='display:flex'>
              <b>6</b>
              <div style='margin-left:20px'>
                They didn’t believe that his injuries were serious.<br>
                His injuries<input width=593px />.
              </div>
            </div>
            <div style='display:flex'>
              <b>7</b>
              <div style='margin-left:20px'>
                Some fruits are said by scientists to be ‘superfoods’.<br>
                It<input width=701px />.
              </div>
            </div>
            
        </div>
        `,
        // InputRong: true,
        answer: [
          "is thought to have escaped in a stolen car",
          "is known to be bad for you",
          "was thought to be an original Picasso",
          "is reported to have caused extensive damage",
          "is said to have caused the floods",
          "were not believed to be serious",
          "is said by scientists that some fruits are 'superfoods'",
        ],
      },
    ],
  },
  4: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P127-E4",
    exerciseKey: "img/FriendsPlus/Page127/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with adjectives formed from the
        nouns in brackets.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -30,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "265px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          //   padding: "0px 7px",
          color: "white",
          //   border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          //   padding: "0px 7px",
          //   borderRadius: "50%",
          color: "black",
          //   border: "2px solid",
          //   borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [`✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `
      
     <div style='line-height:50px;display:flex'>
            <div style='margin-right:250px'>
                <b>1</b>&ensp;let someone know<br>
                <b>2</b>&ensp;look forward to<br>
                <b>3</b>&ensp;put off<br>
                <b>4</b>&ensp;hand in<br>
                <b>5</b>&ensp;stand for<br>
                <b>6</b>&ensp;leave out<br>
            </div>
            <div>
                <b>a</b>&ensp;represent<br>
                <b>b</b>&ensp;submit<br>
                <b>c</b>&ensp;inform<br>
                <b>d</b>&ensp;anticipate<br>
                <b>e</b>&ensp;omit<br>
                <b>f</b>&ensp;postpone<br>
            </div>
     </div>
      `,
    },
  },
  5: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P127-E5",
    exerciseKey: "img/FriendsPlus/Page127/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the words
        and phrases below. Say which sentence is formal or informal.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 300,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "apologise",
          "inform",
          "let you know",
          "postpone",
          "put off",
          "sorry",
        ],
        styleElement: {
          color: "#91268e",
          marginBottom: 10,
          marginRight: "40px",
        },
        width: "90%",
      },
    ],
    character: /[,/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:50px'>
            <b>1</b>&ensp; # about breaking your vase!<br>
            <b>2</b>&ensp;We are writing to # for the late delivery of your order.<br>
            <b>3</b>&ensp;We regret to # you that the item you requested is no longer in stock.<br>
            <b>4</b>&ensp;Just thought I’d better # that we can’t make your party.<br>
            <b>5</b>&ensp;We regret to announce that the meeting has been #.<br>
            <b>6</b>&ensp;We’ll have to # that dinner till next week.<br>
        </div>
        
        `,
        answer: [
          "Sorry (informal)|(informal)",
          "apologise (formal)|(formal)",
          "inform (formal)|(formal)",
          "let you know (informal)|(informal)",
          "postponed (formal)|(formal)",
          "put off (informal)|(informal)",
        ],
      },
    ],
  },
  6: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P127-E6",
    exerciseKey: "img/FriendsPlus/Page127/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 140 },
    titleQuestion: [
      {
        num: "3",
        title: `Match the American words in A with the British words in B.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>A</b>",
          "bathing suit",
          "pants",
          "sneakers",
          "sweater",
          "undershirt",
          "vest",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "jumper",
          "swimming costume",
          "trainers",
          "trousers",
          "vest",
          "waistcoat",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>A</b>",
          "eraser",
          "hot lunch",
          "math",
          "recess",
          "teachers’ lounge",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "break time",
          "maths",
          "rubber",
          "school dinner",
          "staff room",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>A</b>", "cupcake", "dessert", "eggplant", "jelly", "takeout"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>B</b>", "aubergine", "fairy cake", "jam pudding", "takeaway"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    character: /[,-]/,
    // characterCoupleWork: "",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Clothes
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <textarea id=0 rows=3></textarea>
        <b>2</b>&ensp;School
        <hintbox id=2></hintbox>
        <hintbox id=3></hintbox>
        <textarea id=1 rows=3></textarea>
        <b>3</b>&ensp;Food
        <hintbox id=4></hintbox>
        <hintbox id=5></hintbox>
        <textarea id=2 rows=3></textarea>
        
        
          `,
        answer: [
          "bathing suit - swimming costume, pants - trousers, sneakers - trainers, sweater - jumper, undershirt - vest, vest - waistcoat",
          "eraser - rubber, hot lunch - school dinner, math - maths, recess - break time, teachers' lounge - staff room",
          "cupcake - fairy cake, dessert - pudding, eggplant - aubergine, jelly - jam, takeout - takeaway",
        ],
      },
    ],
  },
  7: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P127-E7",
    exerciseKey: "img/FriendsPlus/Page127/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Add an example of your own for
        each type of adjective formation.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 330, paddingTop: 10 },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page127/E7/1.jpg" style='height:11cm'/><br>
            <div style='margin-left:15px'>
                <b>-ful:</b> #<br>
                <b>-ive:</b> #<br>
                <b>-al:</b> #<br>
                <b>-able:</b> #<br>
                <b>-ous:</b> #<br>
                <b>-y:</b> #<br>
                <b>-less:</b> #<br>
            </div>
        </div>
        `,
        answer: [
          "truthful, useful",
          "active, creative",
          "magical, fatal",
          "breakable, uncontrollable",
          "dangerous, envious",
          "salty, muddy",
          "helpless, lifeless",
        ],
      },
    ],
  },
  8: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P127-E8",
    exerciseKey: "img/FriendsPlus/Page127/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page127/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
