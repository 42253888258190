import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P27-E1",
    exerciseKey: "img/FriendsPlus/Page27/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss how you would feel
        and what you would do in these situations.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
              <b>1</b>
            <div style='margin-left:15px'>
              You have just ridden your bike into another cyclist who<br>
              pulled out into the road in front of you without looking.
            </div>
        </div>
        <div style='display:flex'>
              <b>2</b>
            <div style='margin-left:15px'>
              You have just said something negative about a friend<br>
              without realising the friend was right behind you.
            </div>
        </div>
        
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P27-E2",
    exerciseKey: "img/FriendsPlus/Page27/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. Which pieces of advice could be useful to
        each situation in exercise 1? Why? Which do you think is
        the best piece of advice?
        `,
        color: "black",
        left: 50,
        width: "100%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <img src="img/FriendsPlus/Page27/E2/1.jpg" style='margin-left:-50px;height:21.5cm'/>
            <div>
                <div style='display:flex'>
                      <b>1</b>
                    <div style='margin-left:15px'>
                      You have just ridden your bike into another cyclist who
                      pulled out into the road in front of you without looking.
                    </div>
                </div>
                <div style='display:flex'>
                      <b>2</b>
                    <div style='margin-left:15px'>
                      You have just said something negative about a friend
                      without realising the friend was right behind you.
                    </div>
                </div>
                <b>Answer:</b><br>
                <b>1</b> #<br>
                <b>2</b> #<br>
                <b>3</b> #
            </div>
        </div>

        `,
        // InputRong: true,
        answer: [
          "situations 1, 2|situations 2, 1",
          "situations 1, 2|situations 2, 1",
          "situation 2",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P27-E3",
    exerciseKey: "img/FriendsPlus/Page27/Key/E3answerKey.png",
    audio: "Audios/1.16.mp3",
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='1.16' colorimg=#ddaea9  color='white'></headphone> Read the <span style='color:#c30c45'> Listening Strategy</span> Then listen to five
        dialogues. Which words are emphasised in each? Which
        reason (1–3) is it?`,
        color: "black",
        left: 50,
        width: "",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 300, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page27/E3/1.jpg" style='height:10.5cm'/>
            <div style='line-height:50px'>
                <b>Answer:</b><br>
                <b>1</b> #<br>
                <b>2</b> #<br>
                <b>3</b> #<br>
                <b>4</b> #<br>
                <b>5</b> #
            </div>
        </div>

        `,
        // InputRong: true,
        answer: [
          "quiet - 1|1 - quiet",
          "taken - 3|3 - taken",
          "cash, card - 2|2 - cash, card|card, cash - 2|2 - card, cash",
          "forgotten, passport - 1|1 - forgotten, passport|passport, forgotten - 1|1 - passport, forgotten",
          "Ted, mine - 3|3 - Ted, mine|mine, Ted - 3|3 - mine, Ted",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P27-E4",
    exerciseKey: "img/FriendsPlus/Page27/Key/E4answerKey.png",
    textareaStyle: {
      resize: "none",
      width: 450,
      paddingTop: 1,
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Look at the lines of dialogues. Decide where the emphasis
        should go and why.
        `,
        color: "black",
        width: "28.5cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <img src="img/FriendsPlus/Page27/E2/1.jpg" style='margin-left:-50px;height:21.5cm'/>
            <div>
                <div style='display:flex'>
                      <b>1</b>
                    <div style='margin-left:15px'>
                      Do you want to call the police or the fire brigade?
                    </div>
                </div>
                <div style='display:flex'>
                      <b>2</b>
                    <div style='margin-left:15px'>
                      <b>A</b>&ensp;Do you live at number 7 Duncan Drive?<br>
                      <b>B</b>&ensp;No, I live at number 11.<br>
                    </div>
                </div>
                <div style='display:flex'>
                      <b>3</b>
                    <div style='margin-left:15px'>
                      Are those the burglar’s fingerprints or mine?
                    </div>
                </div>
                <div style='display:flex'>
                      <b>4</b>
                    <div style='margin-left:15px'>
                      I can’t find my ring. It’s a diamond ring, and it’s very expensive
                    </div>
                </div>
                <div style='display:flex'>
                      <b>5</b>
                    <div style='margin-left:15px'>
                      <b>A</b>&ensp;You say you left the house at quarter to eight?<br>
                      <b>B</b>&ensp;No, it was quarter past eight.<br>
                    </div>
                </div>
                <div style='display:flex'>
                      <b>6</b>
                    <div style='margin-left:15px'>
                      I saw a van outside. A blue van. A dark blue van.
                    </div>
                </div>
            </div>
        </div>
        
        `,
        // InputRong: true,
        answer: [
          "most of his accidents could have ended a lot more seriously",
          "And the older he got, the worse things became.",
          "John’s life has been more accident-filled than you would think possible.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P27-E5",
    exerciseKey: "img/FriendsPlus/Page27/Key/E5answerKey.png",
    audio: "Audios/1.17.mp3",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='1.17' colorimg=#ddaea9  color='white'></headphone> Listen and check. Practise the sentences in pairs.
        `,
        color: "black",
        left: 50,
        width: "25cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    // textAlign: "center",
    stylesTextInput: { width: 710, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Idioms to describe feelings</b>"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div>
                <div style='display:flex'>
                      <b>1</b>
                    <div style='margin-left:15px'>
                      Do you want to call the police or the fire brigade?
                    </div>
                </div>
                <div style='display:flex'>
                      <b>2</b>
                    <div style='margin-left:15px'>
                      <b>A</b>&ensp;Do you live at number 7 Duncan Drive?<br>
                      <b>B</b>&ensp;No, I live at number 11.<br>
                    </div>
                </div>
                <div style='display:flex'>
                      <b>3</b>
                    <div style='margin-left:15px'>
                      Are those the burglar’s fingerprints or mine?
                    </div>
                </div>
                <div style='display:flex'>
                      <b>4</b>
                    <div style='margin-left:15px'>
                      I can’t find my ring. It’s a diamond ring, and it’s very expensive
                    </div>
                </div>
                <div style='display:flex'>
                      <b>5</b>
                    <div style='margin-left:15px'>
                      <b>A</b>&ensp;You say you left the house at quarter to eight?<br>
                      <b>B</b>&ensp;No, it was quarter past eight.<br>
                    </div>
                </div>
                <div style='display:flex'>
                      <b>6</b>
                    <div style='margin-left:15px'>
                      I saw a van outside. A blue van. A dark blue van.
                    </div>
                </div>
            </div>
            <b>Answer</b>
            <div>
              <b>1</b>&ensp;#<br>
              <b>2</b>&ensp;#<br>
              <b>3</b>&ensp;#<br>
              <b>4</b>&ensp;#<br>
              <b>5</b>&ensp;#<br>
              <b>6</b>&ensp;#<br>
            </div>
        `,
        // InputRong: true,
        answer: [
          "police, fire brigade - to make clear two alternatives|to make clear two alternatives - police, fire brigade",
          "11 - to correct what someone has said|to correct what someone has said - 11",
          "burglar's, mine - to make clear two alternatives|to make clear two alternatives - burglar's, mine",
          "diamond, very - highlight new or important information|highlight new or important information - diamond, very",
          "past - to correct what someone has said|to correct what someone has said - past",
          "blue, dark - highlight new or important information|highlight new or important information - blue, dark",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P27-E6",
    exerciseKey: "img/FriendsPlus/Page27/Key/E6answerKey.png",
    audio: "Audios/1.18.mp3",

    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.17' colorimg=#ddaea9  color='white'></headphone> Listen to a dialogue between two friends.
        Choose the correct answers (a–d).
         `,
        color: "black",
        width: "28cm",
        left: -50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // dontChangeColor: true,
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid ",
          color: "black",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["1-12", "0-4", "2-0"],
        initialValue: [],
      },
      Layout: `
      
        
      <div style='line-height:50px'>
        <b>1</b>&ensp;George doesn’t believe that
        <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=0 type=Circle /></div>  
          <div>
              Gemma is telling the truth.<br>
              Gemma saw Sarah stealing from Alex.<br>
              Gemma didn’t say anything.<br>
              Sarah has done anything wrong.
          </div>
        </div>
        <b>2</b>&ensp;Gemma believes that
        <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=1 type=Circle /></div>  
          <div>
              she saw Sarah take Sam’s lunch money.<br>
              she has enough evidence against Sarah.<br>
              Sarah took something out of Gemma’s schoolbag.<br>
              something is troubling Sarah.
          </div>
        </div>
        <b>3</b>&ensp;George agrees that
        <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=2 type=Circle /></div>  
          <div>
              Sarah isn’t behaving as she normally does.<br>
              they ought to mention their suspicions to an adult.<br>
              Gemma should speak to Alex first.<br>
              Gemma should speak to Sarah alone.
          </div>
        </div>
      </div>
     
      `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P27-E7",
    exerciseKey: "img/FriendsPlus/Page27/Key/E7answerKey.png",
    recorder: true,
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 360, paddingTop: 10 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Are you good at keeping calm in a crisis? Give reasons
            for your answer.<br>
            <b>2</b>&ensp;What would you have done if you had been in
            Gemma’s situation in the classroom?<br>
            <b>3</b>&ensp;Do you think that George and Gemma decided to do
            the right thing? <br>
        </div>
        `,
        answer: [
          "is nowhere near as difficult as",
          "are much safer than",
          "nothing like as important as",
          "more popular than it",
          "older you get, the more",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P27-E8",
    exerciseKey: "img/FriendsPlus/Page27/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss whether you agree or
        disagree with the statements in exercise 7. Give reasons.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
