import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Introduction",
    id: "SB12-2024-I-P10-E1",
    exerciseKey: "img/FriendsPlus/Page10/Key/E1answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Tell your partner about:`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <ul style="list-style-type: disc;">
          <li> your first day at school.</li>
          <li> the first birthday that you can remember.</li>
          <li> learning to ride a bike.</li>
        </ul>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Introduction",
    id: "SB12-2024-I-P10-E2",
    exerciseKey: "img/FriendsPlus/Page10/Key/E2answerKey.png",
    audio: "Audios/1.03.mp3",
    component: T6,
    checkDuplicated: true,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 230, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Stages of life</b>",
          "be an adult",
          "be a centenarian",
          "be an infant",
          "be a toddler",
          "be elderly",
          "be in your teens",
          "be middle-aged",
        ],
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name='1.03' colorimg=#d0b5d4  color='white'></headphone> <span style='color:#91268e'>VOCABULARY</span> Listen. How old are the people
        being described? Choose from the stages of life below.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div>
          <b>1</b>&ensp;#<br>
          <b>2</b>&ensp;#<br>
          <b>3</b>&ensp;#<br>
          <b>4</b>&ensp;#<br>
          <b>5</b>&ensp;#<br>
          <b>6</b>&ensp;#<br>
          <b>7</b>&ensp;#<br>
        </div>
        
        `,
        answer: [
          "be an infant",
          "be a centenarian",
          "be in your teens",
          "be a toddler",
          "be elderly",
          "be an adult",
          "be middle-aged",
        ],
      },
    ],
  },

  3: {
    unit: "Introduction",
    id: "SB12-2024-I-P10-E3",
    exerciseKey: "img/FriendsPlus/Page10/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: ["about", "at", "by", "of", "to", "with"],
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the questions with the prepositions below. You can use them more than once.",
        color: "black",
        // left: -50,
        width: "90%",
      },
    ],
    character: "/",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        At which age or ages do you think people are …
        <div style='margin-top:10px'>
          <b>1</b>&ensp;most obsessed#fashion?<br>
          <b>2</b>&ensp;curious#the world?<br>
          <b>3</b>&ensp;good#sport?<br>
          <b>4</b>&ensp;addicted#social media?<br>
          <b>5</b>&ensp;more sensitive#other people’s feelings?<br>
          <b>6</b>&ensp;unhappy#their financial situation?<br>
          <b>7</b>&ensp;shocked#young people’s behaviour?<br>
          <b>8</b>&ensp;aware#the environment?<br>
        </div>
        
        `,
        answer: [
          "with",
          "about",
          "at",
          "to",
          "to",
          "with / about",
          "by / at",
          "of",
        ],
      },
    ],
  },

  4: {
    unit: "Introduction",
    id: "SB12-2024-I-P10-E4",
    exerciseKey: "img/FriendsPlus/Page10/Key/E4answerKey.png",
    recorder: true,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Answer the questions in
        exercise 3.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 250, textAlign: "center", paddingTop: 10 },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-top:10px'>
          <b>1</b>&ensp;most obsessed with fashion?<br>
          <b>2</b>&ensp;curious about the world?<br>
          <b>3</b>&ensp;good at sport?<br>
          <b>4</b>&ensp;addicted to social media?<br>
          <b>5</b>&ensp;more sensitive to other people’s feelings?<br>
          <b>6</b>&ensp;unhappy with their financial situation?<br>
          <b>7</b>&ensp;shocked by young people’s behaviour?<br>
          <b>8</b>&ensp;aware of the environment?<br>
        </div>
      
      
        
        `,
        answer: [
          "thinking",
          "asked",
          "reminded",
          "insisted",
          "accused / accusing|accused|accusing",
          "apologise",
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "SB12-2024-I-P10-E5",
    exerciseKey: "img/FriendsPlus/Page10/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `<span style='color:#91268e'>VOCABULARY</span> Complete the life events with the verbs
        below.
        `,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "0px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "become",
          "born",
          "fall",
          "get",
          "have",
          "learn",
          "leave",
          "pass",
          "settle",
          "start",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='color:rgb(145,38,142);'>
          <b>Life events</b>&emsp;get married&emsp;buy a house or flat
          emigrate&emsp;be <sup style='color:black'>1</sup># &emsp;be brought up (by)&emsp;retire&emsp;
          start a family&emsp;<sup style='color:black'>2</sup># in love&emsp;get divorced&emsp;
          <sup style='color:black'>3</sup># engaged&emsp; go to university&emsp;grow up&emsp;
          <sup style='color:black'>4</sup># a change of career&emsp;inherit (money, a house, etc.)
          <sup style='color:black'>5</sup># to drive&emsp;<sup style='color:black'>6</sup># home&emsp;leave school / university
          move (house)&emsp;<sup style='color:black'>7</sup># away&emsp;split up&emsp;<sup style='color:black'>8</sup># down&emsp;
          <sup style='color:black'>9</sup># a business&emsp; start school&emsp;<sup style='color:black'>10</sup># a grandparent&emsp;
          get your first job&emsp;
        </div>
      
        
        `,
        answer: [
          "born",
          "fall",
          "get",
          "have",
          "learn",
          "leave",
          "pass",
          "settle",
          "start",
          "become ",
        ],
      },
    ],
  },
  6: {
    unit: "Introduction",
    id: "SB12-2024-I-P10-E6",
    exerciseKey: "img/FriendsPlus/Page10/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "continue",
          "forget",
          "go on",
          "like",
          "prefer",
          "remember",
          "start",
          "stop",
          "try",
        ],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the sentences with the correct form of the
        phrases in exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div>
          <b>1</b>&ensp;My brother # school at sixteen and got his first #
          four months later. However, when he was in his
          twenties, he decided to # to university and later
          had a change of # .<br>
          <b>2</b>&ensp;My parents # in love when they were in their late
          thirties. They got # after three months and then #
           married a month later!<br>
          <b>3</b>&ensp;We didn’t have to # a house because my dad #
          his parents’ house after they had sadly # .<br>
          <b>4</b>&ensp;My dad # from Ireland in 1998. He met my mum
          and they # down in London where they # a
          family.<br>
          <b>5</b>&ensp;My mum’s parents # up when she was very young.
          They got # soon after. They both had to travel for
          their jobs so my mum was # by her aunt.
       </div>
        
        `,
        answer: [
          "left",
          "job",
          "go",
          "career",
          "fell",
          "engaged",
          "got",
          "buy",
          "inherited",
          "passed away",
          "emigrated",
          "settled",
          "started",
          "split",
          "divorced",
          "brought up",
        ],
      },
    ],
  },
  7: {
    unit: "Introduction",
    id: "SB12-2024-I-P10-E7",
    exerciseKey: "img/FriendsPlus/Page10/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title:
          "Complete the text with the correct form of the phrases in exercise 5.",
        color: "black",
        left: 50,
      },
    ],
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      width: 140,
      paddingTop: 10,
    },
    component: T6,
    questions: [
      {
        title: `
       <div style='box-shadow:4px 5px 9px gray;width:21cm;background:rgb(242,239,220);justify-content:center;border:5px solid gray;padding:10px 30px;border-radius:25px'>
          <div style='margin-bottom:10px;'>
            I was <sup>1</sup># on October17th, 2001 in London,
            although my parents aren’t Londoners. My dad <sup>2</sup>#
            from Ireland when he was a teenager and my mum,
            who is from Croatia, <sup>3</sup># home after she had <sup>4</sup>#
            to university. My dad started a business and my mum
            got her first, and only, <sup>5</sup># in his company!
          </div>
          Well, after a few months, they <sup>6</sup># in love, and six
          months later they <sup>7</sup># engaged. After they got <br><sup>8</sup># ,
          they <sup>9</sup># to south-east London and <br><sup>10</sup># down in
          the neighbourhood of Greenwich, where they started
          a <sup>11</sup># , and I was the first one to come along! I’ve
          also got a brother and sister, and we’ve all been <br><sup>12</sup>#
          up in the same house. What’s more, when my mum’s
          mum <sup>13</sup># a grandmother, she decided to leave
          Croatia too and come and help my mum! 
       </div>
        
        `,
        answer: [
          "born",
          "emigrated",
          "left",
          "been",
          "job",
          "fell",
          "got",
          "married",
          "moved",
          "settled",
          "family",
          "brought",
          "became",
        ],
      },
    ],
  },
  8: {
    unit: "Introduction",
    id: "SB12-2024-I-P10-E8",
    exerciseKey: "img/FriendsPlus/Page10/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page10/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
