import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P91-E6",
    exerciseKey: "img/FriendsPlus/Page91/Key/E6answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 380, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "are discovered",
          "hasn’t been removed",
          "might be hidden",
          "was examined",
          "was killed",
          "will be displayed",
        ],
        width: 500,
        borderColor: "#5cc9dd",
        marginLeft: 200,
      },
    ],
    selectStyle: {
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    titleQuestion: [
      {
        num: "6",
        title: `Read the text again. Are the sentences true or false? 
          Write T or F. Correct the false sentences.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div><img src="img/FriendsPlus/Page90/E3/1.jpg" style='height:20cm'/></div>
        <b>1</b>&ensp;A vlogger could attract thousands of fans.<br>
        <b>2</b>&ensp;Most successful vloggers do not appreciate interaction with their audience.<br>
        <b>3</b>&ensp;Vloggers and their viewers are from the same age group.<br>
        <b>4</b>&ensp;The relationship between vloggers and viewers could be damaged by money.<br>
        <b>5</b>&ensp;Zoella’s fans thought she was under the control of the media corporations.<br>

        <b>Answer:</b>
        <div style='display:flex;'><div style='margin-right:20px'><b>1.</b></div> <select id=5></select> #</div>
        <div style='display:flex;'><div style='margin-right:20px'><b>2.</b></div> <select id=6></select> #</div>
        <div style='display:flex;'><div style='margin-right:20px'><b>3.</b></div> <select id=7></select> #</div>
        <div style='display:flex;'><div style='margin-right:20px'><b>4.</b></div> <select id=8></select> #</div>
        <div style='display:flex;'><div style='margin-right:20px'><b>5.</b></div> <select id=9></select> #</div>
      
        
        `,
        answer: [
          "",
          "line 10 -13",
          "",
          "",
          "Many media experts thought",
          "T",
          "F",
          "T",
          "T",
          "F",
        ],
        InputRong: true,
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P91-E7",
    exerciseKey: "img/FriendsPlus/Page91/Key/E7answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 160, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "are discovered",
          "hasn’t been removed",
          "might be hidden",
          "was examined",
          "was killed",
          "will be displayed",
        ],
        width: 500,
        borderColor: " #5cc9dd",
        marginLeft: 200,
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: ` Match the highlighted nouns in the text 
          with definitions (1–6).`,
        color: "black",
        left: 100,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div><img src="img/FriendsPlus/Page90/E3/1.jpg" style='height:20cm'/></div>
        <div style='margin-left:150px;padding-left:20px;border-left:5px solid rgb(66,66,156)'>
          <b style='color:rgb(66,66,156)'>Digital media</b><br>
          <b>1</b>&ensp;organisations that supply someone with what they want #<br>
          <b>2</b>&ensp;the ability to reach or use something #<br>
          <b>3</b>&ensp;the homepage for user’s videos on YouTube #<br>
          <b>4</b>&ensp;the ideas in a book, film, etc. #<br>
          <b>5</b>&ensp;criticism, opinions, etc. that someone receives about their work #<br>
          <b>6</b>&ensp;communication between two or more people#<br>
        </div>
      
        
        `,
        answer: [
          "providers",
          "accessibility",
          "channel",
          "content",
          "feedback",
          "interaction",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P91-E8",
    exerciseKey: "img/FriendsPlus/Page91/Key/E8answerKey.png",
    component: T6,
    inputSize: 500,
    recorder: true,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Discuss the questions.
          `,
        color: "black",
        // width:"28cm",
        // left:150,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;Do people follow vloggers in your country?<br>
          <b>2</b>&ensp;Who are the most popular vloggers in your country? What makes them popular?<br>
          <b>3</b>&ensp;Why are the ‘media lives’ of some celebrities so short?<br>
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P91-E8",
    exerciseKey: "img/FriendsPlus/Page91/Key/E8answerKey.png",
    component: T6,
    inputSize: 500,
    audio: "Audios/3-11.mp3",
    // recorder: true,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        // width:"28cm",
        // left:150,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div><img src="img/FriendsPlus/Page90/E3/1.jpg" style='height:20cm'/></div>
        <div>
          <b>1</b>&ensp;Do people follow vloggers in your country?<br>
          <b>2</b>&ensp;Who are the most popular vloggers in your country? What makes them popular?<br>
          <b>3</b>&ensp;Why are the ‘media lives’ of some celebrities so short?<br>
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
