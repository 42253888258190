import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import { CgMagnet } from "react-icons/cg";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P16-E1",
    exerciseKey: "img/FriendsPlus/Page16/Key/E1answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Look at the photo and the title. What is a ‘buddy’? What
        sort of film is a ‘buddy movie’? Read the text to check.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page16/E1/1.jpg" style='margin-left:100px;height:19cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P16-E2",
    exerciseKey: "img/FriendsPlus/Page16/Key/E2answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      // resize: "none",
      marginBottom: -23,
    },
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 90,
      paddingTop: 10,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["He’s always talking about his life"],
        // styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#f88b17'>Learn this!</span>  box. the gaps with <i>a / an, the</i>
         or no article (–) and find examples in exercise 1.`,
        color: "black",
        // width: "30cm",
        left: 50,
      },
    ],
    character: /[,/]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div>
              <div style='position:relative;display:flex'>
                <img src="img/FriendsPlus/Page16/E2/1.jpg" style='height:12cm'/>
                <div style='position:absolute;top:52px;left:221px'>#</div>
                <div style='position:absolute;top:85px;left:271px'>#</div>
                <div style='position:absolute;top:125px;left:278px'>#</div>
                <div style='position:absolute;top:198px;left:156px'>#</div>
                <div style='position:absolute;top:231px;left:349px'>#</div>
                <div style='position:absolute;top:271px;left:156px'>#</div>
                <div style='position:absolute;top:312px;left:154px'>#</div>
                <div style='position:absolute;top:352px;left:156px'>#</div>
              </div>
              <div>
                <b>Find examples in exercise 1:</b>
                <div style='display:flex'><b>1</b>&ensp;<textarea resize=none id=8 rows=2></textarea></div>
                <div style='display:flex'><b>2</b>&ensp;<textarea resize=none id=9 rows=2></textarea></div>
                <div style='display:flex'><b>3</b>&ensp;<textarea resize=none id=10 rows=2></textarea></div>
                <div style='display:flex'><b>4</b>&ensp;<textarea resize=none id=11 rows=2></textarea></div>
                <div style='display:flex'><b>5</b>&ensp;<textarea resize=none id=12 rows=2></textarea></div>
                <div style='display:flex'><b>6</b>&ensp;<textarea resize=none id=13 rows=2></textarea></div>
                <div style='display:flex'><b>7</b>&ensp;<textarea resize=none id=14 rows=2></textarea></div>
                <div style='display:flex'><b>8</b>&ensp;<textarea resize=none id=15 rows=2></textarea></div>
              </div>
          </div>
          <img src="img/FriendsPlus/Page16/E1/1.jpg" style='height:19cm'/>
        </div>
        <div style='display:flex'>
          
          
        </div>
        
        
        `,
        answer: [
          "a / an",
          "the",
          "a / an",
          "-",
          "the",
          "-",
          "the",
          "the",
          "a challenge",
          "the challenge",
          "a (minor) role, an animal",
          "Europe",
          "the USA",
          "Buddy movies, different backgrounds, different personalities, action films, road movies, westerns, women",
          "the earliest",
          "the old, the young",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P16-E3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E3answerKey.png",

    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct answers to complete the sentences.`,
        color: "black",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        dontChangeColor: true,
        initialWordStyle: {
          // padding: "1px 7px",
          border: "2px solid white",
          fontWeight: "bold",
          color: "transparent",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 7px",
          color: "transparent",
          borderRadius: "50%",
          // color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "an / the",
          "i / the",
          "the / i",
          "a / the",
          "a / i",
          "the / i",
          "i / the",
          "i / a",
          "a / the",
          "i / The",
        ],
        answers: [
          "0-4",
          "1-0",
          "2-0",
          "3-4",
          "4-0",
          "5-4",
          "6-0",
          "7-4",
          "8-0",
          "9-4",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='position:relative'>
        <b>1</b>&ensp;Mont Blanc is <b>an / the</b> tallest mountain in <b>- / the</b> France.<br>
        <b>2</b>&ensp;It’s usually <b>the / -</b> poor who have <b>a / the</b> most problems.<br>
        <b>3</b>&ensp;He was in <b>a / -</b> film about <b>the / -</b> people with <b>- / the</b> superpowers.<br>
        <b>4</b>&ensp;I’ve got <b>- / a</b> cat and <b>a / the</b> dog. <b>- / The</b> dog is eighteen years old!<br>
        <div style='position:absolute;top:0px;left:186px'><input id=0 type='Circle'/></div>
        <div style='position:absolute;top:0px;left:517px'><input id=1 type='Circle'/></div>
        <div style='position:absolute;top:39px;left:151px'><input id=2 type='Circle'/></div>
        <div style='position:absolute;top:39px;left:412px'><input id=3 type='Circle'/></div>
        <div style='position:absolute;top:79px;left:142px'><input id=4 type='Circle'/></div>
        <div style='position:absolute;top:79px;left:321.5px'><input id=5 type='Circle'/></div>
        <div style='position:absolute;top:79px;left:545.5px'><input id=6 type='Circle'/></div>
        <div style='position:absolute;top:118px;left:113.5px'><input  id=7 type='Circle'/></div>
        <div style='position:absolute;top:118px;left:258px'><input id=8 type='Circle'/></div>
        <div style='position:absolute;top:118px;left:403px'><input id=9 type='Circle'/></div>
      </div>
      `,
    },
  },

  4: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P16-E4",
    exerciseKey: "img/FriendsPlus/Page16/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `<span style='color:#91268e'>USE OF ENGLISH</span>  Read the <span style='color:#f88b17'>Learn this!</span> box. Then choose
        the correct answers (a–d).`,
        color: "black",
        width: "28cm",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: [
          "0-4",
          "1-8",
          "2-8",
          "3-4",
          "4-0",
          "5-8",
          "6-12",
          "7-4",
          "8-0",
          "9-12",
        ],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page16/E4/1.jpg" style='margin-left:50px;height:11cm'/>
      <div style='display:flex'>
          <div>
            <div style='display:flex'>
              <b>1</b>&ensp;
              <div style='margin-left:15px'><input id=0 type='Circle'/></div>
              <div style='margin-left:15px'>much <br> a few <br> every <br> none</div>
            </div>
            <div style='display:flex'>
              <b>6</b>&ensp;
              <div style='margin-left:15px'><input id=5 type='Circle'/></div>
              <div style='margin-left:15px'>few <br> each <br> both <br> neither</div>
            </div>
          </div>
          <div style='margin:0px 50px'>
            <div style='display:flex'>
              <b>2</b>&ensp;
              <div style='margin-left:15px'><input id=1 type='Circle'/></div>
              <div style='margin-left:15px'>Each of <br> Every <br> All of <br> Most</div>
            </div>
            <div style='display:flex'>
              <b>7</b>&ensp;
              <div style='margin-left:15px'><input id=6 type='Circle'/></div>
              <div style='margin-left:15px'>all <br> most <br> some <br> a lot</div>
            </div>
          </div>
          <div>
            <div style='display:flex'>
              <b>3</b>&ensp;
              <div style='margin-left:15px'><input id=2 type='Circle'/></div>
              <div style='margin-left:15px'>any <br> none <br> little <br> several</div>
            </div>
            <div style='display:flex'>
              <b>8</b>&ensp;
              <div style='margin-left:15px'><input id=7 type='Circle'/></div>
              <div style='margin-left:15px'>none <br> several <br> much of <br> every</div>
            </div>
          </div>
          <div style='margin:0px 50px'>
            <div style='display:flex'>
              <b>4</b>&ensp;
              <div style='margin-left:15px'><input id=3 type='Circle'/></div>
              <div style='margin-left:15px'>both <br> another <br> other <br> each</div>
            </div>
            <div style='display:flex'>
              <b>9</b>&ensp;
              <div style='margin-left:15px'><input id=8 type='Circle'/></div>
              <div style='margin-left:15px'>neither <br> either <br> no <br> both</div>
            </div>
          </div>
          <div>
            <div style='display:flex'>
              <b>5</b>&ensp;
              <div style='margin-left:15px'><input id=4 type='Circle'/></div>
              <div style='margin-left:15px'>some <br> few of <br> either <br> little</div>
            </div>
            <div style='display:flex'>
              <b>10</b>&ensp;
              <div style='margin-left:3px'><input id=9 type='Circle'/></div>
              <div style='margin-left:15px'>every <br> a little <br> few <br> every one</div>
            </div>
          </div>
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P16-E5",
    exerciseKey: "img/FriendsPlus/Page16/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      // resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Discuss the information below with
        your partner. Try to use quantifiers from the <span style='color:#f88b17'>Learn this!</span> box box in your answers. `,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        color: "black",
        left: 50,
        width: "30cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      textAlign: "center",
      width: 300,
      paddingTop: 10,
    },
    recorder: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <div style='line-height:47px'>
          <b>1</b>&ensp;How much free time you spend with classmates.<br>
          <b>2</b>&ensp;How many of your friends have the same taste in films.<br>
        </div> 
        `,
        notSymbol: true,
        answer: [
          `Wouldn't it be great if parents and their teenagers got on all
        the time?
        Try not to argue!
        You may regret not being patient!|Wouldn't it be great if parents and their teenagers got on all
        the time?, Try not to argue!, You may regret not being patient!`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P16-E6",
    exerciseKey: "img/FriendsPlus/Page16/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    stylesTextInput: { width: 600, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "continue",
          "forget",
          "go on",
          "like",
          "prefer",
          "remember",
          "start",
          "stop",
          "try",
        ],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Write the words in the correct order to make sentences.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=''>
          <b>1</b>&ensp;to / not / I’ll / late / tomorrow / be / try<br>#<br>
          <b>2</b>&ensp;be / the / shouldn’t / test / for / Aidan / studying / ?<br>#<br>
          <b>3</b>&ensp;honest / not / with / I / being / him / resent / me<br>#<br>
          <b>4</b>&ensp;not / upset / was / Kat / invited / be / to<br>#<br>
          <b>5</b>&ensp;about / should / you / argue / try / to / not / it<br>#<br>
        </div>
        
        
        `,
        answer: [
          "I'll try not to be late tomorrow.",
          "Shouldn't Aidan be studying for the test?",
          "I resent him not being honest with me.",
          "Kat was upset not to be invited.",
          "You should try not to argue about it. ",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P16-E7",
    exerciseKey: "img/FriendsPlus/Page16/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:#91268e'>USE OF ENGLISH</span> Rewrite the sentences beginning with
          the words in brackets. Do not change the meaning. `,
        color: "black",
        width: "27cm",
        left: 50,
      },
    ],
    inputSize: 700,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:48px'>
          <b>1</b>&ensp;He doesn’t often go out during the week. (Rarely)<br>#<br>
          <b>2</b>&ensp;He wasn’t to blame. (In no way)<br>#<br>
          <b>3</b>&ensp;Don’t worry about the future. (Try)<br>#<br>
          <b>4</b>&ensp;Luckily she didn’t hurt herself badly. (She was)<br>#<br>
          <b>5</b>&ensp;They won’t decide until they know the facts. (Only when)<br>#<br>
          <b>6</b>&ensp;I’m sure we’ve been here before. (Haven’t)<br>#<br>
        </div>
        `,
        answer: [
          "Rarely does he go out during the week.",
          "In no way was he to blame.",
          "Try not to worry about the future.",
          "She was lucky not to hurt herself badly.",
          "Only when they know the facts will they decide.",
          "Haven't we been here before?",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P16-E8",
    exerciseKey: "img/FriendsPlus/Page16/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Think of different ways to
        complete the sentences below. Compare your sentences
        with another pair’s. `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Under no circumstances should …<br>
          <b>2</b>&ensp;At no time have I ever …<br>
          <b>3</b>&ensp;Only once have I …<br>
          <b>4</b>&ensp;It’s so important not to …<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
