import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P103-E3",
    exerciseKey: "img/FriendsPlus/Page103/Key/E3answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color: #42429c'>Reading Strategy</span>. Then look at the question
        below. Which text matches with it? What is the
        information in the text that helps you answer it?
        `,
        color: "black",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Holiday activities</b>",
          "be",
          "broaden",
          "eat",
          "enjoy",
          "get away",
          "hang out",
          "have",
          "meet",
          "pamper",
          "recharge",
          "see",
          "soak up",
          "take",
          "try",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 750,
      // textAlign: "center",
    },
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
    },
    select: true,
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page103/E3/1.jpg" style='height:7.5cm'/><br>
        Who started their project because they had already identified a need?<textarea id=0 rows=5></textarea>
        <div style='margin-top:10px;width:26cm'>
             A teacher spotted Rene Silva’s talent for writing when he was just eleven and encouraged him to set up a community
            newspaper. Rene accepted the challenge and <span style='background:rgb(199,161,202)'>took</span> on publishing Voz da Comunidade (Voice of the Community) and he
            soon realised that the monthly newspaper could help his neighbourhood. Rene lives on the edge of Rio de Janeiro in
            a favela, a poor town that used to be controlled by armed drug gangs. Rene’s newspaper quickly became a <span style='background:rgb(199,161,202)'>vehicle</span> for
            protest about the poor conditions in the favela and gave a voice to local residents who were campaigning to make the
            community a safer and better place to live.<br>
            
            But one Saturday morning, Voz da Comunidade was transformed from a small local newspaper into Brazil’s most popular
            source of information. The government had decided to force the drug dealers out of the favela and had sent soldiers onto
            the streets. Journalists from the <span style='background:rgb(199,161,202)'>mainstream</span> media waited outside the favela for news, but Rene and his team of teenage
            reporters were in the middle of the action. Using their mobile phones to film and take photos, the Voz da Comunidade
            Twitter account suddenly had thousands more followers than usual and Rene’s reporting was praised by the mainstream
            media. More importantly, it started a debate about how the government should help the inhabitants of Brazil’s hundreds
            of favelas. Rene and the Voz da Comunidade brought positive change to the community<br>
        </div>
          
        `,
        // InputRong: true,
        answer: [
          `Wilson To - He wanted to stop bullying.;
Note: Rene realised that his newspaper could help people after he had started it.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P103-E4",
    exerciseKey: "img/FriendsPlus/Page103/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Match two texts with questions (1–5) below. Each of the
        texts can match more than one question.
        `,
        color: "black",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Holiday activities</b>",
          "be",
          "broaden",
          "eat",
          "enjoy",
          "get away",
          "hang out",
          "have",
          "meet",
          "pamper",
          "recharge",
          "see",
          "soak up",
          "take",
          "try",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 750,
      // textAlign: "center",
    },
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B"],
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm;line-height:50px'>
            <div style='display:flex'><select id=0></select><div style='margin-left:10px'><b>1</b>&ensp;offered some training to others?</div></div>
            <div style='display:flex'><select id=1></select><div style='margin-left:10px'><b>2</b>&ensp;was given an idea by someone else?</div></div>
            <div style='display:flex'><select id=2></select><div style='margin-left:10px'><b>3</b>&ensp;published something?</div></div>
            <div style='display:flex'><select id=3></select><div style='margin-left:10px'><b>4</b>&ensp;worked in the most dangerous conditions?</div></div>
            <div style='display:flex'><select id=4></select><div style='margin-left:10px'><b>5</b>&ensp;worked anonymously?</div></div>
        </div>

          
        `,
        // InputRong: true,
        answer: ["A", "B", "B", "B", "A"],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P103-E5",
    exerciseKey: "img/FriendsPlus/Page103/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },

    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Decide if the statements (1–5) are true (T), false (F), or
        the information is not given (NG).`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 140,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: [
          "<b>Holidays and holiday accommodation</b>",
          "adventure holiday",
          "B&B",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 100,
      height: 40,
      textAlign: "center",
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["NG", "T", "F"],
    component: T6,
    questions: [
      {
        title: `
    <div style='width:26cm;line-height:50px'>
        <div style='display:flex'><select id=0></select><div style='margin-left:10px'><b>1</b>&ensp;Wilson To was worried that his Facebook page would attract the wrong type of comments.</div></div>
        <div style='display:flex'><select id=1></select><div style='margin-left:10px'><b>2</b>&ensp;Wilson has left the school, but continues with his job as administrator of the school page.</div></div>
        <div style='display:flex'><select id=2></select><div style='margin-left:10px'><b>3</b>&ensp;Wilson didn’t let anyone know he was the creator of the page because he was too modest.</div></div>
        <div style='display:flex'><select id=3></select><div style='margin-left:10px'><b>4</b>&ensp;Rene Silva got the funding for his newspaper from local residents.</div></div>
        <div style='display:flex'><select id=4></select><div style='margin-left:10px'><b>5</b>&ensp;Rene’s reporting has had a big impact outside the favela.</div></div>
    </div>
        
        `,
        answer: ["T", "F", "NG", "NG", "T"],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P103-E6",
    exerciseKey: "img/FriendsPlus/Page103/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "6",
        title: `Match the underlined words with the
        appropriate form of the highlighted words or phrases in
        the articles.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 200, textAlign: "center" },
    hintBox: [
      {
        src: ["<b>Words or phrases</b>"],
        width: "80%",
        styleElement: { color: "#42429c", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <hintbox id=0></hintbox>
        <div style='width:26cm;border-left:5px solid rgb(66,66,156);padding-left:15px'>
            <b>1</b>&ensp;The biggest sponsor of the contest still remained <u>unknown</u>. His name could only be guessed.  #<br>
            <b>2</b>&ensp;The drama club is a perfect <u>expression</u> for Amy’s talent.  #<br>
            <b>3</b>&ensp;Jack always <u>invents</u> great ideas for our politics blog.  #<br>
            <b>4</b>&ensp;Mark <u>accepted</u> too much work and responsibility in the organisation and suffered from stress.  #<br>
            <b>5</b>&ensp;The <u>typical</u> journalists are expected to have particular skills in coverage and photography.  #<br>
            <b>6</b>&ensp;Alice <u>left the job</u> so that Andy could take her place.   #<br>
            <b>7</b>&ensp;My grandad <u>established</u> the business in 1958.  #<br>
        </div>
        <div style='margin-top:10px;width:26cm'>
             A teacher spotted Rene Silva’s talent for writing when he was just eleven and encouraged him to set up a community
            newspaper. Rene accepted the challenge and <span style='background:rgb(199,161,202)'>took</span> on publishing Voz da Comunidade (Voice of the Community) and he
            soon realised that the monthly newspaper could help his neighbourhood. Rene lives on the edge of Rio de Janeiro in
            a favela, a poor town that used to be controlled by armed drug gangs. Rene’s newspaper quickly became a <span style='background:rgb(199,161,202)'>vehicle</span> for
            protest about the poor conditions in the favela and gave a voice to local residents who were campaigning to make the
            community a safer and better place to live.<br>
            
            But one Saturday morning, Voz da Comunidade was transformed from a small local newspaper into Brazil’s most popular
            source of information. The government had decided to force the drug dealers out of the favela and had sent soldiers onto
            the streets. Journalists from the <span style='background:rgb(199,161,202)'>mainstream</span> media waited outside the favela for news, but Rene and his team of teenage
            reporters were in the middle of the action. Using their mobile phones to film and take photos, the Voz da Comunidade
            Twitter account suddenly had thousands more followers than usual and Rene’s reporting was praised by the mainstream
            media. More importantly, it started a debate about how the government should help the inhabitants of Brazil’s hundreds
            of favelas. Rene and the Voz da Comunidade brought positive change to the community<br>
        </div>
        `,
        answer: [
          "anonymous",
          "vehicle",
          "thinks up",
          "took on",
          "mainstream",
          "stepped down",
          "set up",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P103-E7",
    exerciseKey: "img/FriendsPlus/Page103/Key/E7answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Discuss the question.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Work and jobs</b>"],
        styleElement: { color: "#514ea7", marginRight: "30px" },
        width: "80%",
      },
    ],
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>Wilson To or Rene Silva, who makes a deeper impression
        on you? </div>
        

        `,
        answer: [
          "networking",
          "insight",
          "agility",
          "management",
          "manual jobs",
          "staff",
          "goals",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P103-E8",
    exerciseKey: "img/FriendsPlus/Page103/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { textAlign: "center", width: 400, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    character: ",",
    hintBox: [
      {
        src: [
          "<b>Describing places</b>",
          "breathtaking",
          "commercialised",
          "dingy",
          "dull",
          "inaccessible",
          "overcrowded",
          "remote",
          "unique",
          "unspoilt",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "80%",
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm;line-height:50px'>
          <b>1</b>&ensp;What jobs do you think AI can’t do properly?<br>
          <b>2</b>&ensp;What do you think is more important when it comes to choosing a job: the salary or the job satisfaction?<br>
        </div>
        `,
        answer: [
          "sremote",
          "breathtaking, inaccessible",
          "overcrowded, commercialised",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P103-E9",
    exerciseKey: "img/FriendsPlus/Page103/Key/E9answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `<headphone name='2.02' colorimg=#d1b6d5  color='white'></headphone> Read the <span style='color:#92278f' >Recycle!</span> box. Then
        listen again and answer the questions in
        writing.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page103/E9/1.jpg" style='height:13cm'/>
        <div style='width:25cm'>
            <b>1</b>&ensp;In dialogue 1, what does the woman wish?<br>#<br>
            <b>2</b>&ensp;In dialogue 2, how would the boy feel if he spent a few nights at Lindisfarne Castle?<br>#<br>
            <b>3</b>&ensp;In dialogue 3, what advice would the woman give if somebody wanted a relaxing holiday?<br>#<br>
        </div>
        
        
        
        `,
        answer: [
          "She wishes they were already on holiday.",
          "He would be worried about meeting ghosts.",
          "This wouldn't be a great place to visit.",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P103-E10",
    exerciseKey: "img/FriendsPlus/Page103/Key/E10answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 370, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Work in pairs. Which of the
        holidays in exercise 2 would you most like
        to go on? Why?`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
          Which gesture or expression might you use if …<br>
            <b>1</b>&ensp;you wish someone good luck in an exam? <br>
            <b>2</b>&ensp;you are puzzled by a question?<br>
            <b>3</b>&ensp;you don’t know the answer to a question that someone has asked?<br>
        </div>
        <img src="img/FriendsPlus/Page103/E10/1.jpg" style='height:4cm'/>

        `,
        answer: [
          "might annoy people",
          "could think of you as cold and reserved",
          "might not get the job",
        ],
      },
    ],
  },
};

export default json;
