import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P30-E1",
    exerciseKey: "img/FriendsPlus/Page30/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Look at the photos that illustrate two
        common problems in a city. What do you think are
        the problems? What are some possible solutions?`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page30/E1/1.jpg" style='margin-left:-50px;height:10cm'/>
          <img src="img/FriendsPlus/Page30/E1/2.jpg" style='margin-left:10px;height:20cm'/>
        </div>
        
          
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P30-E2",
    exerciseKey: "img/FriendsPlus/Page30/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read texts (A–C) opposite. Match the texts with the
        titles (1–5). There are two extra titles.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 350, paddingTop: 10 },
    hintBox: [
      {
        src: ["age", "common", "course", "fact", "first", "purpose", "trouble"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: T6,
    questions: [
      {
        title: `
       <div style='display:flex;line-height:50px'>
        <div>
              <b>1</b>&ensp;I’ve got my eye on you!<br>
              <b>2</b>&ensp;Brighter and safer<br>
              <b>3</b>&ensp;Driven to crime<br>
              <b>4</b>&ensp;Feeling blue<br>
              <b>5</b>&ensp;Politics works!<br>
        </div>
        <div style='margin-left:200px'>
              <b>Answer</b><br>
              <b>A:</b>#<br>
              <b>B:</b>#<br>
              <b>C:</b>#<br>
        </div>
       </div>
       <div style='width:25cm'>
            <b>A</b>&ensp;Dr Melissa Bateson, a psychologist from Newcastle
            University, secretly conducted an experiment to see if she
            could change her colleagues’ behaviour when it came to
            paying for their hot drinks. The teachers were expected to
            put money in a box in the staff room, but nobody controlled
            how much money each teacher gave. Dr Bateson put a large
            picture of some flowers on the wall behind the box for a
            few weeks and then replaced it with a picture of a pair
            of human eyes. At the end of the experiment, Dr Bateson
            found that teachers paid almost three times more for their
            drinks when the picture of the eyes was on the wall.
            She concluded that we are programmed to respond to eyes,
            even pictures of eyes, and believed that her posters could
            help <b>combat</b> crime.<br>
            <b>B</b>&ensp;During the 1960s and ’70s, industrialised countries
            suffered an incredible crime <b>wave</b>, and <b>violent</b> crimes
            such as muggings increased at an alarming rate. What is
            more, it was expected to get worse. Only it didn’t. Instead,
            it got much, much better. In the 1990s, crime started to fall
            dramatically, and today it is down by almost 50% across
            the industrialised world and continues to fall. Triumphant
            politicians will assert that their policies have <b>cut</b> crime.
            Actually, regardless of the country’s policies, the trend
            does coincide with the use and subsequent prohibition of
            tetraethyl lead in petrol, which has been known for years
            as a cause of aggressive behaviour. Therefore, countries
            that still use leaded petrol are being urged to stop using it
            to help improve health and crime <b>prevention</b>.<br>
            <b>C</b>&ensp;After the installation of blue-light street lamps in
            Buchanan Street, the main shopping centre of Glasgow
            (Scotland), the local police felt that thieves were <b>committing</b>
            fewer crimes in Buchanan Street. Statistics proved that the
            number of <b>recorded</b> crimes had fallen – especially <b>petty</b>
            crimes such as pickpocketing and bag snatching. Some
            psychologists claim that blue is a calming colour and reduces
            people’s levels of stress and aggression. Whatever the
            reason, it seems that blue lighting does <b>deter</b> crime and it
            has created interest abroad. Many neighbourhoods in Japan
            have adopted the change and seen crime fall at a similar
            rate as in Glasgow.<br>
       </div>
          
        `,
        // InputRong: true,
        answer: [
          "1|1 (I've got my eye on you!)|I've got my eye on you!",
          "3|3 (Driven to crime)|Driven to crime",
          "4|4 (Feeling blue)|Feeling blue",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P30-E3",
    exerciseKey: "img/FriendsPlus/Page30/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#42429c'>Reading Strategy</span> Match texts (A–C) with
        questions (1–6). Each paragraph may be matched
        with more than one question.`,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    character: /[|/]/,
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page30/E3/1.jpg" style='height:6cm'/>
       <div style='display:flex;line-height:50px'>
        <div>
              Which text mentions …<br>
              <b>1</b>&ensp;similar falls in crime in different countries?<br>
              <b>2</b>&ensp;a fall in the crime rate of under 50%?<br>
              <b>3</b>&ensp;a fall in crime that was the result of a planned experiment?<br>
              <b>4</b>&ensp;people claiming that they were responsible for less crime?<br>
              <b>5</b>&ensp;a crime prevention technique that has interested people in another country?<br>
              <b>6</b>&ensp;a local change that had an effect on the crime rate?<br>
        </div>
        <div style='margin-left:20px'>
              <b>Answer</b><br>
              <b>1</b>#<br>
              <b>2</b>#<br>
              <b>3</b>#<br>
              <b>4</b>#<br>
              <b>5</b>#<br>
              <b>6</b>#<br>
        </div>
       </div>
       <div style='width:25cm'>
            <b>A</b>&ensp;Dr Melissa Bateson, a psychologist from Newcastle
            University, secretly conducted an experiment to see if she
            could change her colleagues’ behaviour when it came to
            paying for their hot drinks. The teachers were expected to
            put money in a box in the staff room, but nobody controlled
            how much money each teacher gave. Dr Bateson put a large
            picture of some flowers on the wall behind the box for a
            few weeks and then replaced it with a picture of a pair
            of human eyes. At the end of the experiment, Dr Bateson
            found that teachers paid almost three times more for their
            drinks when the picture of the eyes was on the wall.
            She concluded that we are programmed to respond to eyes,
            even pictures of eyes, and believed that her posters could
            help <b>combat</b> crime.<br>
            <b>B</b>&ensp;During the 1960s and ’70s, industrialised countries
            suffered an incredible crime <b>wave</b>, and <b>violent</b> crimes
            such as muggings increased at an alarming rate. What is
            more, it was expected to get worse. Only it didn’t. Instead,
            it got much, much better. In the 1990s, crime started to fall
            dramatically, and today it is down by almost 50% across
            the industrialised world and continues to fall. Triumphant
            politicians will assert that their policies have <b>cut</b> crime.
            Actually, regardless of the country’s policies, the trend
            does coincide with the use and subsequent prohibition of
            tetraethyl lead in petrol, which has been known for years
            as a cause of aggressive behaviour. Therefore, countries
            that still use leaded petrol are being urged to stop using it
            to help improve health and crime <b>prevention</b>.<br>
            <b>C</b>&ensp;After the installation of blue-light street lamps in
            Buchanan Street, the main shopping centre of Glasgow
            (Scotland), the local police felt that thieves were <b>committing</b>
            fewer crimes in Buchanan Street. Statistics proved that the
            number of <b>recorded</b> crimes had fallen – especially <b>petty</b>
            crimes such as pickpocketing and bag snatching. Some
            psychologists claim that blue is a calming colour and reduces
            people’s levels of stress and aggression. Whatever the
            reason, it seems that blue lighting does <b>deter</b> crime and it
            has created interest abroad. Many neighbourhoods in Japan
            have adopted the change and seen crime fall at a similar
            rate as in Glasgow.<br>
       </div>
          
        `,
        // InputRong: true,
        answer: ["B, C|C, B", "B", "A, C|C, A", "B", "C", "B, C|C, B"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P30-E4",
    exerciseKey: "img/FriendsPlus/Page30/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    recorder: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Work in pairs. Discuss the questions about
        the article.`,
        color: "black",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      fontWeight: "bold",
      borderBottom: "none",
      background: "none",
      width: 91,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='width:950px'>
            <b>1</b>&ensp;Why do you think Dr Bateson first put a picture of
            flowers behind the collection box?<br>
            <textarea id=0 rows=3></textarea>
            <b>2</b>&ensp;Why might politicians not want to believe the theory
            about lead petrol?<br>
            <textarea id=1 rows=3></textarea>
            <b>3</b>&ensp;What is the writer suggesting by using the phrase
            ‘whatever the reason’ in the text about blue street lighting?<br>
            <textarea id=2 rows=3></textarea>
        </div>

        `,
        answer: [
          "She possibly wanted to get teachers used to the idea of a picture being in that place. She also probably used the picture of flowers as a control: would people respond to any picture or a specific picture? ",
          "They might not want to believe it because it would mean that their crime-fighting policies have had little or no effect. ",
          "The writer is suggesting that the psychologists' explanation is just one possible explanation and that there may be others.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P30-E5",
    exerciseKey: "img/FriendsPlus/Page30/Key/E5answerKey.png",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Copy and complete the diagram with the
        words in bold from the text that collocate with crime.
        Which words come before crime and which come after?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      fontSize: 25,
      width: 182,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: ["Crime collocations"],
        styleElement: { color: "#42429c", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page30/E5/1.jpg" style='height:19cm'/>
          <div style='position:absolute;top:99px;left:125px'>#</div>
          <div style='position:absolute;top:107px;left:397px'>#</div>
          <div style='position:absolute;top:312px;left:40px'>#</div>
          <div style='position:absolute;top:418px;left:234px'>#</div>
          <div style='position:absolute;top:61px;left:721px'>#</div>
          <div style='position:absolute;top:105px;left:1012px'>#</div>
          <div style='position:absolute;top:435px;left:935px'>#</div>
          <div style='position:absolute;top:598px;left:986px'>#</div>
          <div style='position:absolute;top:567px;left:407px'>#</div>
        </div>
        <b>Which words come before crime and which come after?</b><br><input width='900px' font-size=25px id=9 />
        <div style='width:25cm'>
            <b>A</b>&ensp;Dr Melissa Bateson, a psychologist from Newcastle
            University, secretly conducted an experiment to see if she
            could change her colleagues’ behaviour when it came to
            paying for their hot drinks. The teachers were expected to
            put money in a box in the staff room, but nobody controlled
            how much money each teacher gave. Dr Bateson put a large
            picture of some flowers on the wall behind the box for a
            few weeks and then replaced it with a picture of a pair
            of human eyes. At the end of the experiment, Dr Bateson
            found that teachers paid almost three times more for their
            drinks when the picture of the eyes was on the wall.
            She concluded that we are programmed to respond to eyes,
            even pictures of eyes, and believed that her posters could
            help <b>combat</b> crime.<br>
            <b>B</b>&ensp;During the 1960s and ’70s, industrialised countries
            suffered an incredible crime <b>wave</b>, and <b>violent</b> crimes
            such as muggings increased at an alarming rate. What is
            more, it was expected to get worse. Only it didn’t. Instead,
            it got much, much better. In the 1990s, crime started to fall
            dramatically, and today it is down by almost 50% across
            the industrialised world and continues to fall. Triumphant
            politicians will assert that their policies have <b>cut</b> crime.
            Actually, regardless of the country’s policies, the trend
            does coincide with the use and subsequent prohibition of
            tetraethyl lead in petrol, which has been known for years
            as a cause of aggressive behaviour. Therefore, countries
            that still use leaded petrol are being urged to stop using it
            to help improve health and crime <b>prevention</b>.<br>
            <b>C</b>&ensp;After the installation of blue-light street lamps in
            Buchanan Street, the main shopping centre of Glasgow
            (Scotland), the local police felt that thieves were <b>committing</b>
            fewer crimes in Buchanan Street. Statistics proved that the
            number of <b>recorded</b> crimes had fallen – especially <b>petty</b>
            crimes such as pickpocketing and bag snatching. Some
            psychologists claim that blue is a calming colour and reduces
            people’s levels of stress and aggression. Whatever the
            reason, it seems that blue lighting does <b>deter</b> crime and it
            has created interest abroad. Many neighbourhoods in Japan
            have adopted the change and seen crime fall at a similar
            rate as in Glasgow.<br>
       </div>

        `,
        // InputRong: true,
        answer: [
          "combat |cut |commit |deter ",
          "combat |cut |commit |deter ",
          "combat |cut |commit |deter ",
          "combat |cut |commit |deter ",
          " prevention| wave",
          " prevention| wave",
          "violent |recorded |petty ",
          "violent |recorded |petty ",
          "violent |recorded |petty ",
          "Adjectives and verbs come before the noun, nouns come after the noun.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P30-E6",
    exerciseKey: "img/FriendsPlus/Page30/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Read the dictionary entries. Then
        choose the correct words to complete the phrases with
        end and time.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `by_the / in`,
          `At_the / In`,
          `on / by_the`,
          `in / at`,
          `In / By`,
          `on / in`,
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `

      <img src="img/FriendsPlus/Page30/E6/1.jpg" style='margin-left:100px;height:17cm'/>
      <div style='margin-left:100px;width:19cm'>
        Last night, I waited for Matt, but he was late – again! So
        <br><sup>1</sup><input id=0 type='Circle' /> time we got to the concert, it had already
        started. <sup>2</sup><input id=1 type='Circle' /> time, I was so angry – I don’t know
        why he can’t be <sup>3</sup><input id=2 type='Circle' /> time for once! I tried
        discussing it with him, but <sup>4</sup><input id=3 type='Circle' /> the end I gave up. <sup>5</sup><input id=4 type='Circle' /> the end of the evening, we weren’t speaking.
        I don’t know how things will turn out, but I hope that
        <sup>6</sup><input id=5 type='Circle' /> time we’ll be friends again.
      </div>
     
      `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P30-E7",
    exerciseKey: "img/FriendsPlus/Page30/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss the questions. Give
        reasons for your answers.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px;width:80%'>
            <b>1</b>&ensp;Do you think magazines and websites offer good advice to people who write in with their problems?<br>
            <b>2</b>&ensp;If you had a problem, would you seek advice on a forum?<br>
           
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P30-E8",
    exerciseKey: "img/FriendsPlus/Page30/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page30/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
