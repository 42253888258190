import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E1",
    exerciseKey: "img/FriendsPlus/Page44/Key/E1answerKey.png",
    recorder: true,

    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        left: 50,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: ["film (n)", "flat", "lift (n)", "petrol", "tap", "toilet"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 800,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;In Viet Nam, on which occasion do people usually hold a celebration?<br>
          <b>2</b>&ensp;How do they usually celebrate these occasions? Think about food, parties, gifts / cards and other customs.<br>
        </div>
        `,
        answer: ["movie", "apartment", "elevator", "gas", "faucet", "restroom"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E2",
    exerciseKey: "img/FriendsPlus/Page44/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Look at the photos. What special events do you think
        the people are celebrating? Why do you think that?
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { width: 100, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Gestures and expressions</b>", "bite your fingernails"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page44/E2/1.jpg" style='height:14cm'/><br>
        
        `,

        // InputRong: true,
        answer: ["BrE", "NAmE"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E3answerKey.png",
    audio: "Audios/1.36.mp3",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='1.36' colorimg=#b6d0b3  color='white'></headphone> Read the task below and then listen to a
        student doing the task. Does she agree with your ideas
        from exercise 2?`,
        color: "black",
        left: 0,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    stylesTextInput: { textAlign: "center", width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: ["annoyance", "anxiety"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page44/E3/1.jpg" style='height:8cm'/><br>
        
        `,

        // InputRong: true,
        answer: ["BrE", "NAmE"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E4",
    exerciseKey: "img/FriendsPlus/Page44/Key/E4answerKey.png",
    audio: "Audios/1.36.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.36' colorimg=#b6d0b3  color='white'></headphone> Listen again. Write down the first four
        sentences. Which words in the sentences are unstressed?`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 40,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div>
          <img src="img/FriendsPlus/Page44/E3/1.jpg" style='height:8cm'/><br>
        </div>
        <textarea id=0 rows=5></textarea>

         `,
        answer: [
          "The photos show two different family celebrations. The first is probably a wedding reception, judging by the smart clothes and the flowers. The second looks like a more informal occasion - maybe a birthday party. Both are very common family celebrations in my country - and probably in most other countries too.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E5",
    exerciseKey: "img/FriendsPlus/Page44/Key/E5answerKey.png",

    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Read the <span style='color:#009147'>Reading Strategy 1</span>. Then complete
        phrases (1–4) with the words below.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 150,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["concerned", "else", "now", "subject"],
        styleElement: { color: "#009147", marginRight: "30px" },
        // width: "70%",
      },
      {
        src: ["<b>Moving onto another topic</b>"],
        styleElement: { color: "#009147", marginRight: "30px" },
        // width: "70%",
      },
    ],

    component: T6,
    questions: [
      {
        title: `

        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page44/E5/1.jpg" style='height:5.5cm'/><br>
        <div style='border-left:5px solid rgb(0,145,71);padding-left:20px'>
          <hintbox id=1></hintbox>
          <b>1</b>&ensp;As far as … is / are #  , …<br>
          <b>2</b>&ensp;Turning # to the topic of …<br>
          <b>3</b>&ensp;There’s something # I’d like to talk about: …<br>
          <b>4</b>&ensp;That brings me to the # of ….<br>
        </div>
        
        `,
        answer: ["concerned", "now", "else", "subject"],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E6",
    exerciseKey: "img/FriendsPlus/Page44/Key/E6answerKey.png",
    audio: "Audios/1.36.mp3",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 700 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.36' colorimg=#b6d0b3  color='white'></headphone> Listen again. Which phrases from exercise 5 did
        you hear? Which prompt from the task in exercise 3 does
        the student not mention?
        `,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Compound adjectives</b>"],
        styleElement: { color: "#42429c", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>Answer:</b><br>
         #<br>
         #<br>
        
        `,
        answer: [
          "The student says phrases 1 and 3.",
          "The student doesn't mention the second bullet point.|The student does not mention the second bullet point.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E7",
    exerciseKey: "img/FriendsPlus/Page44/Key/E7answerKey.png",
    recorder: true,
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Read the <span style='color:#009147'>Reading Strategy 2</span>. How could you
        describe the things below if you didn’t know or couldn’t
        remember the words?
        `,
        color: "black",
        left: 0,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 70,
      paddingTop: 10,
    },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <img src="img/FriendsPlus/Page44/E7/1.jpg" style='height: 9.5cm'/><br>
            <div style='line-height:50px'>
              <b>1</b>&ensp;A party hat <br>
              <b>2</b>&ensp;A bow tie <br>
              <b>3</b>&ensp;Icing<br>
              <b>4</b>&ensp;A waistcoat<br>
          </div>
        </div>
        `,
        answer: ["Am", "Br", "Am", "Am", "Br", "Br"],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E8",
    exerciseKey: "img/FriendsPlus/Page44/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Read the task below, including the three
        question prompts. Choose a different festival each and
        plan what you are going to talk about for each prompt.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page44/E8/1.jpg" style='height: 15.5cm'/><br>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P44-E9",
    exerciseKey: "img/FriendsPlus/Page44/Key/E9answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Take it in turns to do the task in exercise 8.
        Listen carefully when your partner is speaking and check
        whether all three prompts are mentioned.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page44/E8/1.jpg" style='height: 15.5cm'/><br>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
