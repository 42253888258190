import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E1",
    exerciseKey: "img/FriendsPlus/Page104/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss these questions. What do
        organisations such as Greenpeace and WWF (the World
        Wide Fund for Nature) aim to do? How do they do it?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
    <textarea id=0 rows=4></textarea>        

        `,
        answer: [
          "They aim to protect the environment by investigating issues, raising awareness, talking to governments and other people who can make a change, and educating young people so that they can help to protect the environment in the future.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E2",
    exerciseKey: "img/FriendsPlus/Page104/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Look at the task below. Which of the four ways of
        combating climate change do you personally employ?
        Give examples.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [
          //{ text: "VOCABULARY", icon: "", marginRight: 10, color: "#91268e" },
          // { text: "SPEAKING", icon: "", color: "#01a951" },
        ],
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Illnesses, injuries and symptoms</b>",
          "<s>ache</s>",
          "<s>bruise</s>",
          "<s>chest infection</s>",
        ],
        styleElement: { color: "#009345", marginRight: "30px" },
      },
    ],
    character: /[:,(]/,
    // replaceCharacter: /[:(]/g,
    hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page104/E2/1.jpg" style='height:7.5cm'/>
        `,
        // InputRong: true,
        initialValue: ["chest infection,", "bruise,", "ache,"],
        answer: [
          "chest infection, flu, virus (Also: asthma, chicken pox, gastroenteritis, heart disease, measles, mumps, tonsillitis, heart disease, malaria)",
          "bruise, cut, fracture, insect sting, nose bleed, sprain, wound (Also: graze)",
          "ache, be congested, cough, feel dizzy, feel fatigued, heartburn, feel nauseous, rash, sore throat, feel stiff, be swollen, temperature (Also: runny nose, diarrhoea)",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E3",
    exerciseKey: "img/FriendsPlus/Page104/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the phrases with the words
        below.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "KEY PHRASES ", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "another",
          "agree",
          "by",
          "chief",
          "feel",
          "how",
          "let’s",
          "mind",
          "not",
          "of",
          "opinion",
          "point",
          "right",
          "some",
          "start",
          "sure",
          "take",
          "would",
          "you",
        ],
        styleElement: { color: "#009345", marginRight: "30px" },
        width: "80%",
      },
      {
        src: ["<b>IStarting a discussion</b>"],
        styleElement: { color: "#009345", marginRight: "30px" },
      },
      {
        src: ["<b>Expressing an opinion</b>"],
        styleElement: { color: "#009345", marginRight: "30px" },
      },
      {
        src: ["<b>Adding an opinion</b>"],
        styleElement: { color: "#009345", marginRight: "30px" },
      },
      {
        src: ["<b>Agreeing and disagreeing</b>"],
        styleElement: { color: "#009345", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top:20px;border-left:5px solid rgb(15,143,73);padding-left:20px'>
          <hintbox id=1></hintbox>
            <b>1</b>&ensp;Shall we # with … ?<br>
            <b>2</b>&ensp;# begin with … , shall we?<br>
            <b>3</b>&ensp;We could start # talking about …<br>
          <hintbox id=2></hintbox>
            <b>4</b>&ensp;To my  # …<br>
            <b>5</b>&ensp;In my  # …<br>
            <b>6</b>&ensp;I’m # the opinion that …<br>
            <b>7</b>&ensp;For me, the # consideration is …<br>
            <b>8</b>&ensp;Personally, I # say that …<br>
            <b>9</b>&ensp;I # quite strongly that …<br>
          <hintbox id=3></hintbox>
            <b>10</b>&ensp;# only that, …<br>
            <b>11</b>&ensp;Yes, and # thing: …<br>
            <b>12</b>&ensp;Good  # . And I also believe that …<br>
          <hintbox id=3></hintbox>
            <b>13</b>&ensp;Absolutely. I couldn’t # more.<br>
            <b>14</b>&ensp;I’m with # on that.<br>
            <b>15</b>&ensp;Yes, I think you’re # .<br>
            <b>16</b>&ensp;That’s true to # extent. However, …<br>
            <b>17</b>&ensp;I’m not # I agree.<br>
            <b>18</b>&ensp;I # a rather different view on that.<br>
            <b>19</b>&ensp;That’s not quite # I see it.<br>
        </div>
        `,
        answer: [
          "start",
          "Let's",
          "by",
          "mind",
          "opinion",
          "of",
          "chief",
          "would",
          "feel",
          "Not",
          "nother",
          "point",
          "agree",
          "you",
          "right",
          "some",
          "sure",
          "take",
          "how",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E4",
    exerciseKey: "img/FriendsPlus/Page104/Key/E4answerKey.png",
    recorder: true,
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 600,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Work in pairs. Take turns to do the task. Use
        the phrases from exercise 3 to help you.
        `,
        color: "black",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 800,
      // textAlign: "center",
    },

    // character: ",",
    component: T6,
    questions: [
      {
        title: `
        <div style='margin-top:20px;border-left:5px solid rgb(15,143,73);padding-left:20px'>
          <hintbox id=1></hintbox>
            <b>1</b>&ensp;Shall we start with … ?<br>
            <b>2</b>&ensp;Let's begin with … , shall we?<br>
            <b>3</b>&ensp;We could start by talking about …<br>
          <hintbox id=2></hintbox>
            <b>4</b>&ensp;To my  mind …<br>
            <b>5</b>&ensp;In my  opinion …<br>
            <b>6</b>&ensp;I’m of the opinion that …<br>
            <b>7</b>&ensp;For me, the chief consideration is …<br>
            <b>8</b>&ensp;Personally, I would say that …<br>
            <b>9</b>&ensp;I feel quite strongly that …<br>
          <hintbox id=3></hintbox>
            <b>10</b>&ensp;Not only that, …<br>
            <b>11</b>&ensp;Yes, and nother thing: …<br>
            <b>12</b>&ensp;Good  point . And I also believe that …<br>
          <hintbox id=3></hintbox>
            <b>13</b>&ensp;Absolutely. I couldn’t agree more.<br>
            <b>14</b>&ensp;I’m with you on that.<br>
            <b>15</b>&ensp;Yes, I think you’re right .<br>
            <b>16</b>&ensp;That’s true to some extent. However, …<br>
            <b>17</b>&ensp;I’m not sure I agree.<br>
            <b>18</b>&ensp;I take a rather different view on that.<br>
            <b>19</b>&ensp;That’s not quite how I see it.<br>
        </div>
        `,
        answer: [
          "The doctor says she has a virus.",
          "The speakers use cough, sore throat, ache (verb, not noun), temperature, feel dizzy and be swollen.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E5",
    exerciseKey: "img/FriendsPlus/Page104/Key/E5answerKey.png",
    audio: "Audios/3.21.mp3",
    // character:",",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='3.21' colorimg=#b6d5b9  color='white'></headphone> Listen to two students doing the task. Answer
        the questions below. Compare your ideas in exercise 2.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Treatments and remedies</b>",
          "antacid",
          "antibiotics",
          "antihistamine",
          "anti-inflammatories",
          "antiseptic cream",
          "bandage",
          "cough medicine",
          "lots of liquids",
          "painkillers",
          "rest",
          "tablets",
          "throat sweets",
          "X-ray",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
      {
        src: ["You could treat a sore throat with …"],
        styleElement: { color: "#6da6b5", marginRight: "30px" },
      },
    ],
    character: /[/]/,
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='position:relative'>
            <b>1</b>&ensp;Which measures do the students think will be most effective?<textarea id=1 rows=4></textarea>
            <b>2</b>&ensp;What were their reasons?<textarea id=2 rows=4></textarea>
            <b>3</b>&ensp;Do you agree with any of their opinions?<textarea id=3 rows=4></textarea>
            <div style='position:absolute;top:241px;left:408px'><input maxlength=1 padding=0px height=27px font-size=16px font-weight=bold width=18px height=40px font-size=14px> </input></div>
        </div>
        
        `,
        // initialValue: ["You could treat a sore throat with"],
        answer: [
          "2",
          "using public transport more often, saving electricity at home, recycling",
          "the less we use our cars, the less CO we pump into the atmosphere. / Generating electricity with fossil-based fuels causes a lot of global warming, which leads to climate change. / The more glass and metal we recycle, the better.",
          "",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E6",
    exerciseKey: "img/FriendsPlus/Page104/Key/E6answerKey.png",
    audio: "Audios/3.22.mp3",
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='3.22' colorimg=#b6d5b9  color='white'></headphone> Listen to the students doing the second part of
        the task. Which two measures do they choose? `,
        color: "black",
        width: "28cm",
        left: 40,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 600,
    },
    // character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <b>Answer:</b><textarea id=0 rows=2></textarea>
        </div>
        
        `,
        answer: [
          "using public transport more often, saving electricity at home",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E7",
    exerciseKey: "img/FriendsPlus/Page104/Key/E7answerKey.png",
    audio: "Audios/3.22.mp3",
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='3.22' colorimg=#b6d5b9  color='white'></headphone> Read the <span style='color:#01a951' >Speaking Strategy</span>. and
        check the meaning of the phrases below. Then listen
        again. Tick ✓ the phrases that the students use.
        `,
        color: "black",
        left: -30,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 500,
      // textAlign: "center",
    },
    hintBox: [
      {
        src: ["<b>Involving your partner</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Interrupting</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Reaching an agreement</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          //   padding: "0px 7px",
          color: "white",
          //   border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          //   padding: "0px 7px",
          //   borderRadius: "50%",
          color: "black",
          //   border: "2px solid",
          //   borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [`✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`],
        answers: ["0-0", "1-0", "2-0", "5-0", "7-0", "8-0", "11-0"],
        initialValue: [],
      },
      Layout: `
      <div style=' display:flex;margin-top:20px;border-left:5px solid rgb(15,143,73);padding-left:20px'>
          <div style=' line-height:42px;'>
            <hintbox id=0></hintbox>
              Would you agree with that?<br>
              … , don’t you think?<br>
              Can we agree that … ?<br>
              What’s your view on that?<br>
              What do you think about … ?<br>
            <hintbox id=1></hintbox>
              Sorry to interrupt, but …<br>
              Hang on a moment, I think …<br>
              Not only that, …<br>
            <hintbox id=2></hintbox>
              So, shall we reach a decision?<br>
              We need to come a decision.<br>
              We need to decide what / which …<br>
              That’s settled, then.<br>
          </div>
          <div style='margin-left:30px'>
            <div>
                <br>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=0 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=1 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=2 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=3 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=4 type=Circle /></div>
            </div>
            <div>
                <br>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=5 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=6 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=7 type=Circle /></div>
            </div>
            <div>
                <br>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=8 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=9 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=10 type=Circle /></div>
                    <div style='margin-bottom:5px;color:white; width:1cm;height:1cm;border-radius:5px;border:3px solid black;text-align:center'><input id=11 type=Circle /></div>
            </div>
          </div>
        </div>
      
      
      `,
    },
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E8",
    exerciseKey: "img/FriendsPlus/Page104/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["didn’t", "mean", "mind", "same as", "something", "think"],
        styleElement: { color: "#01a951", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#01a951", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Do you agree with the choices the students
        made? Why? / Why not?`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },

    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        `,
        answer: ["think", "didn't", "mind", "mean", "something", "same as"],
      },
    ],
  },
  9: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E9",
    exerciseKey: "img/FriendsPlus/Page104/Key/E9answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss how using less water
        can help to combat climate change.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    questions: [
      {
        title: `
        
      
        `,
        answer: [
          "The treatments discussed are painkillers that are also anti-inflammatories.",
          "The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E10",
    exerciseKey: "img/FriendsPlus/Page104/Key/E10answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Read the task below. Make notes on each section
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    questions: [
      {
        title: `
      <img src="img/FriendsPlus/Page104/E10/1.jpg" style='height:8cm'/>
      <textarea id=0 rows=10></textarea>
        `,
        answer: [
          "The treatments discussed are painkillers that are also anti-inflammatories.",
          "The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
  11: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E11",
    exerciseKey: "img/FriendsPlus/Page104/Key/E11answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "11",
        title: `Work in pairs. Take turns to be the patient and
        the doctor. Remember to ask for clarification if you are
        unsure about something
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    questions: [
      {
        title: `
        
      
        `,
        answer: [
          "The treatments discussed are painkillers that are also anti-inflammatories.",
          "The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
  12: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P104-E12",
    exerciseKey: "img/FriendsPlus/Page104/Key/E12answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "12",
        title: `Work in pairs. Discuss this quote. Say to what
        extent you believe it is true, and why.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    questions: [
      {
        title: `
        
        <img src="img/FriendsPlus/Page104/E12/1.jpg" style='height:1.2cm'/>
        `,
        answer: [
          "The treatments discussed are painkillers that are also anti-inflammatories.",
          "The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
};

export default json;
