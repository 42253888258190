import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P26-E1",
    exerciseKey: "img/FriendsPlus/Page26/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Do you think you are luckier or unluckier than
        other people? What is the luckiest or unluckiest thing
        that has happened to you or someone you know?`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P26-E2",
    exerciseKey: "img/FriendsPlus/Page26/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `What are the rules for forming a) short and long
        comparative adjectives and b) short and long
        superlative adjectives?
        `,
        color: "black",
        left: 50,
        width: "100%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        `,
        InputRong: true,
        answer: [
          "anxious|frustrated|miserable|stressed",
          "frustrated|irritated||",
          "anxious|frustrated|miserable|stressed",
          "content|thrilled|upbeat|",
          "bitter|disappointed|envious|",
          "frustrated|irritated||",
          "anxious|frustrated|miserable|stressed",
          "content|thrilled|upbeat|",
          "bitter|disappointed|envious|",
          "frustrated|irritated||",
          "anxious|frustrated|miserable|stressed",
          "content|thrilled|upbeat|",
          "bitter|disappointed|envious|",
          "frustrated|irritated||",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P26-E3",
    exerciseKey: "img/FriendsPlus/Page26/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `Underline the correct words to complete the text. `,
        color: "black",
        left: -50,
        width: "",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // dontChangeColor: true,
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid ",
          color: "black",
          // borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["poorest / most_poor", "as / than", "most / more"],
        answers: ["0-0", "1-4", "2-0"],
        initialValue: [],
      },
      Layout: `
      <div style='width:80%'>
        If you were Costis Mitsotakis, you might consider yourself
        the world’s unluckiest man. Costis was the only person in his
        village not to buy a ticket in the Spanish Christmas lottery.
        When they won first prize, everyone except Costis received
        a share of the total prize of nearly £600 million, so he was
        the <sup>1</sup><input id=0 type='Circle'/>  person in his village that Christmas.
        But there are other contenders. For example, US park ranger
        Roy Sullivan was even unluckier <sup>2</sup><input id=1 type='Circle'/>  Costis. He was
        struck by lightning seven times in his lifetime – the world
        record! However, that’s nothing like as bad as Britain’s John
        Lyne, who is perhaps the <sup>3</sup><input id=2 type='Circle'/>  unfortunate of all.
        At eighteen months old, he accidentally drank disinfectant
        and had to have his stomach pumped. And the older he
        got, the worse things became. He’s been run over, nearly
        drowned and had a car crash. John’s life has been more
        accident-filled than you would think possible. But he doesn’t
        think he’s unlucky. As far as he’s concerned, most of his
        accidents could have ended a lot more seriously, so he may
        actually be the world’s luckiest man!
      </div>
     
      `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P26-E4",
    exerciseKey: "img/FriendsPlus/Page26/Key/E4answerKey.png",
    textareaStyle: {
      resize: "none",
      width: 450,
      paddingTop: 1,
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find examples of rules
        (1–3) in the text in exercise 3.
        `,
        color: "black",
        width: "28.5cm",
        left: -50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <img src="img/FriendsPlus/Page26/E3/1.jpg" style='margin-left:-100px;height:22.5cm'/>
            <div>
              <img src="img/FriendsPlus/Page26/E4/1.jpg" style='height:11.5cm'/>
              <div style='margin-left:50px'>
                <b>Answer:</b>
                <div style='display:flex'><b>1</b><textarea id=0 rows=2></textarea></div>
                <div style='display:flex'><b>2</b><textarea id=1 rows=2></textarea></div>
                <div style='display:flex'><b>3</b><textarea id=2 rows=2></textarea></div>
              </div>
            </div>
        </div>
        
        `,
        // InputRong: true,
        answer: [
          "most of his accidents could have ended a lot more seriously",
          "And the older he got, the worse things became.",
          "John’s life has been more accident-filled than you would think possible.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P26-E5",
    exerciseKey: "img/FriendsPlus/Page26/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with a comparative, using the
        words in brackets.
        `,
        color: "black",
        left: 50,
        width: "25cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 210, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Idioms to describe feelings</b>"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;His journey was # than he’d expected. (dangerous)<br>
          <b>2</b>&ensp;The more you worry, # the test will seem. (scary)<br>
          <b>3</b>&ensp;The # it got, the # his parents grew. (late / concerned)<br>
          <b>4</b>&ensp;Trains usually go # but the bus costs less. (quick)<br>
          <b>5</b>&ensp;Lena’s # than she used to be – nowadays she always looks on the dark side. (optimistic)<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "more dangerous",
          "the scarier",
          "later",
          "more concerned",
          "more quickly",
          "less optimistic",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P26-E6",
    exerciseKey: "img/FriendsPlus/Page26/Key/E6answerKey.png",

    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box and find examples of points 1 and 2 in the text in exercise 3. 
        Then underline the correct words to complete facts (1-5).
         `,
        color: "black",
        width: "28cm",
        left: -50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // dontChangeColor: true,
        initialWordStyle: {
          // padding: "0px 7px",
          border: "2px solid ",
          color: "black",
          borderColor: "white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "almost / nowhere_near",
          "a_little / a_lot_more",
          "slightly / far",
          "just / nothing_like",
          "much / a_little",
          `If_you_were_Costis_Mitsotakis,_you_might_consider_yourself_the_world’s_unluckiest_man. Costis_was_the_only_person_in_his_village_not_to_buy_a_ticket_in_the_Spanish_Christmas_lottery.
When_they_won_first_prize,_everyone_except_Costis_received_a_share_of_the_total_prize_of_nearly_£600_million,_so_he_was_the_poorest_person_in_his_village_that_Christmas.
But_there_are_other_contenders. For_example, US_park_ranger_Roy_Sullivan_was_even_unluckier_than_Costis. He_was_struck_by_lightning_seven_times_in_his_lifetime_–_the_world_record! 
However,_that’s_nothing_like_as_bad_as_Britain’s_John_Lyne,_who_is_perhaps_the_most__unfortunate_of_all.
At_eighteen_months_old,_he_accidentally_drank_disinfectant
and_had_to_have_his_stomach_pumped. And_the_older_he_got,_the_worse_things_became. He’s_been_run_over,_nearly_drowned_and_had_a_car_crash. John’s_life_has_been_more
accident-filled_than_you_would_think_possible. But_he_doesn’t_think_he’s_unlucky. 
As_far_as_he’s_concerned, most_of_his_accidents_could_have_ended_a_lot_more_seriously, 
so_he_may_actually_be_the_world’s_luckiest_man!`,
          "much / a_little",
        ],
        answers: ["5-10", "5-36", "0-4", "1-4", "2-4", "3-4", "4-4", "5-16"],
        initialValue: [],
      },
      Layout: `
      <div>
        <input id='5' select='underlineText' type='Circle' />
      </div>
        
      <div style='line-height:50px'>
          <b>1</b>&ensp;£100 million is <b><input id=0 type='Circle' /></b> as much as €300 million.<br>
          <b>2</b>&ensp;Gold is <b><input id=1 type='Circle' /></b> expensive than steel.<br>
          <b>3</b>&ensp;The Pacific Ocean is <b><input id=2 type='Circle' /></b> bigger than the Arctic Ocean.<br>
          <b>4</b>&ensp;Spain’s population is <b><input id=3 type='Circle' /></b> as big as China’s.<br>
          <b>5</b>&ensp;Venus is <b><input id=4 type='Circle' /></b> smaller than Earth. <br>
      </div>
     
      `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P26-E7",
    exerciseKey: "img/FriendsPlus/Page26/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:#91268e' >USE OF ENGLISH </span> Complete the second sentence so that it
        has a similar meaning to the first. Use the words in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 360, paddingTop: 10 },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
              <b>1</b>
            <div style='margin-left:15px'>
              Maths is far more difficult than history. (nowhere near)<br>
              History # maths.
            </div>
        </div>
        <div style='display:flex'>
              <b>2</b>
            <div style='margin-left:15px'>
              Cars are nowhere near as safe as planes. (much)<br>
              Planes # cars.
            </div>
        </div>
        <div style='display:flex'>
              <b>3</b>
            <div style='margin-left:15px'>
              Health is a lot more important than money. (nothing like)<br>
              Money is # health.
            </div>
        </div>
        <div style='display:flex'>
              <b>4</b>
            <div style='margin-left:15px'>
              Facebook didn’t use to be so popular. (than)<br>
              Facebook is # used to be.
            </div>
        </div>
        <div style='display:flex'>
              <b>5</b>
            <div style='margin-left:15px'>
              You don’t need as much money when you’re young. (older)<br>
              The # money you need.
            </div>
        </div>
        `,
        answer: [
          "is nowhere near as difficult as",
          "are much safer than",
          "nothing like as important as",
          "more popular than it",
          "older you get, the more",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P26-E8",
    exerciseKey: "img/FriendsPlus/Page26/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss whether you agree or
        disagree with the statements in exercise 7. Give reasons.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
