import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P53-E1",
    exerciseKey: "img/FriendsPlus/Page53/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Read the travel blog and discuss the questions.
        Give reasons for your answers.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
          <b>1</b>&ensp;Would you like to go InterRailing?<br>
          <b>2</b>&ensp;Which type of pass would you prefer?<br>
          <b>3</b>&ensp;Where would you go?<br>
        </div>
        <img src="img/FriendsPlus/Page53/E1/1.jpg" style='height:22cm'/><br>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P53-E2",
    exerciseKey: "img/FriendsPlus/Page53/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Then find an example of each
        rule in exercise 1.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 750, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    character: ",",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page53/E2/1.jpg" style='height:16cm'/><br>
        <div style='margin:10px 0px'>
          <b>1</b>&ensp;#<br>
          <b>2</b>&ensp;#<br>
          <b>3</b>&ensp;#
        </div>
        <img src="img/FriendsPlus/Page53/E1/1.jpg" style='height:22cm'/><br>
        
        
        `,
        // InputRong: true,
        answer: [
          "weekly (budget) - adjective, (book) early - adverb",
          "(at a more) leisurely (pace) - adjective",
          "(so think) hard - adverb, (You're) hardly (going to need) - adverb",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P53-E3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the response to the blog in exercise 1. Use
        each word once to form an adverb or adverbial phrase.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["closely", "daily", "early", "friendly", "late", "quick"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 120, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:17cm;box-shadow:2px 5px 4px gray;border:4px solid rgb(252,193,91);padding: 10px 20px;'>
          Great tips – thanks, Laura! I’d also add that it’s worth
          booking accommodation <sup>1</sup># as you may get a discount.
          Check travel websites <sup>2</sup># too, as the special offers don’t
          last for very long. And watch your luggage <sup>3</sup># if you’re
          travelling by train – there have been several thefts reported
          <br><sup>4</sup># . Finally, in each country it’s worth <br><sup>5</sup># learning
          a few local phrases as soon as you arrive – you’ll find the
          locals treat you in a more <br><sup>6</sup># way!
        </div>
        `,
        answer: ["early", "daily", "closely", "lately", "quickly", "friendly"],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P53-E4",
    exerciseKey: "img/FriendsPlus/Page53/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the email with the correct form of the verbs below.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -20,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 100, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `deep / deeply`,
          `deep / deeply`,
          `rough / roughly`,
          `rough / roughly`,
          `pretty / prettily`,
          `pretty / prettily`,
          `near / nearly`,
          `near / nearly`,
          `direct / directly`,
          `direct / directly`,
        ],
        answers: [
          "0-0",
          "1-4",
          "2-4",
          "3-0",
          "4-0",
          "5-4",
          "6-0",
          "7-4",
          "8-0",
          "9-4",
        ],
        initialValue: [],
      },
      Layout: `
      <div>
    <div style='display:flex'>
        <b>1</b>&ensp;
        <div style='margin-left:15px'>
          <b>a</b>&ensp;The journey took us <input id=0 type=Circle /> into the country.<br>
        <b>b</b>&ensp;I was <input id=1 type=Circle /> shocked by the cost of the ticket.
        </div>
      </div>
      <div style='display:flex'>
        <b>2</b>&ensp;
        <div style='margin-left:15px'>
          <b>a</b>&ensp;It’s <input id=2 type=Circle /> five kilometres to the next village.<br>
        <b>b</b>&ensp;The sight of homeless people sleeping <input id=3 type=Circle /> makes me really sad.
        </div>
      </div>
      <div style='display:flex'>
        <b>3</b>&ensp;
        <div style='margin-left:15px'>
          <b>a</b>&ensp;We should get a <input id=4 type=Circle /> good deal at the hostel.<br>
        <b>b</b>&ensp;You need to dress comfortably, not <input id=5 type=Circle />!
        </div>
      </div>
      <div style='display:flex'>
        <b>4</b>&ensp;
        <div style='margin-left:15px'>
          <b>a</b>&ensp;They stayed somewhere <input id=6 type=Circle />.<br>
        <b>b</b>&ensp;We’ve <input id=7 type=Circle /> reached the end of our trip.
        </div>
      </div>
      <div style='display:flex'>
        <b>5</b>&ensp;
        <div style='margin-left:15px'>
          <b>a</b>&ensp;Which flight goes <input id=8 type=Circle /> to Hai Phong?<br>
        <b>b</b>&ensp;The receptionist will be with you <input id=9 type=Circle />.
        </div>
      </div>
      </div>

      
      
      `,
    },
  },
  5: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P53-E5",
    exerciseKey: "img/FriendsPlus/Page53/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the adverbs and adverbial
        phrases below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 100,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["daily", "hard", "lately", "pretty", "rough"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:47px'>
          <b>1</b>&ensp;We’ve been driving for hours, so we must be # near.<br>
          <b>2</b>&ensp;Tina updates her Facebook page # .<br>
          <b>3</b>&ensp;If you look #  , you can see me at the back of the photo.<br>
          <b>4</b>&ensp;You’ve been so busy #  ; you really should take a break.<br>
          <b>5</b>&ensp;I lost my money and had to sleep # for two nights.<br>
        </div>

        `,
        answer: ["pretty", "daily", "hard", "lately", "rough"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P53-E6",
    exerciseKey: "img/FriendsPlus/Page53/Key/E6answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Think of an appropriate way to finish each
        sentence. Then compare your ideas with your partner’s.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
          <b>1</b>&ensp;something you have / haven’t done lately.<br>
          <b>2</b>&ensp;two things you do weekly.<br>
          <b>3</b>&ensp;a film which deeply affected you.<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P53-E7",
    exerciseKey: "img/FriendsPlus/Page53/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page53/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P53-E8",
    exerciseKey: "img/FriendsPlus/Page53/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page53/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
