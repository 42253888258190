import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P64-E1",
    exerciseKey: "img/FriendsPlus/Page64/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Read the text. What is unusual about Akrit Jaswal?`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { marginBottom: -23, width: 510, paddingTop: 10 },
    textareaStyle: {
      width: 510,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'><b>Answer:</b><textarea id=0 rows=2></textarea></div>
        <img src="img/FriendsPlus/Page64/E1/1.jpg" style='height:19cm'/><br>
        

        `,
        answer: [
          "He is extremely intelligent and he did unusual things at a very young age.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P64-E2",
    exerciseKey: "img/FriendsPlus/Page64/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find all the examples of
        used to and would in the text.
        `,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 450, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 100, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [
          `Physician_Akrit_Jaswal_had_a_very_unusual_childhood. 
He_didn’t_use_to_play with_toys_like_most_children. 
Instead,_he_used_to_read_a_lot – he_could_read_and_write_from_the_age_of_two. 
And_what_did_he_use_to_read_as_a_five-year-old_boy? 
Shakespeare! He_also_had_a_passion_for_science_and_would_read_everything he_could_find_about_human_anatomy. 
He_performed_his_first_operation_at_the_age_of_seven,_treating_the_badly_burned_hands_of_a_young_girl_whose_family_couldn’t_afford_medical_care. 
This_operation_was_filmed_and_posted_on_YouTube_–_leading_to_worldwide_fame_for_Akrit. 
At_the_age_of_twelve,_he_was_accepted_into_a_medical_university. 
He_studied_there_for_several_years. 
He_was_the_youngest_ever_to_achieve_this_in_India_at_that_time ...`,
        ],
        answers: ["0-4", "0-10", "0-18", "0-24"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page64/E2/1.jpg" style='height:16cm'/><br>
        <div style='width:27cm'>
            <input id=0 type=Circle />
        </div>
      `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P64-E3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the correct form of <i>used to</i>
        or <i>would</i>. In which sentences is either possible?`,
        color: "black",
        left: 30,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 340, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
          <b>1</b>&ensp;He#(work) as a journalist for a science magazine.<br>
          <b>2</b>&ensp;Years ago, small children#(clean) chimneys to earn money.<br>
          <b>3</b>&ensp;That athlete#(have) difficulties getting picked for the team.<br>
          <b>4</b>&ensp;#(she / wear) uniform in her previous job?<br>
        </div>
        `,
        answer: [
          "used to work",
          "used to clean / would clean",
          "used to have",
          "Did she use to wear",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P64-E4",
    exerciseKey: "img/FriendsPlus/Page64/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Decide if the highlighted words in the text below
        are correct or incorrect. Correct any mistakes in your
        notebook using the correct form of <i>used to</i> or <i>would</i>, or
        the past simple where neither is possible.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
        <img src="img/FriendsPlus/Page64/E4/1.jpg" style='height:12cm'/>
          <div style='margin-left:10px'>
            <b>1</b>#<br>
            <b>2</b>#<br>
            <b>3</b>#<br>
            <b>4</b>#<br>
            <b>5</b>#<br>
            <b>6</b>#<br>
            <b>7</b>#<br>
          </div>
        </div>
        
        `,
        answer: [
          "correct",
          "correct",
          "used to want",
          "correct",
          "sent",
          "was",
          "correct",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P64-E5",
    exerciseKey: "img/FriendsPlus/Page64/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the
        correct form of the words in brackets. Do not change the
        order of the words. You can add up to three other words.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 300,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "in three hours from now",
          "at midnight tonight",
          "in three months’ time",
          "by this time next year",
          "by your 20th birthday",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm; line-height:50px'>
          <b>1</b>&ensp;Gary#(used / want) be a vet.<br>
          <b>2</b>&ensp;When you were younger, you#(use / have) a dream job?<br>
          <b>3</b>&ensp;Years ago, people#(not / use) want to become doctor as much as they do now.<br>
          <b>4</b>&ensp;He was such a lazy technician that he#(would / do) his work an hour before the deadline.<br>
        </div>

        `,
        answer: [
          "used to want to",
          "did you use to have",
          "did not use to|didn't use to",
          "he would do",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P64-E6",
    exerciseKey: "img/FriendsPlus/Page64/Key/E6answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Think about when you were
        five or six years old. Talk to your partner about these
        things. Use <i>used to</i> or <i>would</i>.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm; line-height:50px'>
          <b>1</b>&ensp;Favourite toys you played with<br>
          <b>2</b>&ensp;Friends you had<br>
          <b>3</b>&ensp;Books you read<br>
          <b>4</b>&ensp;Other things you enjoyed<br>
        </div>
        <img src="img/FriendsPlus/Page64/E6/1.jpg" style='height:2.4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P64-E7",
    exerciseKey: "img/FriendsPlus/Page64/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page64/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P64-E8",
    exerciseKey: "img/FriendsPlus/Page64/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page64/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
