import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E1",
    exerciseKey: "img/FriendsPlus/Page70/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Look at the photo. What are the people
        doing? Would you like to be an actor? Why? / Why not?`,
        color: "black",
        width: "26cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page70/E1/1.jpg" style='height:9cm'/><br>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E2",
    exerciseKey: "img/FriendsPlus/Page70/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. What three things does it mention that a
        RADA student learns? `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    textareaStyle: {
      width: "25cm",
      paddingTop: 2,
      resize: "none",
      // marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    // hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=3></textarea>
        <div style='display:flex;width:25cm'>
          <b>A</b>
          <div style='margin-left:15px'>
            Becoming a truly great actor requires talent, hard
            work and hours of study. In Britain, the Royal
            Academy of Dramatic Art (RADA) <sup>1</sup>_____ trained and produced many of the country’s most
            famous actors since it was established in 1904, but
            just how does RADA turn passionate young hopefuls
            into respected professionals?
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b>B</b>
          <div style='margin-left:15px'>
            One of the methods RADA teaches is the Stanislavski
            acting technique. The technique was devised
            <sup>2</sup>_____ the famous Russian actor Konstantin
            Stanislavski in 1911, after he had been performing in
            theatres <sup>3</sup>_____ over thirty years. It requires
            actors to really live the role that they are playing. The
            actors not <sup>4</sup>_____ have to learn the lines that
            they have to say, but also recreate everything about
            the character’s life.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b>C</b>
          <div style='margin-left:15px'>
              But if modern audiences would be surprised to
              discover that students learn an acting technique
              that is over a century old, they might 5____
              astonished to discover that they also study a form of
              theatre that has been around for over two millennia:
              Greek tragedy. The Greeks used to study playwriting
              and acting and produced some important theoretical
              works on the subject. Their ideas are still considered
              very relevant today and anybody <sup>6</sup>_____ wants
              to obtain a degree from RADA has to study them. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b>D</b>
          <div style='margin-left:15px'>
              RADA students also spend many hours training their
              voices and learning <sup>7</sup>_____ use their bodies
              to communicate in the same way that a musician
              learns how to play an instrument. A RADA student’s
              objective is to use their creativity to transform written
              words into a three-dimensional character. Evidently,
              becoming an actor is <sup>8</sup>_____ serious business
              and definitely not for people who are only interested
              in fame.
          </div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "They learn the Stanislavski acting technique, i.e. how to recreate everything about a character's life. They train their voices and they learn how to use their bodies",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E3",
    exerciseKey: "img/FriendsPlus/Page70/Key/E3answerKey.png",
    // checkDuplicated: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the text about RADA. Write
        one word in each gap.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "boredom",
          "comfort",
          "convenience",
          "cost",
          "luggage",
          "safety",
          "time",
          "travel sickness",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 80, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex;width:25cm'>
          <b>A</b>
          <div style='margin-left:15px'>
            Becoming a truly great actor requires talent, hard
            work and hours of study. In Britain, the Royal
            Academy of Dramatic Art (RADA) <sup>1</sup># trained and produced many of the country’s most
            famous actors since it was established in 1904, but
            just how does RADA turn passionate young hopefuls
            into respected professionals?
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b>B</b>
          <div style='margin-left:15px'>
            One of the methods RADA teaches is the Stanislavski
            acting technique. The technique was devised
            <sup>2</sup># the famous Russian actor Konstantin
            Stanislavski in 1911, after he had been performing in
            theatres <sup>3</sup># over thirty years. It requires
            actors to really live the role that they are playing. The
            actors not <sup>4</sup># have to learn the lines that
            they have to say, but also recreate everything about
            the character’s life.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b>C</b>
          <div style='margin-left:15px'>
              But if modern audiences would be surprised to
              discover that students learn an acting technique
              that is over a century old, they might <sup>5</sup>#               astonished to discover that they also study a form of
              theatre that has been around for over two millennia:
              Greek tragedy. The Greeks used to study playwriting
              and acting and produced some important theoretical
              works on the subject. Their ideas are still considered
              very relevant today and anybody <sup>6</sup># wants
              to obtain a degree from RADA has to study them. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b>D</b>
          <div style='margin-left:15px'>
              RADA students also spend many hours training their
              voices and learning <sup>7</sup># use their bodies
              to communicate in the same way that a musician
              learns how to play an instrument. A RADA student’s
              objective is to use their creativity to transform written
              words into a three-dimensional character. Evidently,
              becoming an actor is <sup>8</sup># serious business
              and definitely not for people who are only interested
              in fame.
          </div>
        </div>
        `,
        answer: ["has", "by", "for", "only", "be", "who", "to", "a"],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E4",
    exerciseKey: "img/FriendsPlus/Page70/Key/E4answerKey.png",
    audio: "Audios/2.16.mp3",
    textareaStyle: {
      marginBottom: -23,
      // resize: "none",
      width: 570,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='2.16' colorimg=#b6d5b9  color='white'></headphone> Listen to an interview about how actors
        prepare for their roles. Match the people (1–3) with the
        things that they did (A–C).
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -23,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 180,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "321px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "471px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "75px",
              left: "321px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "75px",
              left: "471px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "321px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "471px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["0-3", "2-5", "1-4"],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [``],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <input id=0 type=boxMatch />
      <input id=1 type=boxMatch />
      <input id=2 type=boxMatch />
      <input id=3 type=boxMatch />
      <input id=4 type=boxMatch />
      <input id=5 type=boxMatch />
      <div style='line-height:50px;display:flex'>
        <div>
          <b>1</b>&ensp;Konstantin Stanislavski<br>
          <b>2</b>&ensp;Meryl Streep<br>
          <b>3</b>&ensp;Leonardo DiCaprio<br>
        </div>
        <div style='margin-left:200px'>
          <b>A</b>&ensp;studied psychiatric patients.<br>
          <b>B</b>&ensp;prepared a list of questions.<br>
          <b>C</b>&ensp;prepared for a film about the Second World War.<br>
        </div>
      </div>
      
      

      `,
    },
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E5",
    exerciseKey: "img/FriendsPlus/Page70/Key/E5answerKey.png",
    audio: "Audios/2.16.mp3",
    // character:",",
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='2.16' colorimg=#b6d5b9  color='white'></headphone> Listen again. Are the sentences true or false?
        Write T or F. `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 950,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "do not have problems",
          "got",
          "happen",
          "looked at",
          "really think",
          "said",
          "she didn’t say sorry",
          "showed her",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:21cm'>
          <b>1</b>&ensp;Copying certain emotions is not so difficult for actors.<input id=6 width=100px text-align=center /><textarea id=6 rows=2></textarea>
          <b>2</b>&ensp;The Stanislavski system involves actors asking themselves questions about their own acting ability.<input id=7 width=100px text-align=center /><textarea id=7 rows=2></textarea>
          <b>3</b>&ensp;Meryl Streep studied a foreign language.<input id=8 width=100px text-align=center /><textarea id=8 rows=2></textarea>
          <b>4</b>&ensp;Meryl Streep wanted to read poetry in a foreign language.<input id=9 width=100px text-align=center /><textarea id=9 rows=2></textarea>
          <b>5</b>&ensp;Leonardo DiCaprio met people who had had experiences that he wanted to recreate in a film.<input id=10 width=100px text-align=center /><textarea id=10 rows=2></textarea>
          <b>6</b>&ensp;Leonardo DiCaprio visited a psychiatric hospital on an island to prepare for a film.<input id=11 width=100px text-align=center /><textarea id=11 rows=2></textarea>
        </div>
        `,
        InputRong: true,
        answer: [
          "T",
          "F",
          "T",
          "F",
          "T",
          "F",
          "",
          "It involved asking questions about their character.",
          "",
          "She would walk around her house reading poems in a Polish accent.",
          "",
          "He visited a psychiatric hospital to prepare for a film about a psychiatric hospital on an island.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E6",
    exerciseKey: "img/FriendsPlus/Page70/Key/E6answerKey.png",
    recorder: true,
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Look at the picture and discuss
        the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 600,
    },
    // character: ",",
    hideBtnFooter: true,

    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
          <b>1</b>&ensp;Can you name the arts form in the brochure? Give a short presentation on its history.
          <b>2</b>&ensp;How has the Đồng Ấu Bạch Long group contributed to the development of the arts form?
        </div>
        <img src="img/FriendsPlus/Page70/E6/1.jpg" style='height:9cm'/>
        `,
        answer: [
          `He is proud of his culture. He knows a lot about it and speaks about it proudly.`,
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E7",
    exerciseKey: "img/FriendsPlus/Page70/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='2.08' colorimg=#b6d5b9  color='white'></headphone> Listen again and answer the questions.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 860,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 600,
      // textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "no food available",
          "overcrowded",
          "queues for everything",
          "shop / swimming pool, etc. was closed",
          "showers / restaurants, etc. were dirty",
          "staff were impolite / unhelpful",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;What does kia ora mean?<br>#<br>
          <b>2</b>&ensp;What does whenua refer to?<br>#<br>
          <b>3</b>&ensp;What type of holiday do a lot of tourists go on in New Zealand?<br>#<br>
          <b>4</b>&ensp;Which sport are Maoris good at?<br>#<br>
          <b>5</b>&ensp;What is a haka?<br>#<br>
        </div>
       
        
        `,
        answer: [
          "It means hello in Maori.",
          "It refers to the Maoris' connection with the land.",
          "They often go on adventure holidays.",
          "They are good at rugby.",
          "A haka is a traditional Maori war dance.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E8",
    exerciseKey: "img/FriendsPlus/Page70/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Discuss what you think about
        the customs of ‘walkabout’ and ‘Ta moko’.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
s        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P70-E9",
    exerciseKey: "img/FriendsPlus/Page70/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in groups. Find out facts about the main
        ethnic groups in northern Viet Nam, Tay and Nung. Then
        present them to the whole class.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "PROJECT", icon: "", color: "#999b9c" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
