import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E1",
    exerciseKey: "img/FriendsPlus/Page68/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Describe one photo each (A or B).
        What do you think is happening? Use the phrases below
        to help you.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page68/E1/1.jpg" style='height:6cm'/>
        <div style='margin-bottom:10px;border-left: 5px solid rgb(0,147,69); padding-left: 10px; width:27cm'>
            <hintbox id=1></hintbox>
            I can’t be sure, but …<br>
            It looks like some kind of … , or maybe a …<br>
            It looks to me like a … of some kind.<br>
            It’s / There’s a sort of …<br>
            It’s / They’re most likely a … , or something like that.<br>
            I’d say that …<br>
        </div>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E2",
    exerciseKey: "img/FriendsPlus/Page68/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:rgb(8,144,73)' >Speaking Strategy</span>. Then put the
        adjectives below into two groups: positive and negative.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [
          { text: "VOCABULARY", icon: "", marginRight: 10, color: "#91268e" },
          // { text: "SPEAKING", icon: "", color: "#01a951" },
        ],
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Extreme adjectives</b>",
          "awful",
          "delighted",
          "ecstatic",
          "exhausted",
          "fascinated",
          "hilarious",
          "miserable",
          "starving",
          "terrible",
          "terrified",
          "thrilled",
          "wonderful",
        ],
        styleElement: { color: "#009345", marginRight: "30px" },
      },
    ],
    character: ",",
    // characterNotBoth: true,
    // hideBtnFooter: true,
    pointThieuWord: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <img src="img/FriendsPlus/Page68/E2/1.jpg" style='height:5.5cm'/>
        <div style='line-height:50px'>
          <b>positive:</b>#<br>
          <b>negative:</b>#<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "delighted, ecstatic, fascinated, hilarious, thrilled, wonderful",
          "awful, exhausted, miserable, starving, terrible, terrified",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E3",
    exerciseKey: "img/FriendsPlus/Page68/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:rgb(8,144,73)' >Learn this!</span> box below. Which adverbs can be
        used to modify both ordinary and extreme adjectives?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "boredom",
          "comfort",
          "convenience",
          "cost",
          "luggage",
          "safety",
          "time",
          "travel sickness",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 10 },
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: ",",
    // characterNotBoth: true,
    pointThieuWord: true,
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page68/E3/1.jpg" style='height:6.5cm'/><br>
        <b>Answer:</b>#<br>
        `,
        answer: ["absolutely, totally"],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E4",
    exerciseKey: "img/FriendsPlus/Page68/Key/E4answerKey.png",
    audio: "Audios/2.14.mp3",
    textareaStyle: {
      marginBottom: -23,
      // resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='2.14' colorimg=#b6d5b9  color='white'></headphone> Read the task above. Then listen to a student
        doing the task. Answer the questions.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 800,
      // textAlign: "center",
    },

    character: ",",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page68/E4/1.jpg" style='height:2.5cm'/><br>
        <div style='width:27cm'>
          <b>1</b>&ensp;Which extreme adjectives does the student use? Does she use any adverbs to modify them?<br>#<br>
          <b>2</b>&ensp;Which does she emphasise more: the advantages of
          a panel interview, or the disadvantages? Do you agree
          with her opinion?<br>#<br>
        </div>
        `,
        answer: ["absolutely terrified, really fascinated", "the advantages"],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E5",
    exerciseKey: "img/FriendsPlus/Page68/Key/E5answerKey.png",
    audio: "Audios/2.14.mp3",
    // character:",",
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='2.14' colorimg=#b6d5b9  color='white'></headphone> Listen again. Which of the
        phrases below does the student use to compare the
        photos?`,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Comparing photos</b>"],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          // border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `Both_photos_show_…<br>
In_both_photos,_you_can_see_…<br>
The_common_theme_in_the_photos_is_…<br>
The_main_difference_(between_the_photos)_is_…<br>
In_the_first_photo_…_,_whereas_in_the_second_photo_…<br>
Unlike_the_first_photo,_the_second_photo_shows_…<br>
Overall,_the_second_photo_is_[comparative_form]_than_the_first_photo.<br>`,
        ],
        answers: ["0-2", "0-8", "0-6", "0-10", "0-12"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page68/E1/1.jpg" style='height:6cm'/>
      <div style='margin-bottom:10px;border-left: 5px solid rgb(0,147,69); padding-left: 10px; width:27cm'>
          <hintbox id=0></hintbox>
          <input id=0 type=Circle />
      </div>
      `,
    },
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E6",
    exerciseKey: "img/FriendsPlus/Page68/Key/E6answerKey.png",
    recorder: true,
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Find other similarities and
        differences between the photos in exercise 1. Use the
        phrases in exercise 5 to talk about them.`,
        color: "black",
        width: "28cm",
        left: 40,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: ",",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <img src="img/FriendsPlus/Page68/E1/1.jpg" style='height:6cm'/>
      <div style='margin-bottom:10px;border-left: 5px solid rgb(0,147,69); padding-left: 10px; width:27cm'>
          <hintbox id=0></hintbox>
          Both_photos_show_…<br>
          In_both_photos,_you_can_see_…<br>
          The_common_theme_in_the_photos_is_…<br>
          The_main_difference_(between_the_photos)_is_…<br>
          In_the_first_photo_…_,_whereas_in_the_second_photo_…<br>
          Unlike_the_first_photo,_the_second_photo_shows_…<br>
          Overall,_the_second_photo_is_[comparative_form]_than_the_first_photo.<br>
      </div>
        `,
        answer: ["think , HELLO", "think , HELLO", "opting", "why"],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E7",
    exerciseKey: "img/FriendsPlus/Page68/Key/E7answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Look at photos C and D and do
        the task below.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page68/E7/1.jpg" style='height:10cm'/>

        `,
        answer: [
          `I think I'll choose to travel by coach , mainly because …  
The best option would (again) be the coach.  
That's because you travel …`,
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E8",
    exerciseKey: "img/FriendsPlus/Page68/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Do the task in exercise 2 using
        the phrases in exercise 6.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page68/E2/2.jpg" style='height:3.5cm'/>
        <div style='border-left:5px solid rgb(15,143,73);padding-left:20px'>
          <hintbox id=1></hintbox>
          <b>1</b>&ensp;I think I’ll choose … , mainly because …<br>
          <b>2</b>&ensp;I’m opting for … and that’s because …<br>
          <b>3</b>&ensp;The best option would be the … because …<br>
          <b>4</b>&ensp;The reason why I’m (not) choosing the … is that …<br>
        </div>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P68-E9",
    exerciseKey: "img/FriendsPlus/Page68/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss the statement in
        exercise 8. Use the phrases below to show that you
        agree or disagree.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          That’s exactly how I feel.<br>
          I couldn’t agree more. In fact, …<br>
          I see your point, but …<br>
          That may be true to some extent. However, …<br>
          I don’t really agree that …<br>
          I’m afraid I disagree. I think …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
