import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E1",
    exerciseKey: "img/FriendsPlus/Page57/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Why do people stay in youth hostels?
        What are the advantages and disadvantages?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
       

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E2",
    exerciseKey: "img/FriendsPlus/Page57/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the task. What things do you think
        might have gone wrong? Discuss and make a list of
        possible problems.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 850, paddingTop: 10 },
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    recorder: true,
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page57/E2/1.jpg" style='height:7cm'/><br>
        <b>Answer:</b>#
        `,
        // InputRong: true,
        answer: [
          "dingy accommodation, overcrowded rooms, too much noise, bad service",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E3answerKey.png",
    // checkDuplicated: true,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the letter. Were any of the complaints similar to
        your list in exercise 2?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "boredom",
          "comfort",
          "convenience",
          "cost",
          "luggage",
          "safety",
          "time",
          "travel sickness",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page57/E3/1.jpg" style='height:21cm'/>
        `,
        answer: [
          "To travel the world on his motorbike.",
          "To travel from Europe, across Africa to the South Pole.",
          "To travel around eleven countries in 42 days with just the things he could carry in his pockets",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E4",
    exerciseKey: "img/FriendsPlus/Page57/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      // resize: "none",
      width: 570,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#2683c6' >Speaking Strategy</span>  and find examples of
        each of the four points in the letter.
        
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <img src="img/FriendsPlus/Page57/E4/1.jpg" style='height:12cm'/>
        <img src="img/FriendsPlus/Page57/E3/1.jpg" style='height:21cm'/>
        </div>
        <div style='margin-top:-340px'> 
          <div style='display:flex'><b>1</b><textarea id=0 rows=2></textarea></div>
          <div style='display:flex'><b>2</b><textarea id=1 rows=2></textarea></div>
          <div style='display:flex'><b>3</b><textarea id=2 rows=2></textarea></div>
          <div style='display:flex'><b>4</b><textarea id=3 rows=2></textarea></div>
        </div>
        `,
        answer: [
          "There are five clear paragraphs.",
          "I am writing to complain about our stay at your youth hostel from 14-16 May. I feel we deserve some compensation and I look forward to hearing from you.",
          "There was no apology at any point.",
          "Dear Sir or Madam, Yours faithfully",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E5",
    exerciseKey: "img/FriendsPlus/Page57/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Match the following words or phrases with the
        highlighted formal expressions in the letter.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 450,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "do not have problems",
          "got",
          "happen",
          "looked at",
          "really think",
          "said",
          "she didn’t say sorry",
          "showed her",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <b>Answer</b><br>
        do not have problems – #<br> 
        got – #<br> 
        happen – #<br> 
        looked at – #<br> 
        really think – #<br> 
        said – #<br> 
        she didn't say sorry – #<br> 
        showed her – #<br> 
        <img src="img/FriendsPlus/Page57/E3/1.jpg" style='margin-top:10px;height:21cm'/>
        `,
        answer: [
          `are not inconvenienced`,
          `received`,
          `arise`,
          `reviewed`,
          `feel strongly`,
          `maintained`,
          `There was no apology at any point`,
          `produced`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E6",
    exerciseKey: "img/FriendsPlus/Page57/Key/E6answerKey.png",
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#2683c6' >Learn this!</span> box. Then find two examples of
        the subjunctive form in the letter`,
        color: "black",
        width: "28cm",
        left: -20,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: ",",
    // hideBtnFooter: true,

    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `Dear_Sir_or_Madam,<br>
I_am_writing_to_complain_about_our_stay_at_your_youth_hostel_from_14–16_May.<br>
I_reserved_a_room_for_four_with_a_shower_room,_and_I_received_confirmation_of_the_booking. 
However,_when_we_arrived,_the_receptionist_maintained_there_was_no_record_of_my_reservation.<br>
I_produced_my_confirmation_slip,_but_she_just_said_that_‘something_must_have_been_wrong_with_the_website_that_day’.<br>
We_were_given_beds_in_a_fourteen-person_room_with_ten_other_people. 
There_was_one_bathroom_for_all_of_us,_which_was_dirty_with_no_toilet_paper. 
The_receptionist_took_no_notice_of_our_complaints.<br>
Then_we_were_supplied_with_no_sheets_on_the_beds! Eventually,_we_found_some_sheets_and_cleaned_the_bathrooms_ourselves.<br>
There_was_no_apology_at_any_point.<br>
I_feel_strongly_that_we_were_treated_very_poorly. 
Firstly,_I_suggest_that_your_online_booking_system_be_reviewed. 
Secondly,_your_staff_should_be_trained_to_be_more_welcoming,_and_more_helpful_when_problems_arise. 
And_finally,_I_request_that_there_be_sufficient_bed_linen,_so_that_other_guests_are_not_inconvenienced_as_we_were.<br>
I_feel_we_deserve_some_compensation_and_I_look_forward_to_hearing_from_you.<br>
Yours_faithfully,<br>
Gemma_Winters`,
        ],
        answers: ["0-32", "0-40"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page57/E6/1.jpg" style='height:9cm'/><br>
      <div style='width:25cm'>
        <input id=0 type=Circle />
      </div>
      `,
    },
  },
  7: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E7",
    exerciseKey: "img/FriendsPlus/Page57/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `Read the task above. Make notes about what you will
        complain about. Use the ideas below or your own ideas.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 860,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "no food available",
          "overcrowded",
          "queues for everything",
          "shop / swimming pool, etc. was closed",
          "showers / restaurants, etc. were dirty",
          "staff were impolite / unhelpful",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
       
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page57/E7/1.jpg" style='height:6cm'/>
       
        <textarea id=0 rows=3></textarea>
        `,
        answer: [
          `I think I'll choose to travel by coach , mainly because …  
The best option would (again) be the coach.  
That's because you travel …`,
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E8",
    exerciseKey: "img/FriendsPlus/Page57/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your letter of complaint (180–200 words) using
        your notes in exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=3></textarea>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P57-E9",
    exerciseKey: "img/FriendsPlus/Page57/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss the statement in
        exercise 8. Use the phrases below to show that you
        agree or disagree.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          That’s exactly how I feel.<br>
          I couldn’t agree more. In fact, …<br>
          I see your point, but …<br>
          That may be true to some extent. However, …<br>
          I don’t really agree that …<br>
          I’m afraid I disagree. I think …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
