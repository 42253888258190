import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P21-E1",
    exerciseKey: "img/FriendsPlus/Page21/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss the questions below.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Who are the people you are closest to?<br>
          <b>2</b>&ensp;What qualities do you look for in a friend?
        </div>
        
        
        `,
        answer: [
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P21-E2",
    exerciseKey: "img/FriendsPlus/Page21/Key/E2answerKey.png",

    titleQuestion: [
      {
        num: "2",
        title: `Read the task and the essay. In which paragraphs does
        the writer cover each element of the task?`,
        color: "black",
        left: 50,
        width: "90%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page21/E2/1.jpg" style='margin-left:120px;height:23cm'/>
        
        `,
        answer: [
          "must",
          "can’t",
          "be",
          "as",
          "appears",
          "like",
          "say",
          "certain",
          "wrong",
          "seems to",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P21-E3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E3answerKey.png",

    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#2683c6'>Learn this!</span> box and the <span style='color:#2683c6'>Writing Strategy</span>. Then
        underline an example of each of the structures below in
        the essay. `,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      width: 1000,
      paddingTop: 1,
      marginBottom: -23,
    },
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    // hideBtnFooter: true,
    character: "/",
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page21/E3/1.jpg" style='margin-left:-30px;height:11cm'/>
          <div>
            <img src="img/FriendsPlus/Page21/E3/2.jpg" style='margin-top:20px;height:5cm'/>
            <div style='margin-left:10px'>
              <b>1</b>&ensp;Contrasting clauses with <i>but</i> and <i>although</i><br>
              <b>2</b>&ensp;An example of another linker<br>
              <b>3</b>&ensp;A passive form<br>
              <b>4</b>&ensp;An example of preparatory <i>it</i><br>
            </div>
          </div>
        </div>
        <b>Answer</b>
        <div style='display:flex'><b>1</b><textarea id=0 rows=2></textarea></div>
        <div style='display:flex'><b>2</b><textarea id=1 rows=2></textarea></div>
        <div style='display:flex'><b>3</b><textarea id=2 rows=2></textarea></div>
        <div style='display:flex'><b>4</b><textarea id=3 rows=2></textarea></div>
        
          
        `,
        answer: [
          "I think opposites do attract, but I don't think it lasts as those different qualities that attracted you at first can annoy you later! / For example, we both love sport, but we support different teams. / And although we're both sociable, Helen is more outgoing than me. So, in my view, although you may be attracted to a friend who is completely opposite to you, the best kind of friend is one that you have enough in common with, so that your friendship lasts a long time.",
          "However, and",
          "It is sometimes claimed that opposites attract when it comes to friendship",
          "It's in the details that we differ.",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P21-E4",
    exerciseKey: "img/FriendsPlus/Page21/Key/E4answerKey.png",
    textareaStyle: { width: 800, paddingTop: 5 },
    titleQuestion: [
      {
        num: "4",
        title: `Rewrite the sentences using a structure from the <span style='color:#2683c6'>Learn this!</span> box.  Start with the words in brackets. `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: { width: 900 },
    component: T6,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;Online conversations are quick and easy. They are often superficial. (Although …)<br>#<br>
          <b>2</b>&ensp;The non-verbal aspects of conversations are really important. (It is …)<br>#<br>
          <b>3</b>&ensp;A lot of people believe that you only need a few close friends. (It is …)<br>#<br>
          <b>4</b>&ensp;People are busy. They can still keep in touch using social media. (Even if …)<br>#<br>
        </div>
          
        `,
        answer: [
          "Although online conversations are quick and easy, theyare often superficial.",
          "It is the non-verbal aspects of conversations that arereally important.",
          "It is believed that you only need a few close friends.",
          "Even if people are busy, they can still keep in touchusing social media.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P21-E5",
    exerciseKey: "img/FriendsPlus/Page21/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Read the task. Then make notes under headings (A–C)
        below. Include ideas from exercise 4 and your own
        opinions.A`,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page21/E5/1.jpg" style='margin-top:20px;height:5cm'/>
        <div style='line-height:46px'>
            <b>1</b>&ensp;Online conversations are quick and easy. They are often superficial. (Although …)<br>
            <b>2</b>&ensp;The non-verbal aspects of conversations are really important. (It is …)<br>
            <b>3</b>&ensp;A lot of people believe that you only need a few close friends. (It is …)<br>
            <b>4</b>&ensp;People are busy. They can still keep in touch using social media. (Even if …)<br>
        </div>
        <div>
          <b>A</b>&ensp;Online friendships: benefits<br>
          <b>B</b>&ensp;Online friendships: possible problems<br>
          <b>C</b>&ensp;Face-to-face relationships: unique features<br>
        </div>
        <textarea id=0 rows=5></textarea>
        `,
        // InputRong: true,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P21-E6",
    exerciseKey: "img/FriendsPlus/Page21/Key/E6answerKey.png",
    recorder: true,
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page21/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P21-E7",
    exerciseKey: "img/FriendsPlus/Page21/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P21-E8",
    exerciseKey: "img/FriendsPlus/Page21/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page21/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
