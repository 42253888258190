import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Review Unit 3",
    id: "SB12-2024-U3-P47-E1",
    exerciseKey: "img/FriendsPlus/Page47/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Reading</span> <br> Read the text and choose the best answers (A–D).`,

        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
        ],
        answers: ["0-8", "1-12", "2-4", "3-12", "4-0"],
        initialValue: [],
      },
      Layout: `
      <b style='font-size:30px'>Mardi Gras: a party to remember</b>
      
      <div style='margin-bottom:15px; width:25cm'>
        Mardi Gras. Two little words that describe a centuries-old
        celebration which originated in Medieval Europe. It is the
        name given to the final day of the Carnival season, the day
        that traditionally precedes forty days of fasting in some
        religions. The words mean ’Fat Tuesday’, referring not only to
        the day on which the celebration is always held, but also to
        the custom of eating up all the forbidden food before the fast.
          <div style='margin-top:15px'>Today, it is the city of New Orleans in Louisiana, USA, that
          holds one of the most famous Mardi Gras celebrations in
          the world. Mardi Gras was introduced to North America in
          March 1699 by a French Canadian explorer called
          Jean-Baptiste Le Moyne de Bienville. Arriving on the eve of
          the festival at an area 90 kilometres south of what is now
          New Orleans, he organised the following day celebration
          on a plot of land he named Pointe du Mardi Gras. The
          custom soon caught on, and by the time New Orleans had
          been established by Bienville in 1718, Americans were
          celebrating their own version of Mardi Gras.
          </div>
          <div style='margin-top:15px'>What makes the New Orleans event so special is its
          dazzling parades. Marching bands escort artistic carnival
          floats through streets lined with spectators wearing
          masks. These masks afforded the wearer a disguise which
          allowed him or her to mix with people of different classes.</div>
          <div style='margin-top:15px'>As for the carnival floats, each one belongs to an
          organisation known as a ‘krewe’. The tradition of the ‘krewe’
          began in 1856 when six young men from the nearby town
          of Mobile formed the ‘Mistek Krewe of Comus’. Not only does
          this group hold the distinction of being the first krewe, but
          it is also credited with parading the first float.</div>
          <div style='margin-top:15px'>The second Mardi Gras krewe, the Twelfth Night Revelers
          was founded in 1870, and initiated another popular
          tradition, the Mardi Gras ‘throws’. These are small presents
          which are tossed to the crowd by the costumed float-riders.</div>
          <div style='margin-top:15px'>Mardi Gras was made official in New Orleans in 1875 when
          Governor Warmoth signed the Mardi Gras Act making Fat
          Tuesday a public holiday in Louisiana. </div>
        </div>
        <b>1</b>&ensp;The first Mardi Gras was celebrated in North America when Bienville<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=0 type='Circle' />
          </div>
          <div>
            crossed the Canadian border.<br>
            founded New Orleans.<br>
            reached Pointe du Mardi Gras.<br>
            landed on the coast of Louisiana.<br>
          </div>
         </div>
        <b>2</b>&ensp; Festival-goers started wearing masks at Mardi Gras because<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=1 type='Circle' />
          </div>
          <div>
            they were an obligatory part of the costume.<br>
            they were made legal for the day.<br>
            they were handed out by the float-riders.<br>
            they hid a person’s true identity.<br>
          </div>
         </div>
        <b>3</b>&ensp; 1856 is the year in which<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=2 type='Circle' />
          </div>
          <div>
            the first krewe was formed in New Orleans.<br>
            floats began to take part in the parades.<br>
            a committee was voted to organise the festival.<br>
            the town of Mobile held its first Mardi Gras.<br>
          </div>
         </div>
        <b>4</b>&ensp; The Twelfth Night Revelers was the first krewe to<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=3 type='Circle' />
          </div>
          <div>
            pay for a float.<br>
            dress up in colourful outfits.<br>
            hand out money during a parade.<br>
            provide gifts for spectators.<br>
          </div>
         </div>
        <b>5</b>&ensp; Before 1875,<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=4 type='Circle' />
          </div>
          <div>
            schools and offices were open during Mardi Gras.<br>
            tourists were not welcome at the celebrations.<br>
            krewes didn’t have to pay for their own floats.<br>
            Mardi Gras was financed by the governor.<br>
          </div>
         </div>
         `,
    },
  },
  2: {
    unit: "Review Unit 3",
    id: "SB12-2024-U3-P47-E2",
    exerciseKey: "img/FriendsPlus/Page47/Key/E2answerKey.png",
    audio: "Audios/1.39.mp3",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Listening</span><br>
        <headphone name='1.39' colorimg=#9bc5d6  color='white'></headphone> You will hear four speakers talking about mistakes
        they have made in a foreign language. Match sentences
        (A–E) with speakers (1–4). There is one extra sentence.
        
        
        `,
        color: "black",
        left: 0,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        The speaker …<br>
        <b>A</b>&ensp;used an inappropriate greeting.<br>
        <b>B</b>&ensp;mispronounced a word.<br>
        <b>C</b>&ensp;used the wrong verb.<br>
        <b>D</b>&ensp;gave an incorrect response.<br>
        <b>E</b>&ensp;misunderstood a word.<br>
        <div style='display:flex'>
          <div>
            Speaker 1:#<br>
            Speaker 2:#<br>
          </div>
          <div style='margin-left:200px'>
            Speaker 3:#<br>
            Speaker 4:#<br>
          </div>
        </div>
 
        `,
        answer: ["D", "A", "E", "B"],
      },
    ],
  },
  3: {
    unit: "Review Unit 3",
    id: "SB12-2024-U3-P47-E3",
    exerciseKey: "img/FriendsPlus/Page47/Key/E3answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Speaking</span><br>
        Work in pairs. You and a friend are organising a surprise
birthday party for another friend. Discuss the following points
to make a plan for the party and come to an agreement.`,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <ul>
          <li> Day</li>
          <li> Guests</li>
          <li> Venue</li>
          <li> Gifts</li>
        </ul>
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },

  4: {
    unit: "Review Unit 3",
    id: "SB12-2024-U3-P47-E4",
    exerciseKey: "img/FriendsPlus/Page47/Key/E4answerKey.png",
    textareaStyle: { width: 1000, paddingTop: 1 },
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Writing</span><br>
        Some students choose to go backpacking in the holidays.
Is this a good idea? Write an essay (180–200 words) in
which you express your opinion considering the impact of
this kind of holiday on the students and on the countries
they visit.
       `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
          
        `,
        answer: ["Round 2", "Family life", "Flowers", "Pink", "Yes, they did."],
      },
    ],
  },
  5: {
    unit: "Review Unit 3",
    id: "SB12-2024-U3-P47-E5",
    exerciseKey: "img/FriendsPlus/Page47/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `You are having a family reunion at the end of
        the month. Plan a short trip for your family on that day,
        including:`,
        color: "black",
        left: 50,
        width: "25cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Where you and your family are going ...<br>
          <b>2</b>&ensp;What activities you are participating in ...<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Review Unit 3",
    id: "SB12-2024-U3-P47-E6",
    exerciseKey: "img/FriendsPlus/Page47/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page47/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Review Unit 3",
    id: "SB12-2024-U3-P47-E7",
    exerciseKey: "img/FriendsPlus/Page47/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Review Unit 3",
    id: "SB12-2024-U3-P47-E8",
    exerciseKey: "img/FriendsPlus/Page47/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page47/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
