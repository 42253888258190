import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E1",
    exerciseKey: "img/FriendsPlus/Page82/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Say what health problems
        living in a city might cause.`,
        color: "black",
        width: "26cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E2",
    exerciseKey: "img/FriendsPlus/Page82/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Match questions (1–6) with paragraphs (A–C). You can
        match each paragraph with more than one question.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    textareaStyle: {
      width: "25cm",
      paddingTop: 2,
      resize: "none",
      // marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C"],
    // character:",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        Which paragraph talks about …
        <div style='line-height:47px'>
          <div style='display:flex'><select id=0></select><b>1</b>&ensp;future plans?<br></div>
          <div style='display:flex'><select id=1></select><b>2</b>&ensp;something coming back to life?<br></div>
          <div style='display:flex'><select id=2></select><b>3</b>&ensp;people dying?<br></div>
          <div style='display:flex'><select id=3></select><b>4</b>&ensp;an attempt to stop something from happening again?<br></div>
          <div style='display:flex'><select id=4></select><b>5</b>&ensp;charging people so that everyone’s lives would be improved?<br></div>
          <div style='display:flex'><select id=5></select><b>6</b>&ensp;something that has received awards?<br></div>
        </div>
        <img src="img/FriendsPlus/Page82/E2/1.jpg" style='height:29cm'/>
        `,
        // InputRong: true,
        answer: ["C", "A", "B", "B", "C", "A"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E3",
    exerciseKey: "img/FriendsPlus/Page82/Key/E3answerKey.png",
    audio: "Audios/2.27.mp3",
    // checkDuplicated: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='2.27' colorimg=#98c2d7  color='white'></headphone> Listen to a radio programme about London’s
        sewers and answer the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "boredom",
          "comfort",
          "convenience",
          "cost",
          "luggage",
          "safety",
          "time",
          "travel sickness",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;What dangers did the reporter expect to meet in the sewers?<br>#<br>
          <b>2</b>&ensp;What causes the biggest problems for the maintenance teams?<br>#<br>
        </div>
        `,
        answer: [
          "She expected terrible smells and rats.",
          "Solid fat causes the biggest problem.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E4",
    exerciseKey: "img/FriendsPlus/Page82/Key/E4answerKey.png",
    audio: "Audios/2.27.mp3",
    textareaStyle: {
      marginBottom: -23,
      // resize: "none",
      width: 570,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='2.27' colorimg=#98c2d7  color='white'></headphone> Listen again and answer the questions.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 800,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='width:21cm'>
          <b>1</b>&ensp;How many kilometres of sewers are there?<br>#<br>
          <b>2</b>&ensp;What caused the pollution in the River Thames?<br>#<br>
          <b>3</b>&ensp;What made the politicians feel nauseous?<br>#<br>
          <b>4</b>&ensp;What does 90% of the liquid in the sewers consist of?<br>#<br>
          <b>5</b>&ensp;What are less common in the sewers than many people expect?<br>#<br>
          <b>6</b>&ensp;Where does a lot of the fat come from?<br>#<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "There are nearly fifty thousand kilometres of sewers.",
          "Waste from the newly invented flushing toilet caused the pollution.",
          "The smell from the river made the politicians feel nauseous.",
          "90% of the liquid in sewers is rainwater.",
          "Rats are less common.",
          "It comes from fast food restaurants",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E5",
    exerciseKey: "img/FriendsPlus/Page82/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Work in groups. Discuss which three projects
        you would choose to make your home town cleaner,
        healthier and more attractive. Give reasons for your
        choices.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "PROJECT", icon: "", color: "#96969a" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 950,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "do not have problems",
          "got",
          "happen",
          "looked at",
          "really think",
          "said",
          "she didn’t say sorry",
          "showed her",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
      <ul>
        <li>Create more parks, green spaces, etc. in the city.</li>
        <li>Build more leisure facilities such as sports centres, swimming pools, theatres, etc.</li>
        <li>Provide more youth activities such as after-school clubs.</li>
        <li>Promote transport initiatives such as bicycle lanes, bicycles for rent, etc.</li>
        <li>Restore old buildings, etc. in the city.</li>
        <li>Build more health centres and hospitals.</li>
      </ul>
        
        `,
        InputRong: true,
        answer: [
          "T",
          "F",
          "T",
          "F",
          "T",
          "F",
          "",
          "It involved asking questions about their character.",
          "",
          "She would walk around her house reading poems in a Polish accent.",
          "",
          "He visited a psychiatric hospital to prepare for a film about a psychiatric hospital on an island.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E6",
    exerciseKey: "img/FriendsPlus/Page82/Key/E6answerKey.png",
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Look at the picture and discuss
        the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 600,
    },
    // character: ",",
    hideBtnFooter: true,

    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
          <b>1</b>&ensp;Can you name the arts form in the brochure? Give a short presentation on its history.
          <b>2</b>&ensp;How has the Đồng Ấu Bạch Long group contributed to the development of the arts form?
        </div>
        <img src="img/FriendsPlus/Page82/E6/1.jpg" style='height:9cm'/>
        `,
        answer: [
          `He is proud of his culture. He knows a lot about it and speaks about it proudly.`,
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E7",
    exerciseKey: "img/FriendsPlus/Page82/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='2.08' colorimg=#98c2d7  color='white'></headphone> Listen again and answer the questions.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 860,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 600,
      // textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "no food available",
          "overcrowded",
          "queues for everything",
          "shop / swimming pool, etc. was closed",
          "showers / restaurants, etc. were dirty",
          "staff were impolite / unhelpful",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;What does kia ora mean?<br>#<br>
          <b>2</b>&ensp;What does whenua refer to?<br>#<br>
          <b>3</b>&ensp;What type of holiday do a lot of tourists go on in New Zealand?<br>#<br>
          <b>4</b>&ensp;Which sport are Maoris good at?<br>#<br>
          <b>5</b>&ensp;What is a haka?<br>#<br>
        </div>
       
        
        `,
        answer: [
          "It means hello in Maori.",
          "It refers to the Maoris' connection with the land.",
          "They often go on adventure holidays.",
          "They are good at rugby.",
          "A haka is a traditional Maori war dance.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E8",
    exerciseKey: "img/FriendsPlus/Page82/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Discuss what you think about
        the customs of ‘walkabout’ and ‘Ta moko’.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
s        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P82-E9",
    exerciseKey: "img/FriendsPlus/Page82/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in groups. Find out facts about the main
        ethnic groups in northern Viet Nam, Tay and Nung. Then
        present them to the whole class.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "PROJECT", icon: "", color: "#999b9c" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
