import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P74-E1",
    exerciseKey: "img/FriendsPlus/Page74/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. In what ways do you think
        these things were different in the past?`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: ["diseases", "hospitals", "hygiene"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: 800,
      },
    ],
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      // marginBottom: 23,
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        

        `,
        answer: [
          "He had been successful thanks to his good preparations, persistence and good strategies.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P74-E2",
    exerciseKey: "img/FriendsPlus/Page74/Key/E2answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. Does it mention your ideas from exercise 1?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 450, paddingTop: 10 },
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["diseases", "hospitals", "hygiene"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: 800,
      },
    ],
    character: ",",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <img src="img/FriendsPlus/Page74/E2/1.jpg" style='height:22.4cm'/><br>
        
        
        `,
        // InputRong: true,
        answer: [
          "he had applied for, His classmates even had established contact group, he had been short-listed",
          "he had been experiencing, He had been looking at, They had been working part-time together, he had been perfecting",
          "There is no example.",
          "They had been exchanging information, had been experiencing",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P74-E3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Find passive examples of tenses (a–g) in the text`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // character: "-",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
         
        <div style='width:27cm;line-height:47px'>
          <b>a</b>&ensp;present simple<br>#<br>
          <b>b</b>&ensp;present perfect<br>#<br>
          <b>c</b>&ensp;past continuous<br>#<br>
          <b>d</b>&ensp;will future<br>#<br>
          <b>e</b>&ensp;present continuous<br>#<br>
          <b>f</b>&ensp;past simple<br>#<br>
          <b>g</b>&ensp;past perfect<br>#<br>
        </div>
        <img src="img/FriendsPlus/Page74/E2/1.jpg" style='height:22.4cm'/><br>
        `,
        // InputRong: true,
        answer: [
          "is (still) affected",
          "haven't been eliminated, have been (significantly) reduced",
          "were being killed",
          "will be challenged, will be transmitted",
          "is being improved",
          "were adopted",
          "had been taught",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P74-E4",
    exerciseKey: "img/FriendsPlus/Page74/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Complete the rules.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 100,
        // prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["save", "learn", "have", "lie", "rise", "go", "work"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      fontSize: 23,
      width: 240,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
          <img src="img/FriendsPlus/Page74/E4/1.jpg" style='height:18cm'/>
            <div>
              <b>1</b>#<br>
              <b>2</b>#<br>
              <b>3</b>#<br>
              <b>4</b>#<br>
              <b>5</b>#<br>
            </div>
        </div>
        `,
        answer: [
          "be",
          "past participle",
          "subject",
          "by",
          "present continuous",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P74-E5",
    exerciseKey: "img/FriendsPlus/Page74/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Rewrite the sentences (1–5) in the passive. Match them
        with rules (2–4) in the <span style='color:#f88b1f'>Learn this!</span> box The sentences
        may match with more than one rule.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "in three hours from now",
          "at midnight tonight",
          "in three months’ time",
          "by this time next year",
          "by your 20th birthday",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page74/E4/1.jpg" style='height:19cm'/>
        <div style='width:27cm'>
            <b>1</b>&ensp;A doctor has examined me and told me to rest at home. - # <textarea id=5 rows=2></textarea>
            <b>2</b>&ensp;It was in the 19th century that they invente anaesthetics. - # <textarea id=6 rows=2></textarea>
            <b>3</b>&ensp;Scientists are making more advances in medicine ever year. - # <textarea id=7 rows=2></textarea>
            <b>4</b>&ensp;They’ll stitch the wound now and remove the stitche next week. - # <textarea id=8 rows=2></textarea>
            <b>5</b>&ensp;Kazimierz Funk, a Polish scientist, discovered the fac that vitamins could cure many diseases. - # <textarea id=9 rows=2></textarea>
        </div>
        
        `,
        answer: [
          "2",
          "3a",
          "4",
          "3b",
          "2",
          "I have been examined by a doctor and (have been) told to rest at home.",
          "It was in the 19th century that anaesthetics were invented.",
          "More advances in medicine are being made.",
          "The wound will be sewn now and the stitches will be removed next week.",
          "The fact that many diseases could be cured by vitamins was discovered by Kazimierz Funk, a Polish scientist.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P74-E6",
    exerciseKey: "img/FriendsPlus/Page74/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the text below. Then rewrite the underlined
        sentences (1–8) in the passive. Decide whether you need
        to include the agent.`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    character: "/",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page74/E6/1.jpg" style='height:12cm'/>
      <div style='display:flex'><b>1</b>&ensp; <textarea id=0 rows=2></textarea></div>
      <div style='display:flex'><b>2</b>&ensp; <textarea id=1 rows=2></textarea></div>
      <div style='display:flex'><b>3</b>&ensp; <textarea id=2 rows=2></textarea></div>
      <div style='display:flex'><b>4</b>&ensp; <textarea id=3 rows=2></textarea></div>
      <div style='display:flex'><b>5</b>&ensp; <textarea id=4 rows=2></textarea></div>
      <div style='display:flex'><b>6</b>&ensp; <textarea id=5 rows=2></textarea></div>
      <div style='display:flex'><b>7</b>&ensp; <textarea id=6 rows=2></textarea></div>
      <div style='display:flex'><b>8</b>&ensp; <textarea id=7 rows=2></textarea></div>
        `,
        answer: [
          "It is known as the Black Death.",
          "the plague was brought from Asia to Europe by rats / the plague was brought by rats from Asia to Europe",
          "Millions of people were killed (by the disease).",
          "they were quickly infected by coughs and sneezes",
          "different areas of the world were attacked by Covid-19",
          "Millions of people were infected by the virus corona.",
          "Many cities had to be locked down by authorities.",
          "patients can be saved from danger with vaccines",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P74-E7",
    exerciseKey: "img/FriendsPlus/Page74/Key/E7answerKey.png",
    component: T6,
    recorder: true,

    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
        <img src="img/FriendsPlus/Page74/E6/1.jpg" style='height:10cm'/>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P74-E8",
    exerciseKey: "img/FriendsPlus/Page74/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page74/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
