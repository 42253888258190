import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P19-E1",
    exerciseKey: "img/FriendsPlus/Page19/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Read the quote from Arthur Miller. What do you think
        he means about technology and relationships in the
        modern times? Can you guess what the text is about? `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page19/E1/1.jpg" style='height:19cm'/>
        
        
        `,
        InputRong: true,
        answer: [
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P19-E2",
    exerciseKey: "img/FriendsPlus/Page19/Key/E2answerKey.png",

    titleQuestion: [
      {
        num: "2",
        title: `Scan the text. What is the purpose of the reading?`,
        color: "black",
        left: 0,
        width: "90%",
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid ",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c"],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page19/E1/1.jpg" style='height:19cm'/>
     <div style='display:flex;line-height:50px'>
      <div>
        <input id=0 type='Circle' />
      </div>
      <div style='margin-left:10px'>
          To tell the difference between technological and social
          bonds<br>
          To list all of the advantages of technology to social
          connections<br>
          To give advice on how to develop relationships in
          technological era<br>
      </div>
     </div>
      `,
    },
  },
  3: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P19-E3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: ["text", "mistake", "touch", "bonds", "hiking"],
        styleElement: { color: "#3d3e9a" },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#3d3e9a'>Reading Strategy</span>. Then find the words below
        in the text and decide whether each one is a verb, noun,
        adjective, etc.`,
        color: "black",
        left: 50,
        width: "90%",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='display:flex;line-height:50px'>
            <div>
            <hintbox id=0></hintbox>
            <img src="img/FriendsPlus/Page19/E3/1.jpg" style='margin-top:20px;height:6.5cm'/>
            </div>
            <div style='margin-left:20px'>
              <b>Answer:</b><br>
              text-#<br>
              mistake-#<br>
              touch-#<br>
              bonds-#<br>
              hiking-#<br>
            </div>
          </div>
        `,
        answer: ["verb", "verb", "noun", "noun", "noun"],
      },
    ],
  },

  4: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P19-E4",
    exerciseKey: "img/FriendsPlus/Page19/Key/E4answerKey.png",

    titleQuestion: [
      {
        num: "4",
        title: `Look again at the context of the words in exercise 3.
        Choose the correct definition (a or b) for each one.`,
        color: "black",
        width: "28cm",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid ",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b", "a <br> b", "a <br> b", "a <br> b", "a <br> b"],
        answers: ["0-4", "1-0", "2-0", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
        <div><b>1</b> <br><br> <b>2</b> <br><br> <b>3</b> <br><br> <b>4</b> <br><br> <b>5</b></div>
        <div  style='margin:0px 10px'><input id=0 type='Circle'/><br><input id=1 type='Circle'/><br><input id=2 type='Circle'/><br><input id=3 type='Circle'/><br><input id=4 type='Circle'/><br></div>
        <div>
          any form of written material <br>
          send somebody a written message using a mobile phone<br>
          not understand or judge somebody/something orrectly<br>
          a word, figure, etc. that is not said or written own correctly<br>
          contact <br>
          put your hand onto something<br>
          strong connections <br>
          join two things strongly together<br>
          having something to do with sports <br>
           the activity of going for long walks in the country for pleasure<br>
        </div>
      </div>

        
      `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P19-E5",
    exerciseKey: "img/FriendsPlus/Page19/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Read the text. Choose the correct answers (a–d).`,
        color: "black",
        left: -30,
        width: "25cm",
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid ",
          borderColor: "white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-0", "1-8", "2-0", "3-4", "4-12"],
        initialValue: [],
      },
      Layout: `
      <b>1</b>&ensp;The text mainly discusses
      <div style='display:flex'>
        <div style='margin-left:20px'>
          <input id='0' type='Circle'/>
        </div>
        <div>
            practical ways to nurture connections in the worldof technology.<br>
            the causes of worsened relationships in the modernworld.<br>
            the values of technological advancements inpersonal relationships.<br>
            the role of social media in maintaining relationships.
        </div>
       </div>
      <b>2</b>&ensp;All of the following are examples of our strong attachment to technology except
      <div style='display:flex'>
        <div style='margin-left:20px'>
          <input id='1' type='Circle'/>
        </div>
        <div>
            texting friends.<br>
            working on computers.<br>
            listening to others.<br>
            watching videos.
        </div>
       </div>
      <b>3</b>&ensp;We wrongly perceive social media as
      <div style='display:flex'>
        <div style='margin-left:20px'>
          <input id='2' type='Circle'/>
        </div>
        <div>
            a wonderful replacement for relationships.<br>
            a valuable tool of working life.<br>
            time-saving means of communication.<br>
            misguided ways of contact.
        </div>
       </div>
      <b>4</b>&ensp;The first step to empower connections is
      <div style='display:flex'>
        <div style='margin-left:20px'>
          <input id='3' type='Circle'/>
        </div>
        <div>
            joining a sports club.<br>
            saving more time for family members.<br>
            keeping in touch with different people.<br>
            going hiking with friends.
        </div>
       </div>
      <b>5</b>&ensp;Time commitment is
      <div style='display:flex'>
        <div style='margin-left:20px'>
          <input id='4' type='Circle'/>
        </div>
        <div>
            necessary in balancing connections.<br>
            the first requirement for strengthening one’s mind.<br>
            impossible in the modern time.<br>
            essential in maintaining relationships.
        </div>
       </div>
      

        
      `,
    },
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P19-E6",
    exerciseKey: "img/FriendsPlus/Page19/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      margihLeft: 20,
      width: 700,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `<span style='color:#91268e'>VOCABULARY</span> Match the underlined words with the
        highlighted words in the text.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:80%'>
          <b>1</b>&ensp;Despite the convenience of electronic <u>gadgets</u>, some argue that excessive screen time can have negative impacts on mental and physical health.#<br>
          <b>2</b>&ensp;Lisa is so <u>close</u> to her hometown that she does not want to move to a big city for a better job opportunity.#<br>
          <b>3</b>&ensp;Engaging in meaningful conversations <u>improves</u> interpersonal relationships and fosters emotional connections.#<br>
          <b>4</b>&ensp;Empathy is <u>important</u> in building trust and confidence between friends.#<br>
          <b>5</b>&ensp;We quite often spend a lot of time making a decision, but it rarely turns out to be the <u>best</u> choice.#<br>
        </div>
        
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P19-E7",
    exerciseKey: "img/FriendsPlus/Page19/Key/E7answerKey.png",
    recorder: true,
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P19-E8",
    exerciseKey: "img/FriendsPlus/Page19/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page19/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
