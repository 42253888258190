import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P63-E1",
    exerciseKey: "img/FriendsPlus/Page63/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Describe the photo. What is going on?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page63/E1/1.jpg" style='height:12cm'/>
        

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P63-E2",
    exerciseKey: "img/FriendsPlus/Page63/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the magazine article. How many types of job
        interviews are mentioned? Which do you prefer?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 100, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Travel collocations</b>"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
      {
        src: [
          "do",
          "get",
          "go",
          "have",
          "help",
          "light",
          "put up",
          "sleep",
          "taste",
          "travel",
        ],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
    ],
    character: ",",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page63/E2/1.jpg" style='height:18cm'/>
        
        `,
        // InputRong: true,
        answer: [
          "light",
          "taste",
          "have",
          "sleep",
          "put up",
          "help",
          "get",
          "travel",
          "go",
          "do",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P63-E3",
    exerciseKey: "img/FriendsPlus/Page63/Key/E3answerKey.png",
    audio: "Audios/2.11.mp3",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='2.11' colorimg=#eab5b2  color='white'></headphone> Read the <span style='color:#c50d47'>Listening Strategy</span>. Then listen and
        underline the correct words to complete the sentences.`,
        color: "black",
        left: -30,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `cool / freezing`,
          `stubborn / determined`,
          `details / illustrations`,
        ],
        answers: ["0-4", "1-4", "2-0"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:47px'>
        <b>1</b>&ensp;Speaker 1 describes the room as <input id=0 type=Circle />.<br>
        <b>2</b>&ensp;Speaker 2 says the graduate on the chat show is <input id=1 type=Circle />.<br>
        <b>3</b>&ensp;Speaker 3 thinks the article lacks <input id=2 type=Circle />.
      </div>
      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P63-E4",
    exerciseKey: "img/FriendsPlus/Page63/Key/E4answerKey.png",
    audio: "Audios/2.11.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='2.11' colorimg=#eab5b2  color='white'></headphone> Listen again. For each dialogue, answer the
        questions below. Say which clues helped you to answer.
        
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -20,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["save", "learn", "have", "lie", "rise", "go", "work"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 1070,
      // textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [`a <br> b <br> c`, `a <br> b <br> c`, `a <br> b <br> c`],
        answers: ["0-0", "1-4", "2-0"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex;line-height:47px'>
        <div>
          <b>1</b>&ensp;Speaker 1 
          <div style='display:flex'>
            <div style='margin-left:15px'>
              <input id=0 type=Circle />
            </div>
            <div>disappointed.<br>bored.<br>enthusiastic</div>
          </div>
        </div>
        <div style='margin:0px 100px'>
            <b>2</b>&ensp;Speaker 2 
          <div style='display:flex'>
            <div style='margin-left:15px'>
              <input id=1 type=Circle />
            </div>
            <div>optimistic.<br>impressed.<br>uninterested.</div>
          </div>
        </div>
        <div>
            <b>3</b>&ensp;Speaker 3 
          <div style='display:flex'>
            <div style='margin-left:15px'>
              <input id=2 type=Circle />
            </div>
            <div>critical.<br>interested.<br>passionate.</div>
          </div>
        </div>
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P63-E5",
    exerciseKey: "img/FriendsPlus/Page63/Key/E5answerKey.png",
    audio: "Audios/2.12.mp3",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='2.12' colorimg=#eab5b2  color='white'></headphone> Listen and match the speakers (1–3) with
        sentences (A–D). Write the correct number. There is one
        extra sentence.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 150,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "in three hours from now",
          "at midnight tonight",
          "in three months’ time",
          "by this time next year",
          "by your 20th birthday",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    // character: "|",
    questions: [
      {
        title: `
         <div style='width:27cm'>
            <b>A</b>&ensp;The speaker is supportive of panel interviews.#<br>
            <b>B</b>&ensp;The speaker’s attitude towards panel interview is unsympathetic.#<br>
            <b>C</b>&ensp;The speaker feels outraged about the tension caused by a panel interview.#<br>
            <b>D</b>&ensp;The speaker has a balanced view of the use of panel interviews.#<br>
          </div>
        `,
        InputRong: true,
        answer: ["Speaker 3|3", "", "Speaker 1|1", "Speaker 2|2"],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P63-E6",
    exerciseKey: "img/FriendsPlus/Page63/Key/E6answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    // textAlign: "center",

    textareaStyle: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "two",
          "theirs know",
          "cues",
          "hear",
          "one",
          "sow",
          "sea",
          "hour",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:47px;width:26cm'>
                <b>1</b>&ensp;Should an interviewee ask the interviewer questions What questions do you think should be asked?<br>
                <b>2</b>&ensp;Is it justified for the interviewers to put interviewee under stress during the interview?<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P63-E7",
    exerciseKey: "img/FriendsPlus/Page63/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page63/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P63-E8",
    exerciseKey: "img/FriendsPlus/Page63/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page63/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
