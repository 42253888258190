import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P40-E1",
    exerciseKey: "img/FriendsPlus/Page40/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Look at the photo. Where do you think this is?`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page40/E1/1.jpg" style='height:8cm'/>
        `,
        answer: ["confused", "happy", "anxious", "annoyed", "surprised"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P40-E2",
    exerciseKey: "img/FriendsPlus/Page40/Key/E2answerKey.png",
    audio: "Audios/1.31.mp3",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name='1.31' colorimg=#fbdab2  color='white'></headphone> Listen and complete the dialogue.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { textAlign: "center", width: 110, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Gestures and expressions</b>",
          "bite your fingernails",
          "bow your head",
          "fidget",
          "fold your arms",
          "frown",
          "give a thumbs up",
          "grimace",
          "grin",
          "nod your head",
          "point",
          "pout",
          "purse your lips",
          "raise your eyebrows",
          "shake your head",
          "shrug your shoulders",
          "wink",
          "yawn",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div>
            <b>Jo</b><br>
            <b>Tom</b><br><br><br>
            <b>Jo</b><br>
            <b>Tom</b><br><br><br><br>
            <b>Jo</b><br>
            <b>Tom</b><br>
          </div>
           <div style='width:20cm;margin-left:20px'>
              Hi, Tom. How was your trip to Japan?<br>
              It was a disaster! I <sup>1</sup># have read about their
              customs beforehand – then I <sup>2</sup># not have
              embarrassed myself so badly.<br>
              Why? What happened?<br>
              Well, Aki’s parents took us to dinner one night. I
              had a really bad cold. Aki <sup>3</sup># have told me not
              to blow my nose in public! Everyone kept staring.
              They <sup>4</sup># have thought I was so rude! When
              Aki told me later, I felt terrible about it.<br>
              I’m sure you needn’t have done. How could you
              have known?<br>
              Anyway, I bought a gift for Aki’s parents. I sent
              it on Monday, so it <br> <sup>5</sup># have arrived by now. I
              probably didn’t need to send them anything, but
              I really wanted to apologise.<br>
           </div>
        </div>
        
        `,
        // InputRong: true,
        answer: ["should", "might", "could", "must", "should"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P40-E3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Match the modal verbs from
        exercise 2 with the rules. `,
        color: "black",
        left: 100,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 500, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "be supposed to",
          "don’t have to, don’t need to and needn’t must and have to",
          "mustn’t",
          "should and ought to",
        ],
        styleElement: { color: "#f88b1f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "14cm",
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page40/E3/1.jpg" style='height:16cm'/>
          <div style='position:absolute;top:0px;left:643px'>
              <div style='display:flex'><b>a</b>&ensp;<textarea id=0  rows=2></textarea></div>
              <div style='display:flex'><b>b</b>&ensp;<textarea id=1  rows=2></textarea></div>
              <div style='display:flex'><b>c</b>&ensp;<textarea id=2  rows=2></textarea></div>
              <div style='display:flex'><b>d</b>&ensp;<textarea id=3  rows=2></textarea></div>
          </div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "I might not have embarrassed myself so badly",
          "I should have read about their customs beforehand. / I sent it on Monday, so it should have arrived by now.",
          "Aki could have told me not to blow my nose in public!",
          "They must have thought I was so rude!",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P40-E4",
    exerciseKey: "img/FriendsPlus/Page40/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Underline the incorrect modal verb and replace it with a
        more suitable one. Sometimes more than one answer is
        possible.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -20,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Gestures and expressions</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    character: "/",
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 550, paddingTop: 2 },
        answers: [
          "can't have / couldn't have",
          "might have / could have",
          "shouldn't have / ought not to have",
          "may not have / might not have",
          "must have / should have / ought to have",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `They mustn’t_have seen Max – he’s away on holiday.`,
          `Joe knew about the strike. He must_have warned me!`,
          `I couldn’t_have yawned when Tim told that story. It was a bit rude of me.`,
          `It was so noisy. It’s possible he ought_not_to_have heard us.`,
          `I emailed Erin a week ago, so she can’t_have read it by now.`,
        ],
        answers: ["0-2", "2-2", "1-12", "3-14", "4-16"],
        initialValue: [],
      },
      Layout: `

      <div style='line-height:50px'>
        <b>1</b>&ensp;<input id=0 type=Circle /> <input id=0 /><br>
        <b>2</b>&ensp;<input id=1 type=Circle /> <input id=1 /><br>
        <b>3</b>&ensp;<input id=2 type=Circle /> <input id=2 /><br>
        <b>4</b>&ensp;<input id=3 type=Circle /> <input id=3 /><br>
        <b>5</b>&ensp;<input id=4 type=Circle /> <input id=4 /><br>
      </div>
      

      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P40-E5",
    exerciseKey: "img/FriendsPlus/Page40/Key/E5answerKey.png",

    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Read the <span style='color:#f88b1f'>Look out!</span> box. Then find examples of <i>needn’t</i>
        <i>have</i> and <i>didn’t</i> need to in exercise 2.`,
        color: "black",
        left: -30,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 550, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 2,
        listWords: [
          `Hi,_Tom. How_was_your_trip_to_Japan?<br>
          It_was_a_disaster! I_should_have_read_about_their_customs_beforehand – 
          then_I_might_not_have_embarrassed_myself_so_badly.<br>
          Why? What_happened?<br>
          Well,_Aki’s_parents_took_us_to_dinner_one_night. I_had_a_really_bad_cold. 
          Aki_could_have_told_me_not_to_blow_my_nose_in_public! 
          Everyone_kept_staring.
          They_must_have_thought_I_was_so_rude! When_Aki_told_me_later, 
          I_felt_terrible_about_it.<br>
          I’m_sure_you_needn’t_have_done. How_could_you_have_known?<br>
          Anyway,_I_bought_a_gift_for_Aki’s_parents. I_sent_it_on_Monday, so_it__should_have_arrived_by_now. 
          I_probably_didn’t_need_to_send_them_anything,_but_I_really_wanted_to_apologise.<br>`,
        ],
        answers: ["0-218", "0-270"],
        initialValue: [],
      },
      Layout: `

      <div style='display:flex'>
          <div>
            <b>Jo</b><br>
            <b>Tom</b><br><br><br>
            <b>Jo</b><br>
            <b>Tom</b><br><br><br><br>
            <b>Jo</b><br>
            <b>Tom</b><br>
          </div>
           <div style='width:20cm;margin-left:20px'>
              <input id=0 type=Circle />
           </div>
        </div>
      `,
    },
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P40-E6",
    exerciseKey: "img/FriendsPlus/Page40/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 900 },
    titleQuestion: [
      {
        num: "6",
        title: `Look at the sentence. Are both verbs correct, or only one?
        Explain the difference between them.
        `,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:20cm'>
            The hotel provided towels, so I <b>didn’t need to take</b> /
            <b>needn’t have taken</b> any.
        </div>
        <b>Explain the difference between them</b><br>
        <b>didn’t need to take:</b><br>#<br>
        <b>needn’t have taken:</b><br>#<br>
        `,
        answer: [
          "it was unnecessary to take towels as they were provided by the hotel.",
          "I packed some towels, but it was unnecessary as the hotel already provided.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P40-E7",
    exerciseKey: "img/FriendsPlus/Page40/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Complete the sentences with a suitable modal verb and
        the correct form of the verbs in brackets.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["You should buy a phrase book."],
        styleElement: { marginLeft: 20, color: "#08728a", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    character: "/",
    stylesTextInput: { width: 350, textAlign: "center" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <div style='line-height:50px'>
            <b>1</b>&ensp;Dan looks tired. He #(sleep) badly last night.<br>
            <b>2</b>&ensp;Luckily, Ela gave me her spare ticket, so I #(buy) one.<br>
            <b>3</b>&ensp;I’m not certain, but I #(leave) my passport at home.<br>
            <b>4</b>&ensp;The restaurant’s empty. We #(book) a table after all.<br>
        </div>
        `,
        answer: [
          "must have slept",
          "didn't need to buy / did not need to buy",
          "might / may / could have left",
          "needn't have booked",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P40-E8",
    exerciseKey: "img/FriendsPlus/Page40/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:#0f9b52' >SPEAKING</span> Work in pairs. Take turns to be A and B.
        <br>Student A: tell Student B about a situation below.
        <br>Student B: listen to Student A and respond using past modals.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>1</b>&ensp;My best friend isn’t talking to me.<br>
        <b>2</b>&ensp;My brother thinks he saw a ghost yesterday.<br>
        <b>3</b>&ensp;I haven’t got any money at all this month.<br>
        <img src="img/FriendsPlus/Page40/E8/1.jpg" style='height:5cm'/>


        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
