import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "SB12-2024-Starter-P6-E1",
    exerciseKey: "img/FriendsPlus/Page6/Key/E1answerKey.png",
    component: T6,
    inputSize:500,
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput:{width:900,fontSize:22,paddingTop:10},
    hintBox:[{
      src:["Afterwards","Amazingly","at","finally","Meanwhile","The moment","While"],
      width:600,
      borderColor:"none",
      colorHint:"#92278f",
      marginLeft:0
    }
  ],
    titleQuestion: [
      {
        num: "1",
        title:
          `Look at the photos (A–D). Which of them are traditional media? `,
        color: "BLACK",
        left:50,
        prefix:[{text:'SPEAKING', icon:'',color: '#01a951'}]
        
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:24cm' src="img/FriendsPlus/Page6/E1/1.jpg"/>
        <div style='line-height:50px'>
          <b>A</b>:#<br>
          <b>B</b>:#<br>
          <b>C</b>:#<br>
          <b>D</b>:#<br>
        </div>
      
        
        `,
        answer: ["Electronic television was first invented in September 1927 in San Francisco.","The first newspaper in the world Acta Diurna was published in Rome around 59 BC.","Twitter was created in March 2006 and launched in July of that year.","Instagram was launched in October 2010. TV, newspapers are traditional media. "],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB12-2024-Starter-P6-E2",
    exerciseKey: "img/FriendsPlus/Page6/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize:500,
    // hideBtnFooter:true,
    textAlign:"center",
    stylesTextInput:{width:200,paddingTop:10},
    hintBox:[{
      src:["<b>Media verbs</b> announce broadcast cover dissemble feelings disseminate disguise the truth distort the truth flatter mislead photoshop post provide report reveal the truth tell a lie transmit"],
      width:1000,
      borderColor:"none",
      colorHint:"#92278f",
      marginLeft:0
    }
  ],
    titleQuestion: [
      {
        num: "2",
        title:
          `Check the meaning of the verbs below. Which of them have 
          negative meaning?`,
        color: "BLACK",
        left:50,
        prefix:[{text:'VOCABULARY',color:'#92278f',icon:''}]
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent:100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "3px solid",
          color:'#92278f',
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: "1px 4px",
          borderRadius: "50%",
          color:"#92278f",
          border: "3px solid",
          borderColor: "gray",
        },
        limitSelect: 6,
        listWords: [
          ` announce broadcast cover dissemble_feelings disseminate disguise_the_truth distort_the_truth flatter mislead photoshop post provide report reveal_the_truth tell_a_lie transmit`
        ],
        answers: ['0-8', '0-16', '0-30', '0-14', '0-18', '0-12'],
        initialValue: [],
      },
      Layout: `
        <div style='width:24cm'>
          <b style='color:rgb(146,39,143)'>Media verbs</b>   <input id='0' type='Circle'/> 
        </div>
          
      
      `,
    },
  },
  
 
 
};

export default json;
