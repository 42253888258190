import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E1",
    exerciseKey: "img/FriendsPlus/Page106/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        width: "26cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px;width:26cm'>
            <b>1</b>&ensp;When do you think the first humanoid robot appeared in Viet Nam?<br>
            <b>2</b>&ensp;To what extent do you think this robot resembles a human?<br>
        </div>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E2",
    exerciseKey: "img/FriendsPlus/Page106/Key/E2answerKey.png",
    // checkDuplicated: true,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. Check your answers in exercise 1. What do
        you find most impressive about Sophia?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    textareaStyle: {
      width: "25cm",
      paddingTop: 2,
      resize: "none",
      // marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C"],
    // character:",",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <div style='width:25cm'>
            Humanoid robots, eminent characters in science fiction
            novels and sci-fi films, are becoming a reality. Sophia,
            as an example, was first activated on Valentine’s Day in
            2016. One month later, she had her premiere in Texas,
            USA and has made multiple public appearances around
            the world since then. At one such event, Sophia was
            granted Saudi Arabian citizenship in October 2017 and
            even became the first Innovation Champion of the United
            Nations Development Programme later that year. During
            Sophia’s trip to Viet Nam in 2018, she addressed the
            Industry 4.0 Summit and Expo.<br>
            Sophia the Robot is the latest humanlike robot created by
            a Hong Kong-based company whose combined efforts in AI
            research, engineering and design have given birth to robots.
            Undoubtedly, Sophia together with other Hanson robots
            represents the rapid advancement in the field of robotics
            and artificial intelligence. She has been designed to assist
            humans in healthcare, customer support and education.<br>
            Sophia’s physical appearance is inspired by both the famous
            actress Audrey Hepburn and the creator’s wife. She has
            cameras for eyes and microphones for ears, allowing her
            to see and hear like a human. But what makes her more
            lifelike than other robots is the patented artificial skin called
            Frubber®, which has the feel and flexibility of human skin.<br>
            Sophia amazes the world with her ability to communicate
            naturally. The Sophia Intelligence Collective, which
            is a combination of AI and human input, and other
            sophisticated perception techniques enable Sophia to
            recognise human faces and identify human emotions and
            gestures. Equipped with machine learning algorithms,
            Sophia can understand human speech and interact with
            people. Questions and jokes are pieces of cake to her
            whereas sarcasm is intelligible. What distinguishes Sophia
            from other humanlike robots is her social behaviour, i.e.
            her facial emotions, hand movements and conversation
            skills, and most importantly, her learning capability.
            Sophia is programmed to learn from experiences and adapt to new situations.
        </div>
        
        `,
        // InputRong: true,
        answer: ["C", "A", "B", "B", "C", "A"],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E3",
    exerciseKey: "img/FriendsPlus/Page106/Key/E3answerKey.png",
    // checkDuplicated: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again. Are the sentences true or false?
        Write T or F. Correct the false sentences.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "boredom",
          "comfort",
          "convenience",
          "cost",
          "luggage",
          "safety",
          "time",
          "travel sickness",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    // character: "/",
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
          <div style='display:flex'><select id=0></select><b>1</b>&ensp;Sophia has been introduced at different places around the world.</div>
          <div style='display:flex'><select id=1></select><b>2</b>&ensp;Sophia is the one and only humanlike robot developed by the Hanson Robotics.</div>
          <div style='display:flex'><select id=2></select><b>3</b>&ensp;Sophia shows how much robotics and AI have progressed.</div>
          <div style='display:flex'><select id=3></select><b>4</b>&ensp;Sophia has been designed to give support to humans.</div>
          <div style='display:flex'><select id=4></select><b>5</b>&ensp;Sophia understands whatever a person says.</div>
        </div>
        
        <div style='width:25cm'>
            Humanoid robots, eminent characters in science fiction
            novels and sci-fi films, are becoming a reality. Sophia,
            as an example, was first activated on Valentine’s Day in
            2016. One month later, she had her premiere in Texas,
            USA and has made multiple public appearances around
            the world since then. At one such event, Sophia was
            granted Saudi Arabian citizenship in October 2017 and
            even became the first Innovation Champion of the United
            Nations Development Programme later that year. During
            Sophia’s trip to Viet Nam in 2018, she addressed the
            Industry 4.0 Summit and Expo.<br>
            Sophia the Robot is the latest humanlike robot created by
            a Hong Kong-based company whose combined efforts in AI
            research, engineering and design have given birth to robots.
            Undoubtedly, Sophia together with other Hanson robots
            represents the rapid advancement in the field of robotics
            and artificial intelligence. She has been designed to assist
            humans in healthcare, customer support and education.<br>
            Sophia’s physical appearance is inspired by both the famous
            actress Audrey Hepburn and the creator’s wife. She has
            cameras for eyes and microphones for ears, allowing her
            to see and hear like a human. But what makes her more
            lifelike than other robots is the patented artificial skin called
            Frubber®, which has the feel and flexibility of human skin.<br>
            Sophia amazes the world with her ability to communicate
            naturally. The Sophia Intelligence Collective, which
            is a combination of AI and human input, and other
            sophisticated perception techniques enable Sophia to
            recognise human faces and identify human emotions and
            gestures. Equipped with machine learning algorithms,
            Sophia can understand human speech and interact with
            people. Questions and jokes are pieces of cake to her
            whereas sarcasm is intelligible. What distinguishes Sophia
            from other humanlike robots is her social behaviour, i.e.
            her facial emotions, hand movements and conversation
            skills, and most importantly, her learning capability.
            Sophia is programmed to learn from experiences and adapt to new situations.
        </div>
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E4",
    exerciseKey: "img/FriendsPlus/Page106/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      // resize: "none",
      width: 800,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Write a short summary of the text from the following phrases.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 800,
      // textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <b style='color:rgb(1,111,148)'>
            born in 2016<br>
            visit many countries<br>
            be granted citizenship<br>
            show the rapid development of AI<br>
            aid humans in various fields<br>
            have artificial skin<br>
            engage in conversations<br>
            recognise people’s faces <br>
            respond to new situations<br>
        </b>
        <textarea id=0 rows=5></textarea>
        `,
        // InputRong: true,
        answer: [
          "There are nearly fifty thousand kilometres of sewers.",
          "Waste from the newly invented flushing toilet caused the pollution.",
          "The smell from the river made the politicians feel nauseous.",
          "90% of the liquid in sewers is rainwater.",
          "Rats are less common.",
          "It comes from fast food restaurants",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E5",
    exerciseKey: "img/FriendsPlus/Page106/Key/E5answerKey.png",
    audio: "Audios/3.24.mp3",
    // character:",",
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='3.24' colorimg=#98c2d7  color='white'></headphone> Listen and decide who is more positive about
        Sophia and humanlike robots.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "PROJECT", icon: "", color: "#96969a" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 400,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "do not have problems",
          "got",
          "happen",
          "looked at",
          "really think",
          "said",
          "she didn’t say sorry",
          "showed her",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
      <b>Answer:</b>#
        
        `,
        // InputRong: true,
        answer: ["Minh (the boy)|Minh"],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E6",
    exerciseKey: "img/FriendsPlus/Page106/Key/E6answerKey.png",
    audio: "Audios/3.24.mp3",
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='3.24' colorimg=#98c2d7  color='white'></headphone> Listen again. Choose the correct answers (a–c). `,
        color: "black",
        width: "28cm",
        left: -20,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 600,
    },
    // character: ",",
    // hideBtnFooter: true,

    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["1-0", "0-4", "2-4", "3-8", "4-8"],
        initialValue: [],
      },
      Layout: `
      
      
      
      <b>1</b>&ensp;At the Industry 4.0 Summit and Expo in Viet Nam, Sophia
      <div style='display:flex'>
       <div style='margin-left:15px;margin-right:10px'>
         <input id=0 type='Circle' />
       </div>
       <div>
         is displayed on the stage.<br>
         is interviewed by journalists.<br>
         is dressed in her favourite clothes.<br>
       </div>
      </div>
     <b>2</b>&ensp;Minh is most impressed by
      <div style='display:flex'>
       <div style='margin-left:15px;margin-right:10px'>
         <input id=1 type='Circle' />
       </div>
       <div>
         Sophia’s ability to carry on conversations.<br>
         Sophia’s responses in fixed situations.<br>
         Sophia’s pronunciation and fluency.<br>
       </div>
      </div>
     <b>3</b>&ensp;Sophia is designed to
      <div style='display:flex'>
       <div style='margin-left:15px;margin-right:10px'>
         <input id=2 type='Circle' />
       </div>
       <div>
         identify criminals at police stations.<br>
         help shoppers at department stores.<br>
         take care of the old at home.<br>
       </div>
      </div>
     <b>4</b>&ensp;Sophia believes that
      <div style='display:flex'>
       <div style='margin-left:15px;margin-right:10px'>
         <input id=3 type='Circle' />
       </div>
       <div>
         robots can be better than humans.<br>
         robots can conquer humans.<br>
         robots and humans can cooperate.<br>
       </div>
      </div>
     <b>5</b>&ensp;In certain films, humans
      <div style='display:flex'>
       <div style='margin-left:15px;margin-right:10px'>
         <input id=4 type='Circle' />
       </div>
       <div>
         can easily defeat robots.<br>
         are served by humanlike robots.<br>
         are controlled by powerful robots.<br>
       </div>
      </div>
      `,
    },
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E7",
    exerciseKey: "img/FriendsPlus/Page106/Key/E7answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title: `If you were to programme Sophia to speak
        Vietnamese, what would be the first words or phrases you
        would key in? What aspect of Vietnamese do you think
        would be most challenging to Sophia?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 860,
    },
    hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 600,
      // textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "no food available",
          "overcrowded",
          "queues for everything",
          "shop / swimming pool, etc. was closed",
          "showers / restaurants, etc. were dirty",
          "staff were impolite / unhelpful",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
       
        
        `,
        answer: [
          "It means hello in Maori.",
          "It refers to the Maoris' connection with the land.",
          "They often go on adventure holidays.",
          "They are good at rugby.",
          "A haka is a traditional Maori war dance.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E8",
    exerciseKey: "img/FriendsPlus/Page106/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in groups. Search the internet for
        information about humanlike robots. Present your
        favourite robot to the class.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "PROJECT", icon: "", color: "#96969a" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
s        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P106-E9",
    exerciseKey: "img/FriendsPlus/Page106/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in groups. Find out facts about the main
        ethnic groups in northern Viet Nam, Tay and Nung. Then
        present them to the whole class.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "PROJECT", icon: "", color: "#999b9c" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
