import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  3: {
    unit: "Starter",
    id: "SB12-2024-Starter-P7-E3",
    
    component: T6,
    inputSize:500,
    hideBtnFooter:true,
    textAlign:"center",
    stylesTextInput:{width:200,paddingTop:10},
    hintBox:[{
      src:["although","became","'ve become","despite","in spite","started","'ve started","never"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:0
    }
  ],
    titleQuestion: [
      {
        num: "3",
        title:
          `Read the quiz and answer the questions.`,
        color: "black",
        left:50
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:18cm' src="img/FriendsPlus/Page7/E1/1.jpg"/>    
        `,
        answer: ["They went to the dinosaur museum.","She bought a dinosaur model.","They saw dinosaur bones.","She thought it was alive.","He knew it was a model."],
      },
    ],
  },
  
  5: {
    unit: "Starter",
    id: "SB12-2024-Starter-P7-E5",
    exerciseKey: "img/FriendsPlus/Page7/Key/E4answerKey.png",
    component: T6,
    inputSize:500,
    textAlign:"center",
    stylesTextInput:{width:250,paddingTop:10},
    hintBox:[{
      src:["<b>Media verbs</b> announce broadcast cover dissemble feelings disseminate disguise the truth distort the truth flatter mislead photoshop post provide report reveal the truth tell a lie transmit"],
      width:1000,
      borderColor:"none",
      colorHint:"#92278f",
      marginLeft:0
    }
  ],
    titleQuestion: [
      {
        num: "5",
        title:
          `<headphone name='3.02' colorimg='#e9b6b3'   color='white' marginleft='-50px'></headphone>  Listen to five ideas. Are they similar to your 
          answers to the quiz?`,
        color: "black",
        width:"28cm",
        left:50
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        
        `,
        answer: ["reveal the truth","distorts the truth","posting","disseminating ","report, cover|cover, report"],
      },
    ],
  },
 
 
  
  4: {
    unit: "Starter",
    id: "SB12-2024-Starter-P7-E4",
    exerciseKey: "img/FriendsPlus/Page7/Key/E4answerKey.png",
    component: T6,
    inputSize:500,
    textAlign:"center",
    stylesTextInput:{width:250,paddingTop:10},
    hintBox:[{
      src:["<b>Media verbs</b> announce broadcast cover dissemble feelings disseminate disguise the truth distort the truth flatter mislead photoshop post provide report reveal the truth tell a lie transmit"],
      width:1000,
      borderColor:"none",
      colorHint:"#92278f",
      marginLeft:0
    }
  ],
    titleQuestion: [
      {
        num: "4",
        title:
          `Match the highlighted words or phrases in the quiz with 
          the words or phrases which share the same meaning in 
          exercise 2. Remember to use the right form of the verbs.`,
        color: "black",
        width:"28cm",
        left:50
      },
    ],
     
    // hideBtnFooter:true,
    // textAlign:"center",// hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img style='height:18cm' src="img/FriendsPlus/Page7/E1/1.jpg"/>
        <div>
          <b style='font-style:italic'>Answers:</b><br>
          tell the truth - #<br>
          changes the truth - #<br>
          putting a notice - #<br>
          spreading - #<br>
          make reports on - #<br>
        </div>
        `,
        answer: ["reveal the truth","distorts the truth","posting","disseminating","report, cover|cover, report"],
      },
    ],
  },
  6: {
    unit: "Starter",
    id: "SB12-2024-Starter-P7-E6",
    checkDuplicated:true,
    exerciseKey: "img/FriendsPlus/Page7/Key/E6answerKey.png",
    component: T6,
    inputSize:500,
    textAlign:"center",
    stylesTextInput:{width:300,paddingTop:10},
    hintBox:[{
      src:["biased","critical","daunting","encouraging","fake","flattering","genuine","honest","informative","misleading","neutral","provocative"],
      width:1000,
      borderColor:"none",
      colorHint:"#92278f",
      marginLeft:0
    }
  ],
    titleQuestion: [
      {
        num: "6",
        title:
          `Find the meaning of the following adjectives. Can you 
          find three pairs of antonyms in the list?`,
        color: "black",
        width:"28cm",
        left:50
      },
    ],
     
    // hideBtnFooter:true,
    // textAlign:"center",// hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div>
          <b style='font-style:italic'>Answer:</b><br>
          #<br>#<br>#
        </div>
        `,
        answer: [
          "daunting - encouraging|encouraging - daunting|fake - genuine|genuine - fake|critical - flattering|flattering - critical",
          "daunting - encouraging|encouraging - daunting|fake - genuine|genuine - fake|critical - flattering|flattering - critical",
          "daunting - encouraging|encouraging - daunting|fake - genuine|genuine - fake|critical - flattering|flattering - critical",

        ],
        notSymbol: true,

      },
    ],
  },
  7: {
    unit: "Starter",
    id: "SB12-2024-Starter-P7-E7",
    exerciseKey: "img/FriendsPlus/Page7/Key/E7answerKey.png",
    component: T6,
    inputSize:500,
    textAlign:"center",
    stylesTextInput:{width:170,paddingTop:10},
    titleQuestion: [
      {
        num: "7",
        title:
          `Complete the following sentences using the vocabulary 
          in exercises 2 and 6.`,
        color: "black",
        width:"28cm",
        left:50
      },
    ],
     
    // hideBtnFooter:true,
    // textAlign:"center",
    hintBox:[{
      src:["But it’s up to you","So I’m leaning towards the film club","What are your options","The only bad thing about that club","What are you up to","Have you considered another basketball club","It’s a tricky decision"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:0
    }
  ],
   
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:25cm'>
          <b>1</b>&ensp;That advertisement is#about the true cost of the holiday. The holidaymakers are mistaken about how much they have to spend. <br>
          <b>2</b>&ensp;Some people#their true feelings on social media. They pretend to be happy and satisfied all the time. <br>
          <b>3</b>&ensp;Gina left a#comment on her close friend’s social media post, and it started an argument.<br>
          <b>4</b>&ensp;I know that people are lying to me. I posted some of my photographs which have been photoshopped and I get all#comments. <br>
          <b>5</b>&ensp;Newspapers, whether in paper or online, are good medium to widely#news. <br>
          <b>6</b>&ensp;During the TV panel discussion, some of the professors gave#information about their universities. <br>
          <b>7</b>&ensp;You should not believe everything you read in the tabloids. The news is not serious and maybe#. <br>
          <b>8</b>&ensp;When Wendy expressed her wish to have a trip around the world on her social page, she received so many#remarks that she was not confident enough to do it.<br>
        </div>
        
        `,
        answer: ["misleading","dissemble","provocative","flattering","disseminate","biased","fake","daunting"],
      },
    ],
  },
  8: {
    unit: "Starter",
    id: "SB12-2024-Starter-P7-E8",
    exerciseKey: "img/FriendsPlus/Page7/Key/E7answerKey.png",
    component: T6,
    inputSize:500,
    textAlign:"center",
    stylesTextInput:{width:170,paddingTop:10},
    hintBox:[{
      src:["biased","critical","daunting","encouraging","fake","flattering","genuine","honest","informative","misleading","neutral","provocative"],
      width:1000,
      borderColor:"none",
      colorHint:"#92278f",
      marginLeft:0
    }
  ],
    titleQuestion: [
      {
        num: "8",
        title:
          `Work in groups. Discuss the questions.`,
        color: "black",
        width:"28cm",
        left:150,
        prefix:[{text:'SPEAKING',icon:'',color:'#01a951'}]
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:150px'>
          Traditional media or social media, which one do you <br>
          think is …<br>
          faster?<br>
          more accurate?
        </div>
        `,
        answer: ["misleading","dissemble","provocative","flattering","disseminate","biased","fake","daunting"],
      },
    ],
  },
 
 
};

export default json;
