import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P43-E1",
    exerciseKey: "img/FriendsPlus/Page43/Key/E1answerKey.png",

    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        left: 0,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: ["film (n)", "flat", "lift (n)", "petrol", "tap", "toilet"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 800,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;In Viet Nam, on which occasion do people usually hold a celebration?<br>
          <b>2</b>&ensp;How do they usually celebrate these occasions? Think about food, parties, gifts / cards and other customs.<br>
        </div>
        `,
        answer: ["movie", "apartment", "elevator", "gas", "faucet", "restroom"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P43-E2",
    exerciseKey: "img/FriendsPlus/Page43/Key/E2answerKey.png",
    // checkDuplicated: true,
    audio: "Audios/1.35.mp3",
    titleQuestion: [
      {
        num: "",
        title: `<headphone name='1.35' colorimg=#d1b6d5  color='white'></headphone>`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { width: 100, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Gestures and expressions</b>", "bite your fingernails"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:27cm'>
        Ali and Baba grew up together as childhood playmates
        just like Hassan and I grew up a generation later. Baba
        was always telling us about their mischiefs he and Ali
        used to cause. But in none of his stories did Baba ever
        refer to Ali as his friend.<br>
        The curious thing was, I never thought of Hassan and
        me as friends either. <sup>1</sup>____ Never mind that we taught
        each other to ride a bicycle with no hands, or to build a
        fully functional homemade camera out of a cardboard
        box. Never mind that we spent entire winters flying
        kites, running kites. Never mind that to me, the face of
        Afghanistan is that of a boy with a thin-boned frame, a
        shaved head, and low-set ears, a boy with a Chinese doll
        face perpetually lit by a harelipped smile.<br>
        Never mind any of those things. <sup>2</sup>____ Neither is
        religion. In the end, I was a Pashtun and he was a Hazara,
        I was Sunni and he was Shi’a, and nothing was ever going
        to change that. Nothing.<br>
        We saw our first Western together, Rio Bravo with
        John Wayne, at the Cinema Park. I remember begging
        Baba to take us to Iran so we could meet John Wayne.
        Baba burst out in gales of his deep-throated laughter
        and then explained to us the concept of voice dubbing.
        <sup>3</sup>____ John Wayne didn’t really speak Farsi and he
        wasn’t Iranian! He was American, just like the friendly,
        long-haired men and women we always saw hanging
        around in Kabul, dressed in their tattered, brightly
        colored shirts.<br>
        During the school year, we had a daily routine. By the
        time I dragged myself out of bed and lumbered to the
        bathroom, Hassan had already washed up, prayed the
        morning namaz with Ali, and prepared my breakfast.
        While I ate and complained about homework, Hassan
        made my bed, polished my shoes, ironed my outfit for
        the day, packed my books and pencils. <sup>4</sup>____
        (Adapted from The Kite Runner by Khaled Hosseini)
        </div>

        `,

        // InputRong: true,
        answer: ["BrE", "NAmE"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P43-E3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again. What is it about?`,
        color: "black",
        left: 0,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    stylesTextInput: { textAlign: "center", width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: ["annoyance", "anxiety"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 100, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `1 <br> 2 <br> 3
          `,
        ],
        answers: ["0-4"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px;display:flex'>
        <div><input id=0 type=Circle /></div>
        <div style='margin-left:15px'>
          Afghan cinema<br>
          Growing up in Afghanistan<br>
          The different social classes in Afghanistan<br>
        </div>
      </div>
      <div style='width:27cm'>
        Ali and Baba grew up together as childhood playmates
        just like Hassan and I grew up a generation later. Baba
        was always telling us about their mischiefs he and Ali
        used to cause. But in none of his stories did Baba ever
        refer to Ali as his friend.<br>
        The curious thing was, I never thought of Hassan and
        me as friends either. <sup>1</sup>______ Never mind that we taught
        each other to ride a bicycle with no hands, or to build a
        fully functional homemade camera out of a cardboard
        box. Never mind that we spent entire winters flying
        kites, running kites. Never mind that to me, the face of
        Afghanistan is that of a boy with a thin-boned frame, a
        shaved head, and low-set ears, a boy with a Chinese doll
        face perpetually lit by a harelipped smile.<br>
        Never mind any of those things. <sup>2</sup>______ Neither is
        religion. In the end, I was a Pashtun and he was a Hazara,
        I was Sunni and he was Shi’a, and nothing was ever going
        to change that. Nothing.<br>
        We saw our first Western together, Rio Bravo with
        John Wayne, at the Cinema Park. I remember begging
        Baba to take us to Iran so we could meet John Wayne.
        Baba burst out in gales of his deep-throated laughter
        and then explained to us the concept of voice dubbing.
        <sup>3</sup>______ John Wayne didn’t really speak Farsi and he
        wasn’t Iranian! He was American, just like the friendly,
        long-haired men and women we always saw hanging
        around in Kabul, dressed in their tattered, brightly
        colored shirts.<br>
        During the school year, we had a daily routine. By the
        time I dragged myself out of bed and lumbered to the
        bathroom, Hassan had already washed up, prayed the
        morning namaz with Ali, and prepared my breakfast.
        While I ate and complained about homework, Hassan
        made my bed, polished my shoes, ironed my outfit for
        the day, packed my books and pencils. <sup>4</sup>______
        (Adapted from The Kite Runner by Khaled Hosseini)
        </div>
      
      
      `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P43-E4",
    exerciseKey: "img/FriendsPlus/Page43/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#42429c'>Reading Strategy</span>. Match the missing sentences
        (A–E) with the gaps (1–4). There is one extra sentence`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 40,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div>
          <b>A</b>&ensp;Hassan and I were stunned. Dazed.<br>
          <b>B</b>&ensp;I’d hear him singing to himself in the foyer as he ironed, singing old Hazara songs in his nasal voice.<br>
          <b>C</b>&ensp;Not in the usual sense, anyhow.<br>
          <b>D</b>&ensp;We had been close friends since childhood.<br>
          <b>E</b>&ensp;Because history isn’t easy to overcome.<br>
        </div>
        <div style='width:27cm'>
        Ali and Baba grew up together as childhood playmates
        just like Hassan and I grew up a generation later. Baba
        was always telling us about their mischiefs he and Ali
        used to cause. But in none of his stories did Baba ever
        refer to Ali as his friend.<br>
        The curious thing was, I never thought of Hassan and
        me as friends either. <sup>1</sup># Never mind that we taught
        each other to ride a bicycle with no hands, or to build a
        fully functional homemade camera out of a cardboard
        box. Never mind that we spent entire winters flying
        kites, running kites. Never mind that to me, the face of
        Afghanistan is that of a boy with a thin-boned frame, a
        shaved head, and low-set ears, a boy with a Chinese doll
        face perpetually lit by a harelipped smile.<br>
        Never mind any of those things. <sup>2</sup># Neither is
        religion. In the end, I was a Pashtun and he was a Hazara,
        I was Sunni and he was Shi’a, and nothing was ever going
        to change that. Nothing.<br>
        We saw our first Western together, Rio Bravo with
        John Wayne, at the Cinema Park. I remember begging
        Baba to take us to Iran so we could meet John Wayne.
        Baba burst out in gales of his deep-throated laughter
        and then explained to us the concept of voice dubbing.
        <sup>3</sup># John Wayne didn’t really speak Farsi and he
        wasn’t Iranian! He was American, just like the friendly,
        long-haired men and women we always saw hanging
        around in Kabul, dressed in their tattered, brightly
        colored shirts.<br>
        During the school year, we had a daily routine. By the
        time I dragged myself out of bed and lumbered to the
        bathroom, Hassan had already washed up, prayed the
        morning namaz with Ali, and prepared my breakfast.
        While I ate and complained about homework, Hassan
        made my bed, polished my shoes, ironed my outfit for
        the day, packed my books and pencils. <sup>4</sup>#
        (Adapted from The Kite Runner by Khaled Hosseini)
        </div>

         `,
        answer: ["C", "E", "A", "B"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P43-E5",
    exerciseKey: "img/FriendsPlus/Page43/Key/E5answerKey.png",

    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Match (1–5) with (A–E) to make sentences about the
        extract.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],

    component: T6,
    questions: [
      {
        title: `
        <div>
            <div>
              <b>1</b>&ensp;The narrator and Hassan had a childhood that<br>
              <b>2</b>&ensp;The narrator doesn’t think that he and Hassan<br>
              <b>3</b>&ensp;Certain cultural and social factors couldn’t change the fact that the boys<br>
              <b>4</b>&ensp;Baba laughed a lot because the boys<br>
              <b>5</b>&ensp;In the last paragraph, we discover the narrator and Hassan <br>
            </div>
            <div style='margin-top:20px'>
              <b>A</b>&ensp;had spent their childhood together.<br>
              <b>B</b>&ensp;had a typical friendship.<br>
              <b>C</b>&ensp;had different daily experiences.<br>
              <b>D</b>&ensp;were confused about an actor’s nationality.<br>
              <b>E</b>&ensp;was similar to Ali and Baba’s<br>
            </div>
        </div>
        <b>Answer</b><br>
        <b>1</b> - #<br>
        <b>2</b> - #<br>
        <b>3</b> - #<br>
        <b>4</b> - #<br>
        <b>5</b> - #<br>
        `,
        answer: ["E", "B", "A", "D", "C"],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P43-E6",
    exerciseKey: "img/FriendsPlus/Page43/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 60 },
    titleQuestion: [
      {
        num: "6",
        title: `Find five underlined compound adjectives
        in the text. Then complete the sentences with them.
        `,
        color: "black",
        width: "28cm",
        left: -20,
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Compound adjectives</b>"],
        styleElement: { color: "#42429c", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [
          "thin-boned",
          "homemade",
          "deep-throated",
          "low-set",
          "long-haired",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 20,
        listWords: [
          ` Ali and Baba grew up together as childhood playmates
          just like Hassan and I grew up a generation later. Baba
          was always telling us about their mischiefs he and Ali
          used to cause. But in none of his stories did Baba ever
          refer to Ali as his friend.<br>
          The curious thing was, I never thought of Hassan and
          me as friends either. <sup>1</sup>______ Never mind that we taught
          each other to ride a bicycle with no hands, or to build a
          fully functional homemade camera out of a cardboard
          box. Never mind that we spent entire winters flying
          kites, running kites. Never mind that to me, the face of
          Afghanistan is that of a boy with a thin-boned frame, a
          shaved head, and low-set ears, a boy with a Chinese doll
          face perpetually lit by a harelipped smile.<br>
          Never mind any of those things. <sup>2</sup>______ Neither is
          religion. In the end, I was a Pashtun and he was a Hazara,
          I was Sunni and he was Shi’a, and nothing was ever going
          to change that. Nothing.<br>
          We saw our first Western together, Rio Bravo with
          John Wayne, at the Cinema Park. I remember begging
          Baba to take us to Iran so we could meet John Wayne.
          Baba burst out in gales of his deep-throated laughter
          and then explained to us the concept of voice dubbing.
          <sup>3</sup>______ John Wayne didn’t really speak Farsi and he
          wasn’t Iranian! He was American, just like the friendly,
          long-haired men and women we always saw hanging
          around in Kabul, dressed in their tattered, brightly
          colored shirts.<br>
          During the school year, we had a daily routine. By the
          time I dragged myself out of bed and lumbered to the
          bathroom, Hassan had already washed up, prayed the
          morning namaz with Ali, and prepared my breakfast.
          While I ate and complained about homework, Hassan
          made my bed, polished my shoes, ironed my outfit for
          the day, packed my books and pencils. <sup>4</sup>______
          (Adapted from The Kite Runner by Khaled Hosseini)`,
        ],
        answers: ["0-328", "0-488", "0-456", "0-848", "0-988"],
        initialValue: [],
      },
      Layout: `
      <hintbox id=0></hintbox>
      <div style='margin-bottom:10px'>
        <b>1</b>&ensp;Although she was tall, she was slim and <input id=0 /> and didn’t weigh much.<br>
        <b>2</b>&ensp;Try this <input id=1 /> jam. It’s much better than the ones you buy in shops.<br>
        <b>3</b>&ensp;Suddenly, we heard the <input id=2 /> roar of a lion calling out to its mate.<br>
        <b>4</b>&ensp;The stranger had <input id=3 /> eyes that were close together, giving him a menacing look.<br>
        <b>5</b>&ensp;After six months of travelling, the men were all <input id=4 /> and bearded.<br>
      </div>
      <div>
          <input id=0 type=Circle />
      </div>
      
      
      `,
    },
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P43-E7",
    exerciseKey: "img/FriendsPlus/Page43/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Why is it often difficult to form
        friendships with people from different cultural or social
        backgrounds? Use the ideas below and add your own.
        `,
        color: "black",
        left: 0,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 70,
      paddingTop: 10,
    },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <ul>
          <li>Different hobbies and interests</li>
          <li>Other people’s attitudes</li>
          <li>Different views, opinions, or outlooks</li>
         </ul>
         <img src="img/FriendsPlus/Page43/E7/1.jpg" style='height:5.5cm'/><br>
        `,
        answer: ["Am", "Br", "Am", "Am", "Br", "Br"],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P43-E8",
    exerciseKey: "img/FriendsPlus/Page43/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Each student makes three
        sentences using the words in exercise 4 and reads them
        to the other student. The other student will tell their
        equivalents in British English or American English.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <b style='color: rgb(146,39,143)'>A</b> <hintbox id=0></hintbox>
      </div>
      <div style='display:flex'>
        <b style='color: rgb(146,39,143)'>B</b> <hintbox id=1></hintbox>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
