import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E1",
    exerciseKey: "img/FriendsPlus/Page45/Key/E1answerKey.png",
    recorder: true,

    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        left: 50,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: ["film (n)", "flat", "lift (n)", "petrol", "tap", "toilet"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 800,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Which local festivals happen in your area?<br>
          <b>2</b>&ensp;What happens there? Do you go to any?<br>
        </div>
        `,
        answer: ["movie", "apartment", "elevator", "gas", "faucet", "restroom"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E2",
    exerciseKey: "img/FriendsPlus/Page45/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the task and find the two elements that you are
        required to include in your blog post.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { width: 100, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Gestures and expressions</b>", "bite your fingernails"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page45/E2/1.jpg" style='height:5cm'/><br>
        
        `,

        // InputRong: true,
        answer: ["BrE", "NAmE"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#2683c6'>Writing Strategy</span> and the blog post. In which
        paragraphs (1–4) does the writer cover the two elements
        from the task? How well does the writer follow the
        advice in the strategy?`,
        color: "black",
        left: 0,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    stylesTextInput: { textAlign: "center", width: 700, paddingTop: 10 },
    hintBox: [
      {
        src: ["annoyance", "anxiety"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 700,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page45/E3/1.jpg" style='height:6.5cm'/><br>
        <img src="img/FriendsPlus/Page45/E3/2.jpg" style='height:22cm'/><br>
        <b>which paragraphs (1–4) does the writer cover the two elements from the task?</b><textarea id=1 rows=3></textarea>
        <b>How well does the writer follow the advice in the strategy?</b><br>#
        `,

        // InputRong: true,
        answer: [
          "The writer also includes an introduction and conclusion.",
          "The advantages of the festival: paragraph 2, A personal experience of the festival: paragraph 3, The writer gives more or less equal space to these two elements.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E4",
    exerciseKey: "img/FriendsPlus/Page45/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#2683c6'>Learn this!</span> box. Then find an example of each
        of the structures in the blog post.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -20,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 40,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `I_was_very_surprised_to_read_an_article_in_my_local_newspaper_suggesting_that_the_Kingsbridge_Festival_should_no_longer_take_place. 
The_article_suggested_that_the_festival_costs_the_council_too_much_money_and_causes_too_much_disruption_to_traffic_in_the_town_centre. 
I’d_like_to_outline_some_arguments_against_this_view. `,
          `I_strongly_disagree_with_the_suggestion_that_there_is_no_value_in_continuing_the_festival.  
Firstly,_it_has_become_a_local_tradition.  
Kingsbridge_has_held_an_annual_food_and_music_festival_for_more_than_ten_years.  
Secondly,_local_producers_and_caterers_can_sell_their_food_and_drink_there. 
And_thirdly,_after_eating_and_drinking,_visitors_can_stay_to_watch_local_musicians_showcase_their_talents. `,
          `Last_year’s_festival_was_a_memorable_experience. 
As_families_crowded_around_the_food_stalls,_the_music_played_non-stop. 
There_was_a_lovely,_friendly_atmosphere_until_night_fell. 
Having_arrived_later_to_hear_the_bands_play,_the_local_teenagers_just_mixed_in_with_everybody_else. 
Looking_around,_I_could_see_young_people,_old_people_and_parents_with_children_on_their_shoulders,_all_dancing_to_the_music.`,
          `In_my_view,_festivals_like_this_are_good_for_local_trade_and_advertising. 
They_are_also_invaluable_for_bringing_and_keeping_communities_together. 
How_can_you_put_a_price_on_that?`,
        ],
        answers: ["2-4", "2-12", "2-16"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page45/E4/1.jpg" style='height:13cm'/><br>
      <div style='position:relative'>
        <img src="img/FriendsPlus/Page45/E4/2.jpg" style='height:25cm'/><br>
        <div style='font-size:22px;line-height:31px;width:17cm;position:absolute;top:96px;left:59px'>
          <input id=0 type=Circle />
        </div>
        <div style='font-size:22px;line-height:31px;width:17cm;position:absolute;top:286px;left:59px'>
          <input id=1 type=Circle />
        </div>
        <div style='font-size:22px;line-height:31px;width:17cm;position:absolute;top:508px;left:59px'>
          <input id=2 type=Circle />
        </div>
        <div style='font-size:22px;line-height:31px;width:17cm;position:absolute;top:729px;left:59px'>
          <input id=3 type=Circle />
        </div>
      </div>
   
      
      
      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E5",
    exerciseKey: "img/FriendsPlus/Page45/Key/E5answerKey.png",

    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `In your notebook, join the sentences, beginning with the
        words in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 950,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["After arriving at the airport, I checked in."],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        // width: "70%",
        marginLeft: 15,
      },
      {
        src: ["Having arrived at the airport, I checked in."],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        // width: "70%",
        marginLeft: 15,
      },
    ],
    character: "/",
    component: T6,
    questions: [
      {
        title: `

          <b>1</b>&ensp;I arrived at the airport. I checked in. (After … / Having …)<br>
          <hintbox id=0></hintbox>
          <hintbox id=1></hintbox>
          <b>2</b>&ensp;We lay in the sun. We read magazines. (As … / Lying …)<br>#<br>
          <b>3</b>&ensp;The band played. The sun came out. (As …)<br>#<br>
          <b>4</b>&ensp;They swam. They wrote postcards. (After … / Having …)<br>#<br>
          <b>5</b>&ensp;You watched the film. You fell asleep. (As … / Watching …)<br>#<br>
        
        `,
        answer: [
          "As we lay in the sun, we read magazines. / Lying in the sun, we read magazines.",
          "As the band played, the sun came out.",
          "After swimming, they wrote postcards. / Having swum, they wrote postcards.",
          "As you watched the film, you fell asleep. / Watching the film, you fell asleep.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E6",
    exerciseKey: "img/FriendsPlus/Page45/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 700 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the task below. Then make some notes for <br>
        a) the advantages and b) your description.
        `,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Compound adjectives</b>"],
        styleElement: { color: "#42429c", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page45/E6/1.jpg" style='height: 5.5cm'/><br>
        <textarea id=0 rows=4></textarea> 
        
        `,
        answer: [
          "The student says phrases 1 and 3.",
          "The student doesn't mention the second bullet point.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E7",
    exerciseKey: "img/FriendsPlus/Page45/Key/E7answerKey.png",
    recorder: true,
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Discuss ideas for each paragraph
        and compare the notes you made in exercise 6.
        `,
        color: "black",
        left: 0,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 70,
      paddingTop: 10,
    },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page45/E3/2.jpg" style='height:22cm'/><br>
        
        `,
        answer: ["Am", "Br", "Am", "Am", "Br", "Br"],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E8",
    exerciseKey: "img/FriendsPlus/Page45/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `In your notebook, write your blog post (180–200 words).

        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page45/E8/1.jpg" style='height: 5.5cm'/><br>
        <textarea id=0 rows=4></textarea> 
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P45-E9",
    exerciseKey: "img/FriendsPlus/Page45/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Take it in turns to do the task in exercise 8.
        Listen carefully when your partner is speaking and check
        whether all three prompts are mentioned.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page45/E8/1.jpg" style='height: 15.5cm'/><br>
        <textarea id=0 rows=4></textarea> 
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
