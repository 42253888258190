import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page89/Key/E1answerKey.png",
    component: T6,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Look the photos. How do you think these images were 
          made?`,
        color: "black",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:20cm;margin-left:50px' src="img/FriendsPlus/Page89/E1/1.jpg"/>
      
        
        `,
        answer: ["so", "such", "so", "such"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E2",
    exerciseKey: "img/FriendsPlus/Page89/Key/E2answerKey.png",
    component: T6,
    maxLength: 4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 100, paddingTop: 10, background: "#fefccc" },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the article. What’s the most amusing or unusual 
          hoax image you’ve seen? Do you think hoaxes are a 
          problem? Why? / Why not?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:20cm;margin-left:50px' src="img/FriendsPlus/Page89/E1/1.jpg"/>
      
        
        `,
        answer: ["so", "such", "so", "such"],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E3",
    exerciseKey: "img/FriendsPlus/Page89/Key/E3answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    // maxLength:5,
    stylesTextInput: { width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "addiction",
          "demand",
          "difference",
          "evidence",
          "increase",
          "interest",
          "obsession",
        ],
        width: 600,
        borderColor: "none",
        marginLeft: 150,
        colorHint: "#92278f",
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#92278f'>Learn this!</span> box. Then find the nouns below in the 
          text in exercise 2. Which prepositions are they followed by?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img style='height:7cm;margin-left:100px' src="img/FriendsPlus/Page89/E3/1.jpg"/>
        <div>
          <b style='font-style:italic'>Answer:</b><br>
          addiction#<br>
          demand#<br>
          difference#<br>
          evidence#<br>
          increase#<br>
          interest#<br>
          obsession#<br>
        </div>
        
        `,
        answer: ["to", "for", "between", "of", "in", "in", "with"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E4",
    exerciseKey: "img/FriendsPlus/Page89/Key/E4answerKey.png",
    component: T6,
    inputSize: 500,
    // textAlign:"center",
    // maxLength:5,
    stylesTextInput: { width: 740, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "addiction",
          "demand",
          "difference",
          "evidence",
          "increase",
          "interest",
          "obsession",
        ],
        width: 900,
        borderColor: "none",
        marginLeft: 100,
        colorHint: "#92278f",
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#92278f'>Dictionary Strategy</span> and the 
          dictionary entry below. Answer the questions.`,
        color: "black",
        left: 100,
        width: "28cm",
        prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#92278f" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img style='height:75mm;margin-left:100px' src="img/FriendsPlus/Page89/E4/1.jpg"/>
        <img style='height:60mm;margin-left:100px' src="img/FriendsPlus/Page89/E4/2.jpg"/>
        <div style='margin-left:50px'>
          <b>1</b>&ensp;Which two prepositions can follow ‘evidence’?<br>#<br>
          <b>2</b>&ensp;In what two ways is the information about dependent prepositions given?<br>#<br>
        </div>
        
        `,
        answer: [
          "of, for",
          "The preposition is given in brackets and in example sentences.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E5",
    exerciseKey: "img/FriendsPlus/Page89/Key/E5answerKey.png",
    component: T6,
    checkDuplicated: true,
    inputSize: 500,
    textAlign: "center",
    // maxLength:5,
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belief",
          "dependence",
          "effect",
          "objection",
          "preference",
          "reason",
          "rise",
          "solution",
        ],
        width: 800,
        borderColor: "none",
        marginLeft: 50,
        colorHint: "#92278f",
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Add the nouns to the table. Use a dictionary to help you.`,
        color: "black",
        left: 100,
        width: "28cm",
        prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#92278f" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div  style='margin-left:100px'>
        <table style='border:2px solid rgb(146,38,139)'>
        <tr>
          <th style='text-align:center;padding:4px;border-right:2px solid rgb(146,38,139)'>#<br>#<br>+on</th>
          <th style='text-align:center;padding:4px;border-right:2px solid rgb(146,38,139)'>#<br>#<br>+in</th> 
          <th style='text-align:center;padding:4px;border-right:2px solid rgb(146,38,139)'>#<br>#<br>+for</th>
          <th style='text-align:center;padding:4px;'>#<br>#<br>+to</th>
        </tr>
      </table>
      </div>

        `,
        answer: [
          "dependence|effect",
          "dependence|effect",
          "belief|rise",
          "belief|rise",
          "preference|reason",
          "preference|reason",
          "objection|solution",
          "objection|solution",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E6",
    exerciseKey: "img/FriendsPlus/Page89/Key/E6answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Choose the correct prepositions to complete the sentences. 
          Check your ideas in a dictionary.`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix:[{text:'SPEAKING',icon:'',color:'#01a951'}]
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "with / in",
          "to / for",
          "with / of",
          "for / on",
          "for / of",
          "with / in",
          "on / of",
          "for / in",
        ],
        answers: ["0-4", "1-0", "2-0", "3-0", "4-0", "5-4", "6-4", "7-4"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
        <b>1</b>
        <div style='margin-left:15px'>
          <b>a</b>&ensp;There’s been a recent rise <input/ id='0' type='Circle'> cases of online fraud.<br>
          <b>b</b>&ensp;Her rise <input id='1' type='Circle'/> fame came with the release of her film.
        </div>
      </div>
      <div style='display:flex'>
        <b>2</b>
        <div style='margin-left:15px'>
          <b>a</b>&ensp;What’s the matter <input id='2' type='Circle'/> Tom? He looks terrible!<br>
          <b>b</b>&ensp;This is a serious crime which is a matter <input id='3' type='Circle'/> the police.
        </div>
      </div>
      <div style='display:flex'>
        <b>3</b>
        <div style='margin-left:15px'>
          <b>a</b>&ensp;His taste <input id='4' type='Circle'/> travel led him to become an explorer. <br>
          <b>b</b>&ensp;I really can’t stand my parents’ taste <input id='5' type='Circle'/> music.
        </div>
      </div>
      <div style='display:flex'>
        <b>4</b>
        <div style='margin-left:15px'>
          <b>a</b>&ensp;We had a difference <input id='6' type='Circle'/> opinion about where to go.<br>
          <b>b</b>&ensp;Have you noticed a difference <input id='7' type='Circle'/> Liv’s behaviour?
        </div>
      </div>
      `,
    },
  },
  7: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E7",
    exerciseKey: "img/FriendsPlus/Page89/Key/E7answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    maxLength: 5,
    stylesTextInput: { background: "none", width: 130, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belief",
          "dependence",
          "effect",
          "objection",
          "preference",
          "reason",
          "rise",
          "solution",
        ],
        width: 800,
        borderColor: "none",
        marginLeft: 50,
        colorHint: "#92278f",
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text by writing one 
          dependent preposition in each gap.`,
        color: "black",
        // left:100,
        // width:"28cm",
        prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#92278f" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='background:rgb(235,244,213);padding:2px 15px;width:21cm;border:7px solid rgb(164,207,62);border-radius:20px'>
          The 21<sup>st</sup> century has seen a huge rise <sup>1</sup># the number 
          of images we are exposed to, along with an apparent 
          addiction <br><sup>2</sup># watching news events as they happen. 
          Some might argue that there’s nothing the matter 
          <sup>3</sup># this, but the existence <sup>4</sup># today’s sophisticated 
          image-editing software makes it hard for people to tell the 
          difference <sup>5</sup># what’s real and what’s fake. Now experts 
          have shared evidence <sup>6</sup># the negative effect of this 
          kind of technology. It seems that even when we’re aware 
          that some images we see are false, they can have a 
          dramatic effect <sup>7</sup># our behaviour. Worryingly, we have 
          little control over the false memories that these images 
          can create in us.
        </div>

        `,
        answer: ["in", "to", "with", "of", "between", "for", "on"],
      },
    ],
  },
  8: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E8",
    exerciseKey: "img/FriendsPlus/Page8/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Do you agree to the statement? 
          Creating fake images is illegal.
          `,
        color: "black",
        // width:"28cm",
        // left:150,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
